import React, { useState, useEffect, useRef } from 'react';
import { motion, useMotionValue, useTransform, AnimatePresence, useScroll, useSpring } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ArrowDown, Award, BookOpen, Star } from 'lucide-react';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from './NewsletterSignup';

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      // Show the scroll icon if user scrolled down 300 pixels or more
      if (!showScroll && window.scrollY > 300) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
      onClick={scrollTop}
    >
      <FaChevronUp size={24} />
    </div>
  );
};

const MenuBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const linkStyle = {
    color: '#ffffff',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    display: 'inline-block',
    padding: '15px 20px',
    position: 'relative',
    fontWeight: '700',
  };

  const hoverEffectStyle = {
    content: '""',
    background: '#D946EF',
    bottom: 0,
    height: '2px',
    left: '50%',
    position: 'absolute',
    transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
    width: 0,
  };

  const liStyle = {
    display: 'table-cell',
    position: 'relative',
    padding: '15px 0',
  };

  const ulStyle = {
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    display: 'table',
    width: '800px',
    textAlign: 'center',
  };

  const mobileMenuStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '100%',
    backgroundColor: '#000',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
  };

  const mobileLinkStyle = {
    ...linkStyle,
    padding: '10px 20px',
  };

  return (
    <>
      {/* Desktop View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="desktop-menu"
      >
        <div
          style={{
            position: 'absolute',
            left: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/Y.png"
            alt="Logo"
            style={{ height: '160px', marginLeft: '50px' }}
            onClick={() => window.location.reload()}
          />
        </div>
        <ul style={ulStyle}>
          {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
            <motion.li
              key={text}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={liStyle}
            >
              <motion.a
                href={`/${text.toLowerCase()}`}
                style={linkStyle}
                whileHover={{ scale: 1.1 }}
                onMouseEnter={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '100%';
                  hoverSpan.style.left = '0';
                }}
                onMouseLeave={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '0';
                  hoverSpan.style.left = '50%';
                }}
              >
                {text}
                <span style={hoverEffectStyle}></span>
              </motion.a>
            </motion.li>
          ))}
        </ul>
      </motion.nav>

      {/* Mobile View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="mobile-menu"
      >
        <div
          style={{ padding: '15px 20px', cursor: 'pointer' }}
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
        </div>
        {isMobileMenuOpen && (
          <motion.ul
            style={mobileMenuStyle}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
              <motion.li
                key={text}
                style={{ padding: '10px 0' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                  {text}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </motion.nav>

      <style>
        {`
                  @media (min-width: 768px) {
                      .mobile-menu {
                          display: none;
                      }
                  }
                  @media (max-width: 767px) {
                      .desktop-menu {
                          display: none;
                      }
                  }
              `}
      </style>
    </>
  );
};

const Particle = ({ color }) => {
  const x = useMotionValue(Math.random() * window.innerWidth);
  const y = useMotionValue(Math.random() * window.innerHeight);
  const size = Math.random() * 4 + 1;

  useEffect(() => {
    const intervalId = setInterval(() => {
      x.set(Math.random() * window.innerWidth);
      y.set(Math.random() * window.innerHeight);
    }, Math.random() * 5000 + 2000);

    return () => clearInterval(intervalId);
  }, [x, y]);

  return (
    <motion.div
      className="absolute rounded-full"
      style={{
        x,
        y,
        width: size,
        height: size,
        backgroundColor: color,
        boxShadow: `0 0 ${size * 2}px ${color}`
      }}
      transition={{ duration: 2, ease: "easeInOut" }}
    />
  );
};

const ParticleBackground = () => {
  return (
    <div className="fixed inset-0 z-0">
      {[...Array(100)].map((_, i) => (
        <Particle key={i} color={i % 2 === 0 ? '#8B5CF6' : '#D946EF'} />
      ))}
    </div>
  );
};

const ScrollProgressBar = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-violet-500 to-fuchsia-500 z-50"
      style={{ scaleX, transformOrigin: "0%" }}
    />
  );
};

const AnimatedCounter = ({ target, duration = 2 }) => {
  const [count, setCount] = useState(0);
  const nodeRef = useRef();

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const end = parseInt(target, 10);
      const increment = end / (duration * 60);
      const timer = setInterval(() => {
        start += increment;
        setCount(Math.floor(start));
        if (start >= end) {
          clearInterval(timer);
          setCount(end);
        }
      }, 16);
    }
  }, [target, duration, inView]);

  return (
    <span ref={ref}>
      <span ref={nodeRef}>{count}</span>+
    </span>
  );
};

const ProjectsChart = () => {
  const data = [
    { date: 'Nov 2023', projects: 1 },
    { date: 'Dec 2023', projects: 1 },
    { date: 'Jan 2024', projects: 2 },
    { date: 'Feb 2024', projects: 3 },
    { date: 'Mar 2024', projects: 4 },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="#ffffff22" />
        <XAxis dataKey="date" stroke="#ffffff" />
        <YAxis stroke="#ffffff" />
        <Tooltip
          contentStyle={{ backgroundColor: '#1e1b4b', border: 'none' }}
          labelStyle={{ color: '#ffffff' }}
        />
        <Line
          type="monotone"
          dataKey="projects"
          stroke="#D946EF"
          strokeWidth={2}
          dot={{ fill: '#D946EF', r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};



const RealImage = ({ src, alt }) => (
  <motion.img
    src={src}
    alt={alt}
    className="w-full h-full object-cover rounded-lg shadow-2xl"
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
  />
);

const ParallaxSection = ({ children, speed = 10 }) => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div
      style={{
        y: useTransform(useMotionValue(scrollY), [0, 500], [0, -speed * 50]),
      }}
    >
      {children}
    </motion.div>
  );
};

const FloatingParticle = () => {
  const x = useMotionValue(Math.random() * 100);
  const y = useMotionValue(Math.random() * 100);

  useEffect(() => {
    const intervalId = setInterval(() => {
      x.set(Math.random() * 100);
      y.set(Math.random() * 100);
    }, Math.random() * 5000 + 2000);

    return () => clearInterval(intervalId);
  }, [x, y]);

  return (
    <motion.div
      className="absolute w-2 h-2 bg-violet-400 rounded-full opacity-50"
      style={{ x, y }}
      transition={{ duration: 2, ease: "easeInOut" }}
    />
  );
};

const TimelineItem = ({ year, title, description, side }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className={`mb-8 flex justify-between items-center w-full ${side === 'left' ? 'flex-row-reverse' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <div className="order-1 w-5/12"></div>
      <motion.div
        className="z-20 flex items-center order-1 bg-violet-800 shadow-xl w-11 h-11 rounded-full border-4 border-violet-800"
        whileHover={{ scale: 1.3, rotate: 360 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="mx-auto font-semibold text-lg text-white">{year}</h1>
      </motion.div>
      <motion.div
        className="order-1 bg-violet-900 rounded-lg shadow-xl w-5/12 px-4 sm:px-6 py-4"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
      >
        <h3 className="mb-2 sm:mb-3 font-bold text-white text-lg sm:text-xl">
          {title}
        </h3>
        <p className="text-sm leading-snug tracking-wide text-violet-100 text-opacity-100">
          {description}
        </p>
      </motion.div>
    </motion.div>
  );
};



const SVGDoubleHelix = () => {
  return (
    <svg
      viewBox="0 0 800 600"
      className="w-full h-full opacity-20"
      style={{
        position: 'absolute',
        top: '130%', // Adjust this value to move the helix lower
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <DNAStrand color="url(#grad1)" offset={0} />
      <DNAStrand color="url(#grad1)" offset={Math.PI} />
    </svg>
  );
};

const DNAStrand = ({ color, offset }) => {
  const points = 20;
  const ySpread = 80;
  const xSpread = 800;

  return (
    <>
      {[...Array(points)].map((_, i) => {
        const x = (i / (points - 1)) * xSpread;
        const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
        return (
          <g key={i}>
            <motion.circle
              cx={x}
              cy={y}
              r={3}
              fill={color}
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
            {i < points - 1 && (
              <motion.line
                x1={x}
                y1={y}
                x2={(i + 1) / (points - 1) * xSpread}
                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                stroke={color}
                strokeWidth={1}
                initial={{ pathLength: 0 }}
                animate={{ pathLength: [0, 1, 0] }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: i * 0.2,
                }}
              />
            )}
          </g>
        );
      })}
    </>
  );
};


const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  // Social media details
  const socialIcons = [
    { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
    { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
    { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
  ];



  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      emailjs.send(
        'service_f0vv34a',  // Your service ID
        'template_x9vg3re', // Your template ID
        {
          to_email: email,  // Dynamically set the recipient's email
          message: 'Thank you for subscribing to our newsletter!'
        },
        'oM0GV2_SQASnm0uWu'    // Your public key
      )
        .then((response) => {
          console.log('Email sent successfully:', response);
          setShowThankYou(true);
          setEmail('');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('There was an error subscribing. Please try again later.');
        });
    }
  };

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => setShowThankYou(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const ThankYouAnimation = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
        gravity={0.2}
      />
      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className="text-9xl mb-8"
      >
        🎉
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="text-center"
      >
        <motion.h2
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
          className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
        >
          Thank You!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-3xl text-purple-200 drop-shadow-md"
        >
          You've subscribed to my newsletter!
        </motion.p>
      </motion.div>
    </motion.div>
  );

  return (
    <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and tagline */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="col-span-1 md:col-span-2 lg:col-span-1"
          >
            <div className="flex items-center mb-4">
              {/* Logo */}
              <img
                src="/Y.png"
                alt="Logo"
                className="text-4xl text-purple-400 mr-3"
                style={{ height: '160px' }}  // Larger size for the logo
              />
            </div>
            <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
            >
              <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
            </motion.button>
          </motion.div>

          {/* Quick links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
            <ul className="space-y-2">
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                  About Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                  Projects
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                  Contact Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                  Blog
                </a>
              </motion.li>
            </ul>
          </motion.div>

          {/* Latest Blog */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
            >
              <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
              <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
              <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
            </motion.div>
          </motion.div>

          <NewsletterSignup />
        </div>

        {/* Social Icons */}
        <motion.div
          className="flex justify-center space-x-6 mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          {socialIcons.map(({ Icon, color, name, url }) => (
            <motion.a
              key={name}
              href={url}
              target="_blank" // Opens link in new tab
              rel="noopener noreferrer" // Security for external links
              onMouseEnter={() => setHoveredIcon(name)}
              onMouseLeave={() => setHoveredIcon(null)}
              whileHover={{ y: -5 }}
              className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
            >
              <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
              <AnimatePresence>
                {hoveredIcon === name && (
                  <motion.span
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                  >
                    {name}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.a>
          ))}
        </motion.div>

        {/* DNA Animation */}
        <SVGDoubleHelix />

        {/* Floating Particles */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-purple-500 rounded-full opacity-20"
              style={{
                width: Math.random() * 10 + 5,
                height: Math.random() * 10 + 5,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -30, 0],
                opacity: [0.2, 0.5, 0.2],
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            />
          ))}
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <p>&copy; {currentYear} Yasmine. All rights reserved. |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Privacy Policy Coming Soon!")}
            >
              Privacy Policy
            </button> |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Terms of Service Coming Soon!")}
            >
              Terms of Service
            </button>
          </p>
        </motion.div>
      </div>
      <AnimatePresence>
        {showThankYou && <ThankYouAnimation />}
      </AnimatePresence>
    </footer>
  );
};



const AboutPage = () => {
  const [currentQuote, setCurrentQuote] = useState(0);
  const quotes = [
    "In The Vast Landscape Of Bioinformatics, I'm Charting New Frontiers.",
    "With Every Dataset, I Uncover The Hidden Patterns Of Cancer.",
    "Bioinformatics Is My Canvas, And I’m Painting The Future Of Molecular Science.",
    "Deciphering Cancer’s Complex Code, One Algorithm At A Time.",
    "AI Transforms Raw Data Into Actionable Insights In Bioinformatics."
  ];
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
        (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  useEffect(() => {
    const disableInspectMethods = (e) => {
      // Prevent "Inspect Element" via right-click or drag and drop on element
      if (e.type === 'mousedown' && e.button === 2) {
        e.preventDefault();
      }
    };

    document.addEventListener('mousedown', disableInspectMethods);

    return () => {
      document.removeEventListener('mousedown', disableInspectMethods);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [quotes.length]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    {
      src: "https://i.imghippo.com/files/HYpLj1726417819.jpg",
      descriptionlink: {
        text: "View the Certificate",
        url: "https://www.futurelearn.com/certificates/b5az1on"
      }
    }, {
      src: "https://i.postimg.cc/MHGWGQRk/IMG-20241007-175909-519.jpg",
      descriptionlink: {
        text: "View the Certificate",
        url: "https://coursera.org/share/d298cbd0b03fee66de87964231748bf8"
      }
    },
  ];

  const slideLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Function to go to the next image
  const slideRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-violet-980 to-black text-violet-100 overflow-hidden">
      <ParticleBackground />
      <ScrollProgressBar />
      <MenuBar />
      <div className="relative">
        {[...Array(50)].map((_, i) => (
          <FloatingParticle key={i} />
        ))}
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 ">
        <motion.h1
          className="text-6xl md:text-8xl font-extrabold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-violet-400 via-fuchsia-500 to-purple-600"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          style={{ lineHeight: '1.3' }}
        >
          Yasmine Elnasharty
        </motion.h1>

        <motion.div
          className="text-3xl text-center text-violet-300 mb-12 h-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <AnimatePresence mode="wait">
            <motion.p
              key={currentQuote}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {quotes[currentQuote]}
            </motion.p>
          </AnimatePresence>
        </motion.div>

        <ParallaxSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20">
            <div className="flex flex-col justify-between">
              <h2 className="text-4xl font-bold mb-6 text-violet-300">My Story</h2>
              <div className="space-y-4">
                <p className="text-lg">
                  Hello! I'm Yasmine Elnashary, and I'm excited to share my journey with you. I grew up in Desouk, Egypt, a tranquil city where my passion for science first took root. This passion was fostered by a dedicated science teacher in high school and an exceptional English language teacher who began guiding me when I was just seven years old. Their support and inspiration were pivotal in shaping my academic interests and nurturing my love for learning. My family's unwavering encouragement played a crucial role in my success, allowing me to achieve a remarkable 96% in high school. This accomplishment enabled me to pursue my dream at the Faculty of Science at Tanta University, one of Egypt's oldest and most esteemed institutions for scientific studies.
                </p>
                <p className="text-lg">
                  During my four years at Tanta University, I immersed myself fully in my studies, consistently earning A+ grades and engaging deeply with both my coursework and academic community. I maintained close connections with my colleagues and professors, always striving to expand my knowledge and understanding. In my third year, I discovered bioinformatics and coding, which captivated my interest. I chose to focus my graduation project on the Genetics of Cancer, driven by the profound impact of cancer research and its importance in the field of bioinformatics. This experience solidified my commitment to integrating bioinformatics with cancer research, becoming a driving force in my academic and professional aspirations.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-full h-full max-h-[660px] overflow-hidden">
                <RealImage
                  src="https://i.imghippo.com/files/trCP21726320461.jpg"
                  alt="Young Jane Doe reading a biology book"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </ParallaxSection>

        <ParallaxSection speed={15}>
          <div className="mb-10 sm:mb-20">
            <h2 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-6 text-center text-violet-300">
              Academic Odyssey
            </h2>
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-4 sm:p-10 h-full">
                <motion.div
                  className="border-2 absolute border-opacity-20 border-violet-700 h-full"
                  style={{ left: '50%' }}
                  initial={{ height: 0 }}
                  animate={{ height: '100%' }}
                  transition={{ duration: 2, ease: 'easeInOut' }}
                />
                <TimelineItem
                  year="2019"
                  title="High School Graduate"
                  description="Graduated high school with a 96% average."
                  side="right"
                />
                <TimelineItem
                  year="2023"
                  title="Undergraduate Studies"
                  description="Bachelor of Science in Industrial Biotechnology from Tanta University, Faculty of Science. Developed a foundation in biotechnology and first encountered bioinformatics."
                  side="left"
                />
                {/* <TimelineItem
                  year="2013"
                  title="Doctoral Thesis"
                  description="Defended thesis on 'Efficient Methods for Processing Next-Generation Sequencing Data in Real-Time'."
                  side="left"
                /> */}
                {/* <TimelineItem
                  year="2014"
                  title="Postdoctoral Fellowship"
                  description="Joined the Broad Institute for a two-year fellowship, expanding research into single-cell genomics."
                  side="right"
                /> */}
              </div>
            </div>
          </div>
        </ParallaxSection>


        <ParallaxSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20">
            <div className="flex items-center justify-center order-2 md:order-1">
              <div className="w-full h-full max-h-[600px] overflow-hidden">
                <RealImage
                  src="https://i.imghippo.com/files/BXm8T1726213248.jpg"
                  alt="Yasmine Elnasharty at a scientific conference"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between order-1 md:order-2">
              <h2 className="text-4xl font-bold mb-6 text-violet-300">After Graduation</h2>
              <div className="space-y-4">
                <p className="text-lg">
                  After graduation, I committed myself fully to advancing in bioinformatics and coding. I immersed myself in courses, books, and online training to deepen my expertise. My goal is to build a solid foundation in bioinformatics, enabling me to pursue a master’s degree and eventually become a clinical bioinformatician. My fascination with cancer bioinformatics drives my ongoing studies and research, as I seek to contribute to the field and make a meaningful impact.
                </p>
                <p className="text-lg">
                  I am continuously exploring the latest developments and techniques in cancer genomics, aiming to uncover new insights that could lead to more effective treatments. By staying engaged with the latest research and collaborating with experts, I strive to stay at the forefront of the field and enhance my contributions to cancer research. My dedication to this field fuels my ambition to bridge the gap between computational analysis and clinical applications, advancing personalized medicine and improving patient outcomes.
                </p>
              </div>
            </div>
          </div>
        </ParallaxSection>

        {/* <ParallaxSection speed={20}>
          <div className="mb-20">
            <h2 className="text-4xl font-bold mb-6 text-center text-violet-300">Contributions to Science</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div
                className="bg-violet-800 bg-opacity-30 p-6 rounded-lg backdrop-blur-sm"
                whileHover={{ scale: 1.05, rotate: 2 }}
              >
                <h3 className="text-2xl font-semibold mb-4">FastGenome Algorithm</h3>
                <p>Developed a revolutionary algorithm that increased the speed of genome sequencing analysis by 200%, now widely used in research labs worldwide.</p>
              </motion.div>
              <motion.div
                className="bg-violet-800 bg-opacity-30 p-6 rounded-lg backdrop-blur-sm"
                whileHover={{ scale: 1.05, rotate: -2 }}
              >
                <h3 className="text-2xl font-semibold mb-4">AI in Drug Discovery</h3>
                <p>Pioneered the use of artificial intelligence in predicting drug-target interactions, leading to the discovery of three novel compounds now in clinical trials.</p>
              </motion.div>
              <motion.div
                className="bg-violet-800 bg-opacity-30 p-6 rounded-lg backdrop-blur-sm"
                whileHover={{ scale: 1.05, rotate: 2 }}
              >
                <h3 className="text-2xl font-semibold mb-4">BioViz Platform</h3>
                <p>Created an open-source platform for intuitive visualization of complex biological data, democratizing access to insights from large-scale studies.</p>
              </motion.div>
            </div>
          </div>
        </ParallaxSection> */}

        <ParallaxSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="flex flex-col justify-between">
              <h2 className="text-4xl font-bold mb-6 text-violet-300">Current Endeavors</h2>
              <div className="space-y-4">
                <p className="text-lg">
                  I'm fully immersed in bioinformatics, constantly expanding my expertise through projects in genomic data analysis, algorithm development, and computational biology. My primary focus is cancer bioinformatics, where I investigate genetic mutations and their roles in cancer progression, leveraging bioinformatics tools to identify potential therapeutic targets. These projects enhance my technical skills and contribute valuable insights to the scientific community.
                </p>
                <p className="text-lg">
                  To stay at the forefront of the field, I engage in advanced courses, online workshops, and collaborate with experts. I actively participate in conferences and symposiums, presenting my findings and exchanging ideas with peers. This portfolio documents my journey, showcasing my projects and commitment to bioinformatics, and serves as a platform to connect with like-minded professionals and contribute to the field's future.
                </p>
              </div>
            </div>
            <div className="flex items-start justify-center">
              <div className="w-full h-auto aspect-[4/3] overflow-hidden">
                <RealImage
                  src="https://i.imghippo.com/files/wSb8q1726213216.jpg"
                  alt="Code editor showing bioinformatics work"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </ParallaxSection>
        <ParallaxSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-20 mt-16">
            <div className="relative h-100">
              <RealImage
                src="https://i.imghippo.com/files/ThfCc1726061137.png"
                alt="Yasmine Elnasharty speaking at a conference about the future of bioinformatics"
              />
            </div>
            <div>
              <h2 className="text-4xl font-bold mb-6 text-violet-300">Vision for the Future</h2>
              <p className="mb-4 text-lg">
                Looking ahead, I aim to make a significant impact in bioinformatics, particularly in cancer research. Pursuing a master’s degree will allow me to delve deeper into the intersection of biology and computer science, equipping me with the skills to tackle complex challenges in personalized medicine. My ultimate goal is to become a clinical bioinformatician, where I can develop predictive models that integrate genomic and clinical data to guide treatment decisions, advancing the effectiveness of cancer care.</p>
              <p className="mb-4 text-lg">
                Beyond my professional aspirations, I’m passionate about contributing to the bioinformatics community by mentoring the next generation and fostering collaboration. I plan to continue pushing the boundaries of research, translating findings into real-world applications that benefit patients. Driven by curiosity and a commitment to making a meaningful difference, I am excited to see where this journey in bioinformatics will take me.
              </p>
              <p className="text-lg">
              </p>
            </div>
          </div>
        </ParallaxSection>
        <ParallaxSection speed={25}>
          <div className="mb-20">
            <h2 className="text-4xl font-bold mb-6 text-center text-violet-300">Key Achievements</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                { icon: BookOpen, label: "Projects", count: 5 },
                { icon: Star, label: "Skills", count: 14 },
                { icon: Award, label: "Certificates", count: 3 },
                { icon: ArrowDown, label: "Blogs", count: 5 },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-violet-800 bg-opacity-30 p-6 rounded-lg backdrop-blur-sm text-center"
                  whileHover={{ scale: 1.05, rotate: index % 2 === 0 ? 2 : -2 }}
                >
                  <item.icon className="w-12 h-12 mx-auto mb-4 text-fuchsia-400" />
                  <h3 className="text-2xl font-semibold mb-2">{item.label}</h3>
                  <p className="text-4xl font-bold text-fuchsia-400">
                    <AnimatedCounter target={item.count} />
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </ParallaxSection>

        {/* certificates section */}

        <section className="p-4 sm:p-8 bg-violet-800 bg-opacity-30 rounded-lg backdrop-blur-sm text-gray-800 relative overflow-hidden">
          <h2 className="text-4xl font-bold mb-9 text-center text-violet-300">
            My Certificates
          </h2>

          <div className="relative w-full max-w-5xl mx-auto flex items-center justify-center">
            <div className="relative w-full">
              {/* Image and Description */}
              <div className="w-full overflow-hidden rounded-lg shadow-lg bg-violet-800 bg-opacity-0">
                <AnimatePresence mode="wait" initial={false}>
                  <motion.div
                    key={currentIndex}
                    className="w-full flex flex-col items-center"
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                  >
                    <motion.img
                      src={images[currentIndex].src}
                      alt={`Certificate ${currentIndex + 1}`}
                      className="w-full sm:w-3/4 h-auto object-contain"
                      initial={{ scale: 0.9 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.3 }}
                    />
                    <motion.div
                      className="w-full p-4 sm:p-7 text-center text-violet-300 rounded-b-lg shadow-lg bg-violet-800 bg-opacity-0"
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.5 }}
                    >
                      <motion.a
                        href={images[currentIndex].descriptionlink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-6 py-2 bg-violet-500 text-white rounded-lg shadow-md"
                        whileHover={{
                          scale: 1.05,
                          backgroundColor: "#6B46C1",
                          boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)",
                        }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: "easeInOut" }}
                      >
                        View Certificate
                        <motion.span
                          className="ml-2 inline-block"
                          animate={{ x: [0, 5, 0] }}
                          transition={{ repeat: Infinity, duration: 1.5 }}
                        >
                          &#8594;
                        </motion.span>
                      </motion.a>
                    </motion.div>
                  </motion.div>
                </AnimatePresence>
              </div>

              {/* Left Arrow Button */}
              <motion.button
                onClick={slideLeft}
                whileHover={{
                  scale: 1.15,
                  boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)",
                }}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 p-2 sm:p-4 bg-violet-500 text-white rounded-full shadow-lg transition duration-300"
              >
                &#8592;
              </motion.button>

              {/* Right Arrow Button */}
              <motion.button
                onClick={slideRight}
                whileHover={{
                  scale: 1.15,
                  boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)",
                }}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 p-2 sm:p-4 bg-violet-500 text-white rounded-full shadow-lg transition duration-300"
              >
                &#8594;
              </motion.button>
            </div>
          </div>
        </section>

        {/* New section: Project Trend */}
        <ParallaxSection>
          <div className="mt-20 mb-20">
            <h2 className="text-4xl font-bold mb-6 text-center text-violet-300">Projects Trend</h2>
            <ProjectsChart />
          </div>
        </ParallaxSection>


        {/* New section: Testimonials */}
        <ParallaxSection speed={20}>
          <div className="mb-20">
            <h2 className="text-4xl font-bold mb-6 text-center text-violet-300">What Professors Say</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  quote: "Yasmine consistently demonstrates a deep understanding of bioinformatics algorithms and their practical applications in genomics.",
                  author: "Dr. Safaa Radwan, Tanta University"
                },
                {
                  quote: "Yasmine's dedication to research and continuous learning in bioinformatics is truly commendable.",
                  author: "Dr. Ashraf Haider, Tanta University"
                },
                {
                  quote: "Her collaborative spirit and enthusiasm for interdisciplinary projects make her a standout student.",
                  author: "Dr. Reda Gaafar, Tanta University"
                }
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  className="bg-violet-800 bg-opacity-30 p-6 rounded-lg backdrop-blur-sm"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <p className="mb-4 italic">"{testimonial.quote}"</p>
                  <p className="text-right text-fuchsia-400">- {testimonial.author}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </ParallaxSection>

        <ParallaxSection>
          <div className="mb-20">
            <h2 className="text-4xl font-bold mb-6 text-center text-violet-300">Research Interests</h2>
            <motion.div
              className="flex flex-wrap justify-center items-center gap-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              {[
                "Genomics", "Machine Learning", "Drug Discovery", "Personalized Medicine",
                "Bioinformatics", "AI in Healthcare", "Data Visualization", "Computational Biology",
                "Single-cell Analysis", "Systems Biology", "Precision Oncology", "Proteomics"
              ].map((word, index) => (
                <motion.div
                  key={word}
                  className="bg-violet-800 bg-opacity-30 px-4 py-2 rounded-full text-violet-200 cursor-pointer"
                  whileHover={{ scale: 1.1, backgroundColor: "#D946EF" }}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {word}
                </motion.div>
              ))}
            </motion.div>
          </div>
        </ParallaxSection>
        <ScrollToTopButton />
        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;
