import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation, useMotionValue } from 'framer-motion';
import { FaGithub, FaFilter } from 'react-icons/fa';
import { FaLinkedin, FaEnvelope, FaTwitter } from 'react-icons/fa';
import { SiPython, SiR, SiDocker, SiJupyter, SiGit, SiTensorflow, SiReact, SiNodedotjs, SiLinux, SiGnubash } from 'react-icons/si';
import { FaDna, FaMicroscope, FaBrain } from 'react-icons/fa'; // AI/ML icon, DNA icon, and Microscopy icon
import { MdOutlineBiotech } from 'react-icons/md'; // Biotech and Science icons
import { GiArtificialIntelligence, GiDna2, GiDna1 } from 'react-icons/gi'; // Generative AI and alternate DNA icons
import { AiOutlineRobot } from 'react-icons/ai'; // New icon for Large Language Models (LLM)
import { useNavigate } from 'react-router-dom'; // Add this import
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import { ArrowRight } from 'lucide-react';
import { SiDart } from 'react-icons/si';
import emailjs from 'emailjs-com';
import NewsletterSignup from './NewsletterSignup'; 

const Particle = ({ color }) => {
  const x = useMotionValue(Math.random() * window.innerWidth);
  const y = useMotionValue(Math.random() * window.innerHeight);
  const size = Math.random() * 4 + 1;

  useEffect(() => {
    const intervalId = setInterval(() => {
      x.set(Math.random() * window.innerWidth);
      y.set(Math.random() * window.innerHeight);
    }, Math.random() * 5000 + 2000);

    return () => clearInterval(intervalId);
  }, [x, y]);

  return (
    <motion.div
      className="absolute rounded-full"
      style={{
        x,
        y,
        width: size,
        height: size,
        backgroundColor: color,
        boxShadow: `0 0 ${size * 2}px ${color}`,
      }}
      transition={{ duration: 2, ease: 'easeInOut' }}
    />
  );
};
const ParticleBackground = () => {
  return (
    <div className="fixed inset-0 z-0">
      {[...Array(100)].map((_, i) => (
        <Particle key={i} color={i % 2 === 0 ? '#8B5CF6' : '#D946EF'} />
      ))}
    </div>
  );
};

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      // Show the scroll icon if user scrolled down 300 pixels or more
      if (!showScroll && window.scrollY > 300) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
      onClick={scrollTop}
    >
      <FaChevronUp size={24} />
    </div>
  );
};

const projects = [
  {
    title: 'Assessing Anemia in Pregnant and Non-Pregnant Women',
    description: 'A project examines and compares the prevalence and severity of anemia in pregnant and non-pregnant women using a combination of clinical data and bioinformatics analysis.',
    techStack: ['WHO', 'Python'],
    category: 'Data Analysis',
    githubLink: 'https://github.com/YasmineElnasharty/Assessing_Anemia_in_Pregnant_and_Non-Pregnant_Women.git',
    liveDemo: '/projects/Project1',
    image: 'https://img.freepik.com/free-photo/3d-render-blood-cells-abstract-background_1048-6338.jpg?size=626&ext=jpg&ga=GA1.2.487250843.1725002202&semt=ais_hybrid',
    url: '/projects/Project1'
  },
  {
    title: 'Comparative Genomic Analysis Identifying and Characterizing (SNPs) in DNA Sequences',
    description: 'A project conducts a comparative genomic analysis to identify and characterize single nucleotide polymorphisms (SNPs) in DNA sequences.',
    techStack: ['NCBI', 'Python'],
    category: 'Genomics',
    githubLink: 'https://github.com/YasmineElnasharty/Comparative_Genomic_Analysis_Identifying_and_Characterizing_-SNPs-_in_DNA_Sequences.git',
    liveDemo: '/projects/Project2',
    image: 'https://i.ytimg.com/vi/x4m1_P5Ljf8/maxresdefault.jpg',
    url: '/projects/Project2'
  },
  {
    title: 'Exploring Protein Folding with AlphaFold',
    description: 'A project investigates protein folding mechanisms using AlphaFold to predict and analyze protein structures.',
    techStack: ['Python', 'AI', 'AlfhaFold', 'ChimeraX'],
    category: 'Bioinformatics',
    githubLink: 'https://github.com/YasmineElnasharty/Exploring_Protein_Folding_with_AlphaFold.git',
    liveDemo: '#',
    image: 'https://t3.ftcdn.net/jpg/08/32/93/74/240_F_832937483_yubrxQBohjjh5XCFarDKaqizJh05E0Gi.jpg',
    url: '/projects/Project3'
  },
  // {
  //   title: 'Protein Sequence Relationship Analysis',
  //   description: 'A project focuses on analyzing relationships between protein sequences to understand their structural and functional similarities.',
  //   techStack: ['InterProScan', 'Python', 'AlfhaFold'],
  //   category: 'Bioinformatics',
  //   githubLink: '#',
  //   liveDemo: '#',
  //   image: 'https://i.pinimg.com/564x/52/4e/27/524e2789ee77ec35ab80ff4d4313bf17.jpg'
  // },
  // {
  //   title: 'Cancer Genomics Dashboard',
  //   description: 'A dashboard for visualizing and analyzing cancer genomics datasets.',
  //   techStack: ['React', 'Plotly', 'GraphQL', 'PostgreSQL'],
  //   category: 'Bioinformatics',
  //   githubLink: '#',
  //   liveDemo: '#',
  //   image: 'https://www.biocode.org.uk/wp-content/uploads/2023/02/Course-Thumbnail-for-Cancer-Genomics-NGS-Whole-Genome-Sequencing-Variant-Calling-Using-Linux.webp'
  // },
  // {
  //   title: 'Deep Learning for Drug Discovery',
  //   description: 'A neural network model for predicting drug-target interactions and optimizing drug discovery pipelines.',
  //   techStack: ['PyTorch', 'React', 'FastAPI', 'Docker'],
  //   category: 'AI/ML',
  //   githubLink: '#',
  //   liveDemo: '#',
  //   image: 'https://www.frontiersin.org/files/Articles/739684/fmicb-12-739684-HTML/image_m/fmicb-12-739684-g001.jpg'
  // },
];

const SVGDoubleHelix = () => {
  return (
    <svg
      viewBox="0 0 800 600"
      className="w-full h-full opacity-20"
      style={{
        position: 'absolute',
        top: '130%', // Adjust this value to move the helix lower
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <DNAStrand color="url(#grad1)" offset={0} />
      <DNAStrand color="url(#grad1)" offset={Math.PI} />
    </svg>
  );
};

const DNAStrand = ({ color, offset }) => {
  const points = 20;
  const ySpread = 80;
  const xSpread = 800;

  return (
    <>
      {[...Array(points)].map((_, i) => {
        const x = (i / (points - 1)) * xSpread;
        const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
        return (
          <g key={i}>
            <motion.circle
              cx={x}
              cy={y}
              r={3}
              fill={color}
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
            {i < points - 1 && (
              <motion.line
                x1={x}
                y1={y}
                x2={(i + 1) / (points - 1) * xSpread}
                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                stroke={color}
                strokeWidth={1}
                initial={{ pathLength: 0 }}
                animate={{ pathLength: [0, 1, 0] }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: i * 0.2,
                }}
              />
            )}
          </g>
        );
      })}
    </>
  );
};


const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  // Social media details
  const socialIcons = [
    { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
    { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
    { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
  ];


  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      emailjs.send(
        'service_f0vv34a',  // Your service ID
        'template_x9vg3re', // Your template ID
        {
          to_email: email,  // Dynamically set the recipient's email
          message: 'Thank you for subscribing to our newsletter!'
        },
        'oM0GV2_SQASnm0uWu'    // Your public key
      )
      .then((response) => {
        console.log('Email sent successfully:', response);
        setShowThankYou(true);
        setEmail('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('There was an error subscribing. Please try again later.');
      });
    }
  };

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => setShowThankYou(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const ThankYouAnimation = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
        gravity={0.2}
      />
      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className="text-9xl mb-8"
      >
        🎉
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="text-center"
      >
        <motion.h2
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
          className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
        >
          Thank You!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-3xl text-purple-200 drop-shadow-md"
        >
          You've subscribed to my newsletter!
        </motion.p>
      </motion.div>
    </motion.div>
  );

  return (
    <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and tagline */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="col-span-1 md:col-span-2 lg:col-span-1"
          >
            <div className="flex items-center mb-4">
              {/* Logo */}
              <img
                src="/Y.png"
                alt="Logo"
                className="text-4xl text-purple-400 mr-3"
                style={{ height: '160px' }}  // Larger size for the logo
              />
            </div>
            <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
            >
              <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
            </motion.button>
          </motion.div>

          {/* Quick links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
            <ul className="space-y-2">
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                  About Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                  Projects
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                  Contact Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                  Blog
                </a>
              </motion.li>
            </ul>
          </motion.div>

          {/* Latest Blog */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
            >
              <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
              <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
              <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
            </motion.div>
          </motion.div>

          <NewsletterSignup />
        </div>

        {/* Social Icons */}
        <motion.div
          className="flex justify-center space-x-6 mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          {socialIcons.map(({ Icon, color, name, url }) => (
            <motion.a
              key={name}
              href={url}
              target="_blank" // Opens link in new tab
              rel="noopener noreferrer" // Security for external links
              onMouseEnter={() => setHoveredIcon(name)}
              onMouseLeave={() => setHoveredIcon(null)}
              whileHover={{ y: -5 }}
              className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
            >
              <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
              <AnimatePresence>
                {hoveredIcon === name && (
                  <motion.span
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                  >
                    {name}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.a>
          ))}
        </motion.div>

        {/* DNA Animation */}
        <SVGDoubleHelix />

        {/* Floating Particles */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-purple-500 rounded-full opacity-20"
              style={{
                width: Math.random() * 10 + 5,
                height: Math.random() * 10 + 5,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -30, 0],
                opacity: [0.2, 0.5, 0.2],
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            />
          ))}
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <p>&copy; {currentYear} Yasmine. All rights reserved. |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Privacy Policy Coming Soon!")}
            >
              Privacy Policy
            </button> |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Terms of Service Coming Soon!")}
            >
              Terms of Service
            </button>
          </p>
        </motion.div>
      </div>
      <AnimatePresence>
        {showThankYou && <ThankYouAnimation />}
      </AnimatePresence>
    </footer>
  );
};


const MenuBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const linkStyle = {
    color: '#ffffff',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    display: 'inline-block',
    padding: '15px 20px',
    position: 'relative',
    fontWeight: '700',
  };

  const hoverEffectStyle = {
    content: '""',
    background: '#D946EF',
    bottom: 0,
    height: '2px',
    left: '50%',
    position: 'absolute',
    transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
    width: 0,
  };

  const liStyle = {
    display: 'table-cell',
    position: 'relative',
    padding: '15px 0',
  };

  const ulStyle = {
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    display: 'table',
    width: '800px',
    textAlign: 'center',
  };

  const mobileMenuStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '100%',
    backgroundColor: '#000',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
  };

  const mobileLinkStyle = {
    ...linkStyle,
    padding: '10px 20px',
  };

  return (
    <>
      {/* Desktop View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="desktop-menu"
      >
        <div
          style={{
            position: 'absolute',
            left: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/Y.png"
            alt="Logo"
            style={{ height: '160px', marginLeft: '50px' }}
            onClick={() => window.location.reload()}
          />
        </div>
        <ul style={ulStyle}>
          {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
            <motion.li
              key={text}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={liStyle}
            >
              <motion.a
                href={`/${text.toLowerCase()}`}
                style={linkStyle}
                whileHover={{ scale: 1.1 }}
                onMouseEnter={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '100%';
                  hoverSpan.style.left = '0';
                }}
                onMouseLeave={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '0';
                  hoverSpan.style.left = '50%';
                }}
              >
                {text}
                <span style={hoverEffectStyle}></span>
              </motion.a>
            </motion.li>
          ))}
        </ul>
      </motion.nav>

      {/* Mobile View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="mobile-menu"
      >
        <div
          style={{ padding: '15px 20px', cursor: 'pointer' }}
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
        </div>
        {isMobileMenuOpen && (
          <motion.ul
            style={mobileMenuStyle}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
              <motion.li
                key={text}
                style={{ padding: '10px 0' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                  {text}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </motion.nav>

      <style>
        {`
                  @media (min-width: 768px) {
                      .mobile-menu {
                          display: none;
                      }
                  }
                  @media (max-width: 767px) {
                      .desktop-menu {
                          display: none;
                      }
                  }
              `}
      </style>
    </>
  );
};

const categories = ['All', 'Bioinformatics', 'AI/ML', 'Genomics', 'Data Analysis'];

const SkillsShowcase = () => {
  const skills = [
    { name: 'Python', icon: SiPython, color: '#3776AB' },
    { name: 'R', icon: SiR, color: '#276DC3' },
    { name: 'React', icon: SiReact, color: '#61DAFB' },
    { name: 'Node.js', icon: SiNodedotjs, color: '#339933' },
    { name: 'Machine Learning', icon: FaBrain, color: '#FFDD57' },
    { name: 'Large Language Models (LLM)', icon: AiOutlineRobot, color: '#FF6F61' },
    { name: 'Generative AI', icon: GiArtificialIntelligence, color: '#FF4500' },
    { name: 'Genomics', icon: GiDna2, color: '#FF7043' },
    { name: 'Next-Generation Sequencing (NGS)', icon: GiDna1, color: '#8B5CF6' },
    { name: 'Bioconductor', icon: MdOutlineBiotech, color: '#008C9E' },
    { name: 'Docker', icon: SiDocker, color: '#2496ED' },
    { name: 'Jupyter', icon: SiJupyter, color: '#F37626' },
    { name: 'Git', icon: SiGit, color: '#F05032' },
    { name: 'TensorFlow', icon: SiTensorflow, color: '#FF6F00' },
    { name: 'Linux', icon: SiLinux, color: '#FCC624' },
    { name: 'Bash Scripting', icon: SiGnubash, color: '#4EAA25' },
    { name: 'DNA Sequencing', icon: FaDna, color: '#7B1FA2' },
    { name: 'Microscopy', icon: FaMicroscope, color: '#FF5733' },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [skillsPerSlide, setSkillsPerSlide] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      setSkillsPerSlide(window.innerWidth < 768 ? 4 : 5);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % skills.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [skills.length]);

  const getVisibleSkills = () => {
    const startIndex = currentSlide;
    return [
      ...skills.slice(startIndex, startIndex + skillsPerSlide),
      ...skills.slice(0, Math.max(0, (startIndex + skillsPerSlide) - skills.length)),
    ];
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="mb-16"
    >
      <h2 className="text-3xl font-bold text-center mb-8 text-purple-300">Skills & Technologies</h2>
      <div className="grid grid-cols-4 md:grid-cols-5 gap-4 md:gap-7">
        {getVisibleSkills().map((skill, index) => (
          <motion.div
            key={skill.name + index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.15, y: -10 }}
            className="flex flex-col items-center"
          >
            <motion.div
              className="w-16 h-16 md:w-20 md:h-20 rounded-full flex items-center justify-center mb-2 md:mb-4"
              style={{ backgroundColor: skill.color }}
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.8 }}
            >
              <skill.icon className="text-2xl md:text-4xl text-white" />
            </motion.div>
            <p className="text-purple-200 font-semibold text-center text-sm md:text-base">{skill.name}</p>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};




const PortfolioEnhanced = () => {



  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const controls = useAnimation();
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);


  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
        (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  useEffect(() => {
    const disableInspectMethods = (e) => {
      // Prevent "Inspect Element" via right-click or drag and drop on element
      if (e.type === 'mousedown' && e.button === 2) {
        e.preventDefault();
      }
    };

    document.addEventListener('mousedown', disableInspectMethods);

    return () => {
      document.removeEventListener('mousedown', disableInspectMethods);
    };
  }, []);

  const navigate = useNavigate(); // Initialize the navigate function
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (title) => {
    setExpanded(prev => ({ ...prev, [title]: !prev[title] }));
  };
  const filteredProjects = selectedCategory === 'All'
    ? projects
    : projects.filter((project) => project.category === selectedCategory);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  return (
    <div className="min-h-screen relative overflow-hidden bg-black">
      {/* Mesmerizing Abstract Background */}

      {/* Low Visibility Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-15 z-0 absolute inset-0 bg-purple-980 opacity-15 z-10"
        style={{ backgroundImage: 'url(https://i.imghippo.com/files/BLWjW1726218132.png)', backgroundSize: 'cover' }}
      ></div>



      <ParticleBackground />
      <div className="relative z-20 min-h-screen backdrop-sm">
        {/* Header Menu */}
        <MenuBar />

        <main className="pt-6 pb-8 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* Header */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center mb-16 mt-8"
            >
              <h1 className="text-5xl md:text-7xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600" style={{ lineHeight: '1.3' }}>
                Bioinformatics Projects
              </h1>

              <p className="text-xl text-purple-200 max-w-3xl mx-auto">
                Explore cutting-edge projects at the intersection of biology and computer science.
              </p>
            </motion.div>

            {/* Filter */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="flex justify-center mb-12"
            >
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center space-x-2 bg-purple-700 hover:bg-purple-600 text-white py-2 px-6 rounded-full shadow-lg transition-all duration-300 transform hover:scale-105"
              >
                <FaFilter />
                <span>Filter Projects</span>
              </button>
            </motion.div>

            {/* Category Filter */}
            <AnimatePresence>
              {isFilterOpen && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex justify-center mb-8"
                >
                  <div className="flex flex-wrap justify-center gap-4">
                    {categories.map((category, index) => (
                      <motion.button
                        key={category}
                        onClick={() => setSelectedCategory(category)}
                        className={`py-2 px-4 rounded-full transition-all duration-300 ${selectedCategory === category
                          ? 'bg-purple-600 text-white'
                          : 'bg-purple-800 text-purple-200 hover:bg-purple-700'
                          }`}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        {category}
                      </motion.button>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Featured Project Carousel */}
            <SkillsShowcase />

            {/* Projects Grid */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                gap: '30px',
                padding: '40px 20px',
                maxWidth: '1200px',
                margin: '0 auto',
              }}
            >
              <AnimatePresence>
                {filteredProjects.map((project, index) => (
                  <motion.div
                    key={project.title}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ scale: 1.05, boxShadow: '0 20px 40px rgba(139, 92, 246, 0.3)' }}
                    style={{
                      backgroundColor: 'rgba(139, 92, 246, 0.35)',
                      borderRadius: '20px',
                      overflow: 'hidden',
                      boxShadow: '0 10px 30px rgba(139, 92, 246, 0.2)',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(project.url)}
                  >
                    <div
                      style={{
                        height: '250px',
                        backgroundImage: `url(${project.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    ></div>
                    <div style={{ padding: '20px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <span style={{ color: '#D946EF', fontSize: '14px' }}>{project.category}</span>
                        <motion.a
                          href={project.githubLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          whileHover={{ scale: 1.2, rotate: 0 }}
                          whileTap={{ scale: 0.9 }}
                          className="text-gray-300 hover:text-purple-400 transition-colors duration-300"
                        >
                          <FaGithub size={24} />
                        </motion.a>
                      </div>
                      <h2
                        style={{
                          fontSize: '22px',
                          fontWeight: 'bold',
                          marginBottom: '10px',
                          color: '#E9D5FF',
                        }}
                      >
                        {project.title}
                      </h2>
                      <p style={{ fontSize: '16px', color: '#C4B5FD', marginBottom: '15px' }}>
                        {expanded[project.title]
                          ? project.description
                          : `${project.description.slice(0, 100)}...`}
                      </p>
                      {project.description.length > 100 && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand(project.title);
                          }}
                          style={{
                            color: '#A78BFA',
                            fontSize: '14px',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          {expanded[project.title] ? 'Show less' : 'Read more'}
                        </button>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '5px',
                          marginTop: '15px',
                          marginBottom: '15px',
                        }}
                      >
                        {project.techStack.map((tech) => (
                          <span
                            key={tech}
                            style={{
                              backgroundColor: 'rgba(167, 139, 250, 0.3)',
                              color: '#E9D5FF',
                              padding: '4px 8px',
                              borderRadius: '12px',
                              fontSize: '12px',
                            }}
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <motion.span
                          style={{
                            color: '#D946EF',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '16px',
                          }}
                          whileHover={{ x: 5 }}
                        >
                          View Project <ArrowRight style={{ marginLeft: '5px' }} size={16} />
                        </motion.span>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.div>

          </div>
        </main>

        <ScrollToTopButton />
        <Footer />
      </div>
    </div>
  );
};

export default PortfolioEnhanced;