// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgCaw4xxguXwZJRmDXStUWqAjf0lAxMPY",
  authDomain: "my-portfolio-97978.firebaseapp.com",
  projectId: "my-portfolio-97978",
  storageBucket: "my-portfolio-97978.appspot.com",
  messagingSenderId: "851611893208",
  appId: "1:851611893208:web:aa2370f76227968a43c6ad",
  measurementId: "G-EVJ97DHDD8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
