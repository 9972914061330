import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, Sparkles } from 'lucide-react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const CommentForm = ({ blogId }) => {
  const [commentContent, setCommentContent] = useState('');

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (commentContent.trim() === '') return;

    try {
      await addDoc(collection(db, "blogs", blogId, "comments"), { // Use blogId here
        username: 'User', // Replace with actual username if using authentication
        avatar: 'https://img.icons8.com/bubbles/100/user.png',
        content: commentContent,
        timestamp: serverTimestamp(),
        reactions: { heart: 0, clap: 0 },
      });
      setCommentContent('');
    } catch (error) {
      console.error("Error adding comment: ", error);
      alert("There was an error posting your comment. Please try again.");
    }
  };

  return (
    <motion.form
      onSubmit={handleCommentSubmit}
      className="mb-8 bg-gradient-to-br from-white to-purple-100 rounded-2xl shadow-lg p-6 relative overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-300 to-pink-300"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      />
      <textarea
        className="w-full p-4 border-2 border-purple-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-400 transition-all duration-300 resize-none bg-white bg-opacity-70 backdrop-filter backdrop-blur-sm"
        rows="4"
        placeholder="Share your thoughts..."
        value={commentContent}
        onChange={(e) => setCommentContent(e.target.value)}
      />
      <motion.button
        type="submit"
        className="mt-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-3 rounded-xl hover:shadow-lg transition-all duration-300 flex items-center justify-center group"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Send size={18} className="mr-2 group-hover:rotate-45 transition-transform duration-300" />
        Post Comment
        <Sparkles size={18} className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </motion.button>
    </motion.form>
  );
};

export default CommentForm;