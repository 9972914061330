import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { motion, AnimatePresence } from 'framer-motion';
import { Dna, Brain, ChartBar, Database, BookOpen, Layers } from 'lucide-react';


const DNAAnimation = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Constants
    const dnaHeight = 600;
    const radiusEdges = 3;
    const variationAngle = 0.1;
    const unionGap = 7;
    const edgeSize = 0.6;
    const verticalSeparation = edgeSize;
    const verticalOffset = -50;
    const DNA = new THREE.Group();

    // Scene
    const scene = new THREE.Scene();

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(10, 50, 50);
    scene.add(pointLight);

    // Objects
    const createDNAFragment = (hasBlade, index) => {
      const dnaFragment = new THREE.Group();
      const material = new THREE.MeshStandardMaterial({
        color: `#00A7F5`,
        emissive: `#00A7F5`,
        emissiveIntensity: 0.7,
        metalness: 0.8,
        roughness: 0.2
      });

      const firstEdge = new THREE.Mesh(
        new THREE.BoxGeometry(edgeSize, edgeSize, edgeSize),
        material
      );
      const secondEdge = new THREE.Mesh(
        new THREE.BoxGeometry(edgeSize, edgeSize, edgeSize),
        material
      );
      firstEdge.position.x = radiusEdges * Math.sin(index * variationAngle);
      firstEdge.position.z = radiusEdges * Math.cos(index * variationAngle);
      firstEdge.position.y = verticalSeparation * index + verticalOffset;
      secondEdge.position.x = -radiusEdges * Math.sin(index * variationAngle);
      secondEdge.position.z = -radiusEdges * Math.cos(index * variationAngle);
      secondEdge.position.y = verticalSeparation * index + verticalOffset;
      dnaFragment.add(firstEdge);
      dnaFragment.add(secondEdge);
      if (hasBlade) {
        const blade = new THREE.Group();
        const firstBlade = new THREE.Mesh(
          new THREE.BoxGeometry(edgeSize, edgeSize, radiusEdges),
          material
        );
        firstBlade.position.y = verticalSeparation * index + verticalOffset;
        firstBlade.position.z = radiusEdges / 2;
        const secondBlade = new THREE.Mesh(
          new THREE.BoxGeometry(edgeSize, edgeSize, radiusEdges),
          material
        );
        secondBlade.position.y = verticalSeparation * index + verticalOffset;
        secondBlade.position.z = -radiusEdges / 2;
        blade.add(firstBlade);
        blade.add(secondBlade);
        blade.rotateY(index * variationAngle);
        dnaFragment.add(blade);
      }
      DNA.add(dnaFragment);
    };

    for (let i = 0; i < dnaHeight; i++) {
      createDNAFragment(i % unionGap === 0, i);
    }
    scene.add(DNA);

    // Sizes
    const sizes = {
      width: window.innerWidth * 1,
      height: window.innerHeight * 4
    };

    // Camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
    camera.position.z = 70; // Adjusted to fit the entire length
    camera.position.y = 70;
    camera.position.x = 15;
    camera.lookAt(new THREE.Vector3(15, 70, 70));
    camera.rotateZ(Math.PI / 4);
    scene.add(camera);

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      canvas: mountRef.current
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setClearColor(0x000000, 0); // Set clear color to transparent
    renderer.render(scene, camera);

    // Time
    let time = Date.now();

    // Animations
    const draw = () => {
      const currentTime = Date.now();
      const deltaTime = currentTime - time;
      time = currentTime;
      DNA.rotateY(deltaTime * 0.001);
      renderer.render(scene, camera);
      window.requestAnimationFrame(draw);
    };
    draw();

    // Cleanup
    return () => {
      renderer.dispose();
    };
  }, []);

  return (
    <canvas
      ref={mountRef}
      className="absolute top-0 left-0 w-full h-full object-cover"
      style={{ filter: 'blur(10px)', opacity: 0.5, zIndex: 0, }} // Styling updates to shift right
    />
  );
};

const skills = [
  { icon: Dna, text: 'Genomics Expert' },
  { icon: Brain, text: 'AI Enthusiast' },
  { icon: ChartBar, text: 'Data Visualization Specialist' },
  { icon: Database, text: 'Big Data Analyst' },
  { icon: BookOpen, text: 'LLM Specialist' },
  { icon: Layers, text: 'Deep Learning Engineer' }
];

const titles = [
  "A Passionate Bioinformatician",
  "A Bioinformatics Innovator in AI",
  "A Genomics Pioneer",
  "A Cancer Bioinformatics Enthusiast",
];

const TypingText = ({ texts }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fullText = texts[currentTextIndex];

    if (!isDeleting && charIndex < fullText.length) {
      setTimeout(() => {
        setDisplayText(fullText.slice(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }, 150); // Slower addition
    } else if (isDeleting && charIndex > 0) {
      setTimeout(() => {
        setDisplayText(fullText.slice(0, charIndex - 1));
        setCharIndex(charIndex - 1);
      }, 50); // Faster deletion
    } else if (!isDeleting && charIndex === fullText.length) {
      setTimeout(() => setIsDeleting(true), 1000);
    } else if (isDeleting && charIndex === 0) {
      setTimeout(() => {
        setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        setIsDeleting(false);
      }, 500);
    }
  }, [charIndex, isDeleting, texts, currentTextIndex]);

  return (
    <h3
      className="text-4xl font-bold mb-6 text-center min-h-[48px]"
      style={{
        fontFamily: "'Poppins', sans-serif",
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        position: 'relative',
      }}
    >
      <span
        style={{
          fontSize: '1.2em',
          fontWeight: 'bold',
          WebkitTextStroke: '1px #ff00cc',
          position: 'relative',
          zIndex: 1,
        }}
      >
        I'm
      </span>{' '}
      <span
        style={{
          WebkitTextStroke: '1px #3333ff',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {displayText}
      </span>
    </h3>
  );
};

const DNABlock = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "https://i.imghippo.com/files/HtibP1726052474.jpg",
    "https://i.imghippo.com/files/aYz2Y1726052668.png"
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative py-12 md:py-24 overflow-hidden" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
      <div className="absolute inset-0 z-0">
        <DNAAnimation />
      </div>
      <div className="container mx-auto px-4">
        <Section
          title="My Story"
          heading="A Journey in Precision Bioinformatics"
          text="In my bioinformatics journey, I unravel the complexities of life through computational biology. My work drives breakthroughs in genomics, proteomics, and personalized medicine, shaping the future of healthcare and advancing scientific discovery."
          linkText="Learn More About Me →"
          linkHref="/about"
          imageSrc="https://i.postimg.cc/W4yQmLtF/medicine-doctor-hold-magnifier-search-medical-record-digital-healthcare-1207718-142502.jpg"
          imageAlt="DNA visualization"
          reverse={false}
        />
        <Section
          title="Projects"
          heading="Innovating Through Cutting-Edge Research"
          text="Explore my projects that span across bioinformatics, computational biology, and personalized medicine. Each project reflects my dedication to advancing science and improving human health through data-driven research."
          linkText="View My Projects →"
          linkHref="/Portfolio"
          imageSrc="https://i.postimg.cc/4N0mQxh1/image.png"
          imageAlt="Bioinformatics project visualization"
          reverse={true}
        />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-8 sm:mb-12 text-center"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
            Meet The Mind Behind The Code
          </span>
        </motion.h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="order-2 lg:order-1"
          >
            <TypingText texts={titles} />
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="text-lg sm:text-xl mb-6 sm:mb-8 text-gray-600 leading-relaxed italic"
              style={{ fontFamily: "'Lora', serif" }}
            >
              With a deep fascination for the intersection of biology and technology, I've dedicated my career to pushing the boundaries of what's possible in genomics and data analysis. My journey is fueled by curiosity and a relentless pursuit of innovation in the field of bioinformatics.
            </motion.p>
            <div className="grid grid-cols-2 gap-6 mt-8">
      {skills.map((skill, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="flex items-center space-x-4"
        >
          <motion.div
            whileHover={{ scale: 1.1, rotate: 360 }}
            transition={{ duration: 0.3 }}
            className="flex-shrink-0"
          >
            <skill.icon className="text-purple-500" size={28} />
          </motion.div>
          <div className="flex-grow">
            <p className="text-base font-medium text-gray-800">{skill.text}</p>
          </div>
        </motion.div>
      ))}
    </div>
          </motion.div>
          <div className="relative w-full h-64 sm:h-80 lg:h-96 order-1 lg:order-2 mb-8 lg:mb-0">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentImage}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                className="absolute inset-0"
              >
                <div className="w-full h-full bg-gradient-to-br from-blue-400 via-purple-400 to-pink-400 rounded-lg shadow-2xl transform rotate-3"></div>
                <img
                  src={images[currentImage]}
                  alt={currentImage === 0 ? "Your Name" : "Lab Work"}
                  className="absolute inset-0 w-full h-full object-cover rounded-lg shadow-2xl transform -rotate-3 transition-transform duration-300 hover:rotate-0"
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, heading, text, linkText, linkHref, imageSrc, imageAlt, reverse }) => {
  return (
    <div className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center my-12 md:my-24`}>
      <motion.div 
        className="w-full md:w-1/2 mb-8 md:mb-0"
        initial={{ opacity: 0, x: reverse ? 50 : -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <img 
          src={imageSrc} 
          alt={imageAlt} 
          className="rounded-full w-3/4 mx-auto md:mx-0 md:ml-28"
        />
      </motion.div>
      <motion.div 
        className="w-full md:w-1/2 px-4 md:px-8 relative"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <motion.span 
          className="text-sky-600 text-5xl md:text-9xl font-bold absolute -top-10 md:-top-20 left-0 md:left-6"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 0.1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          {title}
        </motion.span>
        <motion.h2 
          className="text-3xl md:text-5xl font-bold mb-6 mt-8 md:mt-16 relative z-10 bg-gradient-to-r from-purple-900 via-purple-800 to-blue-500 bg-clip-text text-transparent"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.6 }}
          style={{lineHeight: '1.2' }}
        >
          {heading}
        </motion.h2>
        <motion.p 
          className="text-base md:text-lg font-bold mb-8 text-gray-400"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          {text}
        </motion.p>
        <motion.a
          href={linkHref}
          className="inline-block px-6 py-3 text-lg font-semibold text-white bg-fuchsia-600 rounded-full hover:bg-fuchsia-700 transition duration-300"
          whileHover={{ 
            scale: 1.05, 
            boxShadow: '0px 0px 8px rgb(217, 70, 239)' 
          }}
          whileTap={{ scale: 0.95 }}
        >
          {linkText}
        </motion.a>
      </motion.div>
    </div>
  );
};

export { DNAAnimation, DNABlock };
