import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter, FaDownload } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import { AnimatePresence } from 'framer-motion';
import { SiJupyter, SiGooglecolab } from 'react-icons/si';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from '../NewsletterSignup'; 

const ScrollToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);
  
    useEffect(() => {
      const checkScrollTop = () => {
        // Show the scroll icon if user scrolled down 300 pixels or more
        if (!showScroll && window.scrollY > 300) {
          setShowScroll(true);
        } else if (showScroll && window.scrollY <= 300) {
          setShowScroll(false);
        }
      };
  
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);
  
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <div
        className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
        onClick={scrollTop}
      >
        <FaChevronUp size={24} />
      </div>
    );
  };

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const linkStyle = {
        color: '#1F2937',
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '0.15em',
        display: 'inline-block',
        padding: '15px 20px',
        position: 'relative',
        fontWeight: '700',
    };

    const hoverEffectStyle = {
        content: '""',
        background: '#D946EF',
        bottom: 0,
        height: '2px',
        left: '50%',
        position: 'absolute',
        transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        width: 0,
    };

    const liStyle = {
        display: 'table-cell',
        position: 'relative',
        padding: '15px 0',
    };

    const ulStyle = {
        margin: '0 auto',
        padding: 0,
        listStyle: 'none',
        display: 'table',
        width: '800px',
        textAlign: 'center',
    };

    const mobileMenuStyle = {
        position: 'absolute',
        top: '60px',
        left: '0',
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 100,
    };

    const mobileLinkStyle = {
        ...linkStyle,
        padding: '10px 20px',
    };

    return (
        <>
            {/* Desktop View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="desktop-menu"
            >
                <ul style={ulStyle}>
                    {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                        <motion.li
                            key={text}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            style={liStyle}
                        >
                            <motion.a
                                href={`/${text.toLowerCase()}`}
                                style={linkStyle}
                                whileHover={{ scale: 1.1 }}
                                onMouseEnter={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '100%';
                                    hoverSpan.style.left = '0';
                                }}
                                onMouseLeave={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '0';
                                    hoverSpan.style.left = '50%';
                                }}
                            >
                                {text}
                                <span style={hoverEffectStyle}></span>
                            </motion.a>
                        </motion.li>
                    ))}
                </ul>
            </motion.nav>

            {/* Mobile View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="mobile-menu"
            >
                <div
                    style={{ padding: '15px 20px', cursor: 'pointer' }}
                    onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                >
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                </div>
                {isMobileMenuOpen && (
                    <motion.ul
                        style={mobileMenuStyle}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                            <motion.li
                                key={text}
                                style={{ padding: '10px 0' }}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                                    {text}
                                </a>
                            </motion.li>
                        ))}
                    </motion.ul>
                )}
            </motion.nav>

            <style>
                {`
                    @media (min-width: 768px) {
                        .mobile-menu {
                            display: none;
                        }
                    }
                    @media (max-width: 767px) {
                        .desktop-menu {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
};
const SVGDoubleHelix = () => {
    return (
        <svg
            viewBox="0 0 800 600"
            className="w-full h-full opacity-20"
            style={{
                position: 'absolute',
                top: '130%',// Adjust this value to move the helix lower
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <DNAStrand color="url(#grad1)" offset={0} />
            <DNAStrand color="url(#grad1)" offset={Math.PI} />
        </svg>
    );
};
const DNAStrand = ({ color, offset }) => {
    const points = 20;
    const ySpread = 80;
    const xSpread = 800;

    return (
        <>
            {[...Array(points)].map((_, i) => {
                const x = (i / (points - 1)) * xSpread;
                const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
                return (
                    <g key={i}>
                        <motion.circle
                            cx={x}
                            cy={y}
                            r={3}
                            fill={color}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                delay: i * 0.2,
                            }}
                        />
                        {i < points - 1 && (
                            <motion.line
                                x1={x}
                                y1={y}
                                x2={(i + 1) / (points - 1) * xSpread}
                                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                                stroke={color}
                                strokeWidth={1}
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: [0, 1, 0] }}
                                transition={{
                                    duration: 3,
                                    repeat: Infinity,
                                    delay: i * 0.2,
                                }}
                            />
                        )}
                    </g>
                );
            })}
        </>
    );
};
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [email, setEmail] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
  
    // Social media details
    const socialIcons = [
      { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
      { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
      { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
    ];
  
  
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (email) {
        emailjs.send(
          'service_f0vv34a',  // Your service ID
          'template_x9vg3re', // Your template ID
          {
            to_email: email,  // Dynamically set the recipient's email
            message: 'Thank you for subscribing to our newsletter!'
          },
          'oM0GV2_SQASnm0uWu'    // Your public key
        )
        .then((response) => {
          console.log('Email sent successfully:', response);
          setShowThankYou(true);
          setEmail('');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('There was an error subscribing. Please try again later.');
        });
      }
    };
  
    useEffect(() => {
      if (showThankYou) {
        const timer = setTimeout(() => setShowThankYou(false), 5000);
        return () => clearTimeout(timer);
      }
    }, [showThankYou]);
  
    const ThankYouAnimation = () => (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.2}
        />
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          className="text-9xl mb-8"
        >
          🎉
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-center"
        >
          <motion.h2
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
            className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
          >
            Thank You!
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-3xl text-purple-200 drop-shadow-md"
          >
            You've subscribed to my newsletter!
          </motion.p>
        </motion.div>
      </motion.div>
    );
  
    return (
      <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Logo and tagline */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="col-span-1 md:col-span-2 lg:col-span-1"
            >
              <div className="flex items-center mb-4">
                {/* Logo */}
                <img
                  src="/Y.png"
                  alt="Logo"
                  className="text-4xl text-purple-400 mr-3"
                  style={{ height: '160px' }}  // Larger size for the logo
                />
              </div>
              <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
              >
                <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
              </motion.button>
            </motion.div>
  
            {/* Quick links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
              <ul className="space-y-2">
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                    About Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                    Projects
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                    Contact Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                    Blog
                  </a>
                </motion.li>
              </ul>
            </motion.div>
  
            {/* Latest Blog */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
              >
                <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
                <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
                <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
              </motion.div>
            </motion.div>
  
            <NewsletterSignup />
          </div>
  
          {/* Social Icons */}
          <motion.div
            className="flex justify-center space-x-6 mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            {socialIcons.map(({ Icon, color, name, url }) => (
              <motion.a
                key={name}
                href={url}
                target="_blank" // Opens link in new tab
                rel="noopener noreferrer" // Security for external links
                onMouseEnter={() => setHoveredIcon(name)}
                onMouseLeave={() => setHoveredIcon(null)}
                whileHover={{ y: -5 }}
                className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
              >
                <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
                <AnimatePresence>
                  {hoveredIcon === name && (
                    <motion.span
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                    >
                      {name}
                    </motion.span>
                  )}
                </AnimatePresence>
              </motion.a>
            ))}
          </motion.div>
  
          {/* DNA Animation */}
          <SVGDoubleHelix />
  
          {/* Floating Particles */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-purple-500 rounded-full opacity-20"
                style={{
                  width: Math.random() * 10 + 5,
                  height: Math.random() * 10 + 5,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.2, 0.5, 0.2],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              />
            ))}
          </div>
  
          {/* Copyright */}
          <motion.div
            className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <p>&copy; {currentYear} Yasmine. All rights reserved. |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Privacy Policy Coming Soon!")}
              >
                Privacy Policy
              </button> |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Terms of Service Coming Soon!")}
              >
                Terms of Service
              </button>
            </p>
          </motion.div>
        </div>
        <AnimatePresence>
          {showThankYou && <ThankYouAnimation />}
        </AnimatePresence>
      </footer>
    );
  };


const AnimatedBackground = () => {
    const [particles, setParticles] = useState([]);

    useEffect(() => {
        const generateParticles = () => {
            const newParticles = [];
            for (let i = 0; i < 50; i++) {
                newParticles.push({
                    x: Math.random() * window.innerWidth,
                    y: Math.random() * window.innerHeight,
                    size: Math.random() * 4 + 1,
                    speedX: (Math.random() - 0.5) * 0.5,
                    speedY: (Math.random() - 0.5) * 0.5,
                });
            }
            setParticles(newParticles);
        };

        generateParticles();

        const animateParticles = () => {
            setParticles((prevParticles) =>
                prevParticles.map((particle) => ({
                    ...particle,
                    x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
                    y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
                }))
            );
        };

        const intervalId = setInterval(animateParticles, 50);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="fixed inset-0 pointer-events-none">
            {particles.map((particle, index) => (
                <motion.div
                    key={index}
                    className="absolute rounded-full bg-purple-200"
                    style={{
                        width: particle.size,
                        height: particle.size,
                        x: particle.x,
                        y: particle.y,
                    }}
                    animate={{
                        x: particle.x,
                        y: particle.y,
                    }}
                    transition={{
                        duration: 0.05,
                        ease: "linear",
                    }}
                />
            ))}
        </div>
    );
}

const Project2 = () => {
    const [activeSection, setActiveSection] = useState('overview');
    useEffect(() => {
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
      
        document.addEventListener('contextmenu', handleContextMenu);
      
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
    
      useEffect(() => {
        const handleKeydown = (e) => {
          // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
          if (
            e.keyCode === 123 || // F12
            (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
            (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
            (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
          ) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('keydown', handleKeydown);
      
        return () => {
          document.removeEventListener('keydown', handleKeydown);
        };
      }, []);
      useEffect(() => {
        const disableInspectMethods = (e) => {
          // Prevent "Inspect Element" via right-click or drag and drop on element
          if (e.type === 'mousedown' && e.button === 2) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('mousedown', disableInspectMethods);
      
        return () => {
          document.removeEventListener('mousedown', disableInspectMethods);
        };
      }, []);
        
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const CodeBlock = ({ code }) => (
        <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
            <code>{code}</code>
        </pre>
    );


    const goToNextSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const nextIndex = (currentIndex + 1) % sections.length; // Loop back to start
        setActiveSection(sections[nextIndex].id);
    };

    const goToPreviousSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const previousIndex = (currentIndex - 1 + sections.length) % sections.length; // Loop back to end
        setActiveSection(sections[previousIndex].id);
    };


    const sections = [
        { id: 'overview', title: 'Project Overview' },
        { id: 'data', title: 'Data Sources' },
        { id: 'methodology', title: 'Methodology' },
        { id: 'results', title: 'Results and Impact' },
        { id: 'code', title: 'Sample Code' },
        { id: 'references', title: 'References' },
    ];

    const sampleCode = `from Bio import SeqIO
from Bio.Align import PairwiseAligner
import matplotlib.pyplot as plt
import seaborn as sns
import pandas as pd

# Load sequences from FASTA files
seq1 = SeqIO.read('sequence1.fasta', 'fasta').seq
seq2 = SeqIO.read('sequence2.fasta', 'fasta').seq

# Initialize pairwise aligner
aligner = PairwiseAligner()
alignment = aligner.align(seq1, seq2)

# Identify SNP positions
snp_positions = [i for i, (a, b) in enumerate(zip(alignment[0], alignment[1])) if a != b]

# Plot SNP density along the sequence
plt.figure(figsize=(12, 8))
sns.histplot(snp_positions, bins=50, kde=False, color='blue')
plt.title('SNP Density in Each Sequence')
plt.xlabel('Position')
plt.ylabel('Frequency')
plt.grid(True)
plt.show()

# Calculate frequencies of SNP types
snp_changes = [f'{a}->{b}' for a, b in zip(alignment[0], alignment[1]) if a != b]
snp_frequencies = pd.Series(snp_changes).value_counts()
print('SNP Frequencies:\n', snp_frequencies)
`;

    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        {
            src: "https://i.imghippo.com/files/Y2x0I1726062375.png",
            description: "This image is a simple phylogenetic tree diagram displaying the evolutionary relationship between two genetic sequences. The y-axis lists the sequences by their accession numbers, while the x-axis represents the branch lengths indicating genetic distance between the sequences. The internal node labeled 'Inner' suggests a common ancestor or relationship between the two sequences. The branch lengths (horizontal lines) show the relative evolutionary distance between the sequences from this common point."
        },
        {
            src: "https://i.imghippo.com/files/Oii0d1726062453.png",
            description: "This histogram displays the distribution of allele frequencies in a given dataset. The x-axis represents the allele frequency, while the y-axis shows the count of occurrences for each frequency range. The data suggests two distinct frequency ranges, approximately between 0.23-0.24 and 0.26-0.27, both occurring with similar frequency counts of 2 each."
        },
        // {
        //     src: "https://i.postimg.cc/NMZwFQPW/image.png",
        //     description: "The plot shows SNP Density(Single Nucleotide Polymorphisms) along a sequence, measured at different positions. The x- axis represents the position in the sequence(per 100 base pairs), while the y - axis represents the number of SNPs observed.A steep drop in SNP density occurs around position 200. A red dashed line marks the maximum SNP value of 89 at a specific position, highlighted with the label 'Max: 89' The plot suggests that most SNPs are clustered in the early part of the sequence, with a sharp decline in SNP presence after position 200."
        // }
    ];

    const slideLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const slideRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="bg-gradient-to-b from-purple-50 to-white min-h-screen font-sans">
            <AnimatedBackground />
            <Header />
            <main className="max-w-6xl mx-auto px-4 py-12">
                <motion.h1
                    className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-8 text-center"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    Comparative Genomic Analysis Identifying and Characterizing (SNPs) in DNA Sequences
                </motion.h1>

                <nav className="mb-6 sm:mb-12">
                    <ul className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
                        {sections.map((section) => (
                            <motion.li
                                key={section.id}
                                className="mb-2 sm:mb-0"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <button
                                    className={`px-3 sm:px-4 py-2 rounded-full text-sm sm:text-base ${activeSection === section.id
                                        ? 'bg-purple-600 text-white'
                                        : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                                        } transition duration-300`}
                                    onClick={() => setActiveSection(section.id)}
                                >
                                    {section.title}
                                </button>
                            </motion.li>
                        ))}
                    </ul>
                </nav>


                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeSection}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        {activeSection === 'overview' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Project Overview</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Genomic research often revolves around understanding genetic variations, with single nucleotide polymorphisms (SNPs) being one of the most common and significant types. SNPs represent changes in a single nucleotide base in the DNA sequence and can have profound implications for genetic diversity, disease susceptibility, and evolutionary studies. This project focuses on identifying and characterizing SNPs between two DNA sequences, offering insights into the genetic variations that distinguish them.</motion.p>
                                {/* <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    Proteins, the intricate molecular machines that power all living organisms, derive their functions from their complex three-dimensional structures. For decades, unraveling these structures has been a monumental challenge in biology. Our project aims to overcome this hurdle by leveraging the latest advancements in machine learning and computational biology to predict protein structures with unprecedented accuracy and speed.
                                </motion.p> */}

                                <section className="mb-12">
                                    <h2 className="text-4xl font-bold text-purple-800 mb-4">Objectives</h2>
                                    <motion.p
                                        className="text-xl font-bold text-gray-700 leading-relaxed mb-6"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2 }}
                                    >
                                        The primary objectives of this study are:
                                    </motion.p>
                                    <motion.ol
                                        className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Detecting SNPs between two DNA sequences.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Analyzing the types and frequencies of these SNPs to understand genetic variation.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Visualizing the distribution of SNPs to identify patterns.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Calculating various SNP metrics, such as density and transition/transversion ratios, for comprehensive analysis.
                                        </li>
                                        <li>
                                            <span className="font-semibold"></span> Exploring how SNP data can enhance our understanding of genetic diversity, disease susceptibility, and evolutionary relationships.
                                        </li>
                                    </motion.ol>

                                    <motion.div
                                        className="bg-gray-100 p-6 rounded-lg mb-8"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.8 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-800 mb-4">Significance</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                            The project encompasses several stages:
                                        </p>
                                        <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Sequence Reading:</strong> Using Biopython to read DNA sequences from FASTA files.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Sequence Alignment:</strong> Aligning the two DNA sequences to ensure accurate comparison.
                                            </li>
                                            <li className="mb-2">
                                                <strong>SNP Identification:</strong> Detecting nucleotide differences to identify SNPs.
                                            </li>
                                            <li className="mb-2">
                                                <strong>SNP Analysis:</strong> Performing various analyses on SNPs, including plotting distributions, calculating frequencies, and evaluating additional metrics.
                                            </li>
                                            <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                                This approach allows for a detailed examination of genetic variation, which is crucial for various biological and medical research applications.
                                            </p>
                                        </ul>
                                    </motion.div>
                                </section>

                                <motion.div
                                    className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                    whileHover={{ scale: 1.02 }}
                                >
                                    <img
                                        src="https://static.wixstatic.com/media/15b941_47df96ee398a416f9dbadfcae7369219~mv2.png"
                                        alt="The Importance of Genomic Data in Precision Medicine"
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </motion.div>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    The image illustrates a single nucleotide polymorphism (SNP), a variation in a single nucleotide that occurs in less than 1% of the population. In the DNA sequence, the general population (top) has the nucleotide "G" at a specific position, while the SNP variant (bottom) shows a substitution of "G" with "T." SNPs are a common form of genetic variation and can be associated with various traits or disease risks. They are often used in genomic studies to understand human diversity and potential health implications. This small change in the DNA sequence may have significant biological effects depending on its location.
                                </motion.p>

                                {/* <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    Our project not only pushes the boundaries of what's possible in computational biology but also democratizes access to these powerful tools. By making our algorithms and models openly available, we aim to accelerate research across the globe, fostering collaborations and driving innovations in fields ranging from medicine to sustainable agriculture.
                                </motion.p> */}
                            </section>
                        )}

                        {activeSection === 'data' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Data Sources</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The DNA sequences used in this project are sourced from FASTA files, a common format for storing sequence data. Each FASTA file includes a header line with a sequence identifier followed by the nucleotide sequence itself. This format is widely used due to its simplicity and compatibility with bioinformatics tools.                                </motion.p>
                                <motion.ol
                                    className="list-decimal list-inside bg-purple-100 p-6 rounded-lg text-xl text-gray-700 leading-relaxed mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <li className="mb-6">
                                        <span className="font-semibold text-2xl">Biopython:</span>
                                        <p className="text-lg bg-purple-100 p-6 rounded-lg mb-0 text-gray-700 leading-relaxed">
                                            Biopython is instrumental in this project for managing biological data:
                                        </p>
                                        <ul className="list-disc list-inside pl-6">
                                            <li className="mb-4">
                                                <span className="font-semibold text-xl">SeqIO Module:</span> Facilitates the reading and parsing of FASTA files, making it easy to import sequence data into Python for further analysis.
                                            </li>
                                            <li className="mb-4">
                                                <span className="font-semibold text-xl">PairwiseAligner:</span> Enables pairwise alignment of the DNA sequences, which is essential for comparing corresponding nucleotides and identifying SNPs.
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="mb-6">
                                        <span className="font-semibold text-2xl">Sequence Sources:</span>
                                        <p className="text-lg text-gray-700 bg-purple-100 p-6 rounded-lg mb-0 leading-relaxed">
                                            The specific DNA sequences analyzed in this project are derived from human chromosomes:
                                        </p>
                                        <ul className="list-disc list-inside pl-8 mb-6">
                                            <li className="mb-6">
                                                <span className="font-semibold text-xl">Sequence 1: Homo sapiens chromosome 3</span>
                                                <ul className="list-disc list-inside pl-10 mt-2">
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">LOCUS:</span> NC_000003
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Length:</span> 57,381 base pairs
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Definition:</span> Homo sapiens chromosome 3, GRCh38.p14 Primary Assembly
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">ACCESSION:</span> NC_000003 REGION: 36,993,466..37,050,846
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">VERSION:</span> NC_000017.11
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Source:</span> Homo sapiens (human)
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">ORGANISM:</span> Homo sapiens; Eukaryota; Metazoa; Chordata; Craniata; Vertebrata; Euteleostomi; Mammalia; Eutheria; Euarchontoglires; Primates; Haplorrhini; Catarrhini; Hominidae; Homo.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                        <ul className="list-disc list-inside pl-8">
                                            <li className="mb-6">
                                                <span className="font-semibold text-xl">Sequence 2: Homo sapiens chromosome 17</span>
                                                <ul className="list-disc list-inside pl-10 mt-2">
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">LOCUS:</span> NC_000017
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Length:</span> 19,070 base pairs
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Definition:</span> Homo sapiens chromosome 17, GRCh38.p14 Primary Assembly
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">ACCESSION:</span> NC_000017 REGION: 7,668,421..7,687,490
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">VERSION:</span> NC_000017.11
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">Source:</span> Homo sapiens (human)
                                                    </li>
                                                    <li className="mb-3">
                                                        <span className="font-semibold text-lg">ORGANISM:</span> Homo sapiens; Eukaryota; Metazoa; Chordata; Craniata; Vertebrata; Euteleostomi; Mammalia; Eutheria; Euarchontoglires; Primates; Haplorrhini; Catarrhini; Hominidae; Homo.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                        <motion.div
                                            className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                            initial={{ scale: 0.8, opacity: 0 }}
                                            animate={{ scale: 1, opacity: 1 }}
                                            transition={{ duration: 0.6 }}
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <img
                                                src="https://i.imghippo.com/files/EIj171726062199.png"
                                                alt="The Importance of Genomic Data in Precision Medicine"
                                                className="w-full h-auto object-cover rounded-lg"
                                            />
                                        </motion.div>
                                        <motion.p
                                            className="text-xl text-gray-700 leading-relaxed mb-6"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.6 }}
                                        >
                                            This image is a genomic visualization of a region on Homo sapiens chromosome 3, displaying various annotation tracks. The top section shows gene structures (MLH1 and RPL29P11) with exons and introns, based on NCBI RefSeq annotations. Below that, there are tracks showing SNP (dbSNP b156 v2) and ClinVar variants. Further down, RNA-seq data shows exon coverage and intron-spanning reads, indicating expression levels across different samples, scaled logarithmically. The bottom track depicts intron feature annotations with various intron lengths.
                                        </motion.p>
                                    </li>
                                </motion.ol>

                                {/* <motion.div
                                    className="bg-purple-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Data Visualization</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The dataset was visualized using histograms, box plots, and correlation matrices to identify trends and relationships between variables. These visualizations help in understanding the distribution of hemoglobin levels and the regional variations in anemia prevalence. Below are examples of some of the visualizations used in this project:
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">Histogram of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A histogram illustrating the distribution of hemoglobin levels among pregnant and non-pregnant women, highlighting the diagnostic challenges posed by overlapping ranges.
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">Box Plot of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A box plot comparing hemoglobin levels between anemic and non-anemic groups, showing the median values and interquartile ranges.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Correlation Heatmap:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A heatmap visualizing the correlation between anemia prevalence in different regions, segmented by pregnancy status.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </motion.div> */}

                            </section>
                        )}

                        {activeSection === 'methodology' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Methodology</h2>

                                <h3 className="text-2xl font-bold text-purple-700 mb-3">Reading Sequences</h3>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Biopython’s 'SeqIO' module is used to load DNA sequences from FASTA files into Python. This step involves importing the sequences and ensuring they are in the correct format for subsequent alignment.
                                </motion.p>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Aligning Sequences</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            The 'PairwiseAligner' is employed to align the DNA sequences, which aligns nucleotides across their entire length. This alignment is crucial for accurately comparing sequences and detecting SNPs.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Identifying SNPs</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            SNPs are identified by comparing nucleotides at each position in the aligned sequences. Differences between aligned nucleotides are noted, and the positions of these differences are recorded as SNPs.
                                        </p>
                                    </motion.div>
                                </div>

                                <section className="p-4 sm:p-8 bg-white text-gray-800 relative overflow-hidden">
                                    <h2 className="text-2xl sm:text-4xl font-bold text-purple-700 mb-4 sm:mb-6 text-center">
                                        Visualizations
                                    </h2>
                                    
                                    <div className="relative w-full max-w-5xl mx-auto flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4">
                                        {/* Left Arrow Button */}
                                        <motion.button
                                        onClick={slideLeft}
                                        whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
                                        animate={{ x: [0, -8, 8, -8, 0] }}
                                        transition={{ duration: 0.05, ease: "easeInOut" }}
                                        className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
                                        >
                                        &#8592;
                                        </motion.button>

                                        {/* Image and Description */}
                                        <div className="w-full relative overflow-hidden rounded-lg shadow-lg flex justify-center items-center bg-white">
                                        <AnimatePresence mode="wait" initial={false}>
                                            <motion.div
                                            key={currentIndex}
                                            className="w-full flex flex-col items-center"
                                            initial={{ opacity: 0, x: -100 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            exit={{ opacity: 0, x: 100 }}
                                            transition={{ duration: 0.5, ease: 'easeInOut' }}
                                            >
                                            <motion.img
                                                src={images[currentIndex].src}
                                                alt={`Visualization ${currentIndex + 1}`}
                                                className="w-full sm:w-3/4 h-auto object-contain"
                                                initial={{ scale: 0.9 }}
                                                animate={{ scale: 1 }}
                                                transition={{ duration: 0.3 }}
                                            />
                                            <motion.div
                                                className="w-full p-4 sm:p-7 bg-white text-black rounded-b-lg shadow-lg"
                                                initial={{ y: 50, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                transition={{ delay: 0.2, duration: 0.5 }}
                                            >
                                                <p className="text-base sm:text-lg leading-relaxed">
                                                {images[currentIndex].description}
                                                </p>
                                            </motion.div>
                                            </motion.div>
                                        </AnimatePresence>
                                        </div>

                                        {/* Right Arrow Button */}
                                        <motion.button
                                        onClick={slideRight}
                                        whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
                                        animate={{ x: [0, 8, -8, 8, 0] }}
                                        transition={{ duration: 0.05, ease: "easeInOut" }}
                                        className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
                                        >
                                        &#8594;
                                        </motion.button>
                                    </div>
                                    </section>


                                <motion.div
                                    className="bg-gray-100 p-6 rounded-lg mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Analyzing SNPs</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        Various analyses are performed on the identified SNPs:
                                    </p>
                                    <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                        <li className="mb-2">
                                            <strong>SNP Distribution:</strong> The distribution of SNPs along the sequence is plotted to identify patterns and regions with high or low SNP occurrence.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Frequency Calculation:</strong> The frequency of different SNP types (e.g., A->G, C->T) is calculated to understand the most common variations.
                                        </li>
                                        <li>
                                            <strong>Planned Analyses:</strong> Further analyses, such as calculating SNP density, transition/transversion ratios, conservation scores, and identifying sequence motifs around SNPs, are planned to provide a deeper understanding of the SNPs.
                                        </li>
                                    </ul>
                                </motion.div>
                            </section>

                        )}

                        {activeSection === 'results' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Results and Impact</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The project successfully identifies SNPs between the two DNA sequences, providing detailed information on genetic variations. This data is essential for understanding how genetic differences may influence biological processes and disease susceptibility.
                                </motion.p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">SNP Analysis and Interpretation</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                           The analysis of SNPs revealed distinct patterns of genetic variation between the two sequences. These SNPs were categorized based on the type of nucleotide substitution (e.g., transitions vs. transversions). Understanding these patterns provides insights into the underlying mechanisms of mutation and selection. For example, a higher frequency of transitions (e.g., A->G) over transversions (e.g., A->T) is typically observed due to the biochemical stability of transition mutations. This analysis is crucial for interpreting the evolutionary pressures and mutational processes affecting these regions of the genome.</motion.p>
                                    </motion.div>
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Comparative Genomics Insights</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                            The project also contributes to comparative genomics by identifying regions of high and low SNP density. Regions with high SNP density may indicate areas of the genome that are more prone to mutations, possibly due to their involvement in regulatory functions or because they are less evolutionarily conserved. Conversely, regions with low SNP density might suggest strong evolutionary conservation, indicating essential biological functions. These insights can guide further research into gene function, regulatory elements, and evolutionary biology.</motion.p>
                                    </motion.div>
                                </div>
                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Impact of the Findings</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The findings from this project contribute to a better understanding of genetic variation and its implications. The results have applications in genetic research, evolutionary biology, and medical diagnostics, helping to identify potential genetic markers and understand their roles in various biological and health contexts. Future work could involve extending the analysis to larger datasets and incorporating additional metrics to further explore SNPs and their biological significance.
                                    </p>
                                    {/* <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed">
                                        <li>Accelerated drug discovery processes by providing accurate protein structure models for virtual screening and rational drug design</li>
                                        <li>Enhanced understanding of genetic diseases by elucidating the structural effects of mutations, paving the way for personalized medicine approaches</li>
                                        <li>Facilitated the design of novel enzymes for industrial and environmental applications, contributing to sustainable biotechnology</li>
                                        <li>Improved protein engineering efforts, enabling the creation of proteins with enhanced stability, activity, or novel functions</li>
                                        <li>Advanced our fundamental understanding of protein folding and evolution, contributing to basic research in molecular biology</li>
                                    </ul> */}
                                </motion.div>
                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Visualization of Findings</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The results are presented through several visualizations:
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">SNP Density in Each Sequence:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    This graph illustrates the density of SNPs across the length of each DNA sequence, highlighting regions with higher or lower SNP occurrences.
                                                </li>
                                                <div className="flex flex-wrap justify-center gap-8 mb-12">
                                                    <motion.div
                                                        className="relative w-full sm:w-2/3 md:w-2/4 lg:w-2/5 overflow-hidden rounded-xl shadow-2xl"
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{ duration: 0.6 }}
                                                        whileHover={{ scale: 1.02 }}
                                                    >
                                                        <img
                                                            src="https://i.imghippo.com/files/V7Ty31726062527.png"
                                                            alt="The Importance of Genomic Data in Precision Medicine"
                                                            className="w-full h-auto object-cover rounded-lg"
                                                        />
                                                    </motion.div>
                                                    <motion.div
                                                        className="relative w-full sm:w-2/3 md:w-2/4 lg:w-2/5 overflow-hidden rounded-xl shadow-2xl"
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{ duration: 0.6 }}
                                                        whileHover={{ scale: 1.02 }}
                                                    >
                                                        <img
                                                            src="https://i.imghippo.com/files/aiUBV1726062568.png"
                                                            alt="The Importance of Genomic Data in Precision Medicine"
                                                            className="w-full h-auto object-cover rounded-lg"
                                                        />
                                                    </motion.div>
                                                </div>

                                            </ul>
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">SNP Frequency Distribution:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>This plot shows the distribution of different SNP types (e.g., A->G, C->T), offering insights into which variations are most common. </li>
                                                <motion.div
                                                    className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                                    initial={{ scale: 0.8, opacity: 0 }}
                                                    animate={{ scale: 1, opacity: 1 }}
                                                    transition={{ duration: 0.6 }}
                                                    whileHover={{ scale: 1.02 }}
                                                >
                                                    <img
                                                        src="https://i.imghippo.com/files/UAcJc1726062585.png"
                                                        alt="The Importance of Genomic Data in Precision Medicine"
                                                        className="w-full h-auto object-cover rounded-lg"
                                                    />
                                                </motion.div>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Distribution of SNPs in Aligned Sequences:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>This visualization demonstrates the distribution of SNPs along the aligned sequences, providing a clear view of where variations occur.</li>
                                            </ul>
                                            <motion.div
                                                className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                                initial={{ scale: 0.8, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{ duration: 0.6 }}
                                                whileHover={{ scale: 1.02 }}
                                            >
                                                <img
                                                    src="https://i.imghippo.com/files/1Quh91726062609.png"
                                                    alt="The Importance of Genomic Data in Precision Medicine"
                                                    className="w-full h-auto object-cover rounded-lg"
                                                />
                                            </motion.div>
                                        </li>
                                    </ol>
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'code' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Sample Code</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Below is a Python code snippet used in this project to read DNA sequences, align them, and identify SNPs. This code showcases the core functionalities employed in the analysis:                                </motion.p>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <CodeBlock code={sampleCode} />
                                </motion.div>
                                <motion.div
                                    className="mt-8 flex flex-wrap gap-4"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <a
                                        href="https://github.com/YasmineElnasharty/Comparative_Genomic_Analysis_Identifying_and_Characterizing_-SNPs-_in_DNA_Sequences.git"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
                                    >
                                        <FaGithub className="mr-2" /> View on GitHub
                                    </a>
                                    {/* <a
                                        href="https://colab.research.google.com/drive/your-notebook-id" // Replace with actual Colab URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-400 transition duration-300"
                                    >
                                        <SiGooglecolab className="mr-2" /> Open in Colab
                                    </a>
                                    <a
                                        href="https://jupyter.org/try" // Or replace with your own Jupyter URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-400 transition duration-300"
                                    >
                                        <SiJupyter className="mr-2" /> Try in Jupyter
                                    </a> */}
                                </motion.div>
                                <motion.div
                                    className="mt-8 bg-blue-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-blue-800 mb-4">Code Explanation</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The code begins by importing necessary libraries such as SeqIO for reading sequences, PairwiseAligner for aligning sequences, and plotting libraries for visualization. It then loads the DNA sequences from FASTA files, performs pairwise alignment, and identifies SNPs by comparing nucleotide positions. The code includes plotting SNP density and calculating the frequencies of different SNP types, providing a comprehensive view of the SNP data.</p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        {/* <li className="mb-4">
                                            <span className="font-semibold">Data Loading and Cleaning::</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    We begin by loading two datasets: one for pregnant individuals and one for non-pregnant individuals.
                                                </li>
                                                <li>
                                                    The 'extract_numeric' function is used to parse and clean the hemoglobin level data from text fields.
                                                </li>
                                                <li>
                                                    We then remove any rows with missing hemoglobin values and add a 'Group' column to differentiate between pregnant and non-pregnant individuals.
                                                </li>
                                            </ul>
                                        </li> */}
                                        {/* <li className="mb-4">
                                            <span className="font-semibold">Data Combination and Visualization:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The cleaned datasets are combined into a single DataFrame.
                                                </li>
                                                <li>
                                                    A histogram is created to visualize the distribution of hemoglobin levels for both groups, with different colors representing each group.
                                                </li>
                                            </ul>
                                        </li> */}
                                        {/* <li>
                                            <span className="font-semibold">Linear Regression Analysis:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The 'Group' column is encoded into binary values (1 for Pregnant, 0 for Non-Pregnant).
                                                </li>
                                                <li>
                                                    We use this encoded column to predict hemoglobin levels with a linear regression model.
                                                </li>
                                                <li>
                                                    The model is trained and evaluated, and the results are visualized by plotting actual vs. predicted hemoglobin levels.
                                                </li>
                                            </ul>
                                        </li> */}
                                        {/* <li>
                                            <span className="font-semibold">Model Metrics:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The code prints the coefficient and intercept of the linear regression model, providing insight into the relationship between group status and hemoglobin levels.
                                                </li>
                                            </ul>
                                        </li> */}
                                    </ol>
                                    {/* <p className="text-lg text-gray-700 leading-relaxed mt-4">
                                        This example demonstrates the integration of data cleaning, statistical modeling, and visualization to provide a detailed analysis of hemoglobin levels across different groups.
                                    </p> */}
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'references' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">References</h2>
                                <ul className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8 space-y-4">
                                    <li>
                                        <a
                                            href="https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000001405.40/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            National Center for Biotechnology Information (NCBI). (2024). Homo sapiens chromosome 17, GRCh38.p14 Primary Assembly. </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000001405.40/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            National Center for Biotechnology Information (NCBI). (2024). Homo sapiens chromosome 3, GRCh38.p14 Primary Assembly.  </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/11125122/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Sherry, S. T., Ward, M. H., Kholodov, M., Baker, J., Phan, L., Smigielski, E. M., & Sirotkin, K. (2001). dbSNP: the NCBI database of genetic variation. Nucleic Acids Research, 29(1), 308-311. doi:10.1093/nar/29.1.308. </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/15485342/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Miller, W., Makova, K. D., Nekrutenko, A., & Hardison, R. C. (2004). Comparative genomics. Annual Review of Genomics and Human Genetics, 5, 15-56.</a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/7537709/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Axemo P, Liljestrand J, Bergstrom S, Gebre-Medhin M. Aetiology of late fetal death in Maputo Gynecol Obstet Invest. 1995;39:103–9                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/11160595/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Brabin BJ, Premji Z, Verhoeff F. An analysis of anaemia and child mortality J Nutr. 2001;132:636S–48S                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.who.int/data/gho/data/indicators/indicator-details/GHO/prevalence-of-anaemia-in-pregnant-women-(-)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            World Health Organization. (n.d.-b). Prevalence of anaemia in pregnant women (aged 15-49) (%). World Health Organization                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.who.int/data/gho/data/indicators/indicator-details/GHO/anaemia-in-non-pregnant-women-prevalence-(-)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            World Health Organization. (n.d.-b). Prevalence of anaemia in non-pregnant women (aged 15-49) (%). World Health Organization.                                         </a>
                                    </li> */}
                                </ul>
                            </section>
                        )}
                        <div className="flex justify-between mt-6">
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToPreviousSection}
                            >
                                Previous
                            </motion.button>
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToNextSection}
                            >
                                Next
                            </motion.button>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </main>
            <ScrollToTopButton />

            <Footer />
        </div>
    );
};

export default Project2;
