import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter, FaDownload } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import { AnimatePresence } from 'framer-motion';
import { SiJupyter, SiGooglecolab } from 'react-icons/si';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from '../NewsletterSignup'; 

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const linkStyle = {
        color: '#1F2937',
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '0.15em',
        display: 'inline-block',
        padding: '15px 20px',
        position: 'relative',
        fontWeight: '700',
    };

    const hoverEffectStyle = {
        content: '""',
        background: '#D946EF',
        bottom: 0,
        height: '2px',
        left: '50%',
        position: 'absolute',
        transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        width: 0,
    };

    const liStyle = {
        display: 'table-cell',
        position: 'relative',
        padding: '15px 0',
    };

    const ulStyle = {
        margin: '0 auto',
        padding: 0,
        listStyle: 'none',
        display: 'table',
        width: '800px',
        textAlign: 'center',
    };

    const mobileMenuStyle = {
        position: 'absolute',
        top: '60px',
        left: '0',
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 100,
    };

    const mobileLinkStyle = {
        ...linkStyle,
        padding: '10px 20px',
    };

    return (
        <>
            {/* Desktop View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="desktop-menu"
            >
                <ul style={ulStyle}>
                    {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                        <motion.li
                            key={text}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            style={liStyle}
                        >
                            <motion.a
                                href={`/${text.toLowerCase()}`}
                                style={linkStyle}
                                whileHover={{ scale: 1.1 }}
                                onMouseEnter={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '100%';
                                    hoverSpan.style.left = '0';
                                }}
                                onMouseLeave={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '0';
                                    hoverSpan.style.left = '50%';
                                }}
                            >
                                {text}
                                <span style={hoverEffectStyle}></span>
                            </motion.a>
                        </motion.li>
                    ))}
                </ul>
            </motion.nav>

            {/* Mobile View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="mobile-menu"
            >
                <div
                    style={{ padding: '15px 20px', cursor: 'pointer' }}
                    onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                >
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                </div>
                {isMobileMenuOpen && (
                    <motion.ul
                        style={mobileMenuStyle}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                            <motion.li
                                key={text}
                                style={{ padding: '10px 0' }}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                                    {text}
                                </a>
                            </motion.li>
                        ))}
                    </motion.ul>
                )}
            </motion.nav>

            <style>
                {`
                    @media (min-width: 768px) {
                        .mobile-menu {
                            display: none;
                        }
                    }
                    @media (max-width: 767px) {
                        .desktop-menu {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
};
const SVGDoubleHelix = () => {
    return (
        <svg
            viewBox="0 0 800 600"
            className="w-full h-full opacity-20"
            style={{
                position: 'absolute',
                top: '130%',// Adjust this value to move the helix lower
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <DNAStrand color="url(#grad1)" offset={0} />
            <DNAStrand color="url(#grad1)" offset={Math.PI} />
        </svg>
    );
};
const DNAStrand = ({ color, offset }) => {
    const points = 20;
    const ySpread = 80;
    const xSpread = 800;

    return (
        <>
            {[...Array(points)].map((_, i) => {
                const x = (i / (points - 1)) * xSpread;
                const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
                return (
                    <g key={i}>
                        <motion.circle
                            cx={x}
                            cy={y}
                            r={3}
                            fill={color}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                delay: i * 0.2,
                            }}
                        />
                        {i < points - 1 && (
                            <motion.line
                                x1={x}
                                y1={y}
                                x2={(i + 1) / (points - 1) * xSpread}
                                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                                stroke={color}
                                strokeWidth={1}
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: [0, 1, 0] }}
                                transition={{
                                    duration: 3,
                                    repeat: Infinity,
                                    delay: i * 0.2,
                                }}
                            />
                        )}
                    </g>
                );
            })}
        </>
    );
};
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [email, setEmail] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
  
    // Social media details
    const socialIcons = [
      { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
      { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
      { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
    ];
  
  
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (email) {
        emailjs.send(
          'service_f0vv34a',  // Your service ID
          'template_x9vg3re', // Your template ID
          {
            to_email: email,  // Dynamically set the recipient's email
            message: 'Thank you for subscribing to our newsletter!'
          },
          'oM0GV2_SQASnm0uWu'    // Your public key
        )
        .then((response) => {
          console.log('Email sent successfully:', response);
          setShowThankYou(true);
          setEmail('');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('There was an error subscribing. Please try again later.');
        });
      }
    };
  
    useEffect(() => {
      if (showThankYou) {
        const timer = setTimeout(() => setShowThankYou(false), 5000);
        return () => clearTimeout(timer);
      }
    }, [showThankYou]);
  
    const ThankYouAnimation = () => (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.2}
        />
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          className="text-9xl mb-8"
        >
          🎉
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-center"
        >
          <motion.h2
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
            className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
          >
            Thank You!
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-3xl text-purple-200 drop-shadow-md"
          >
            You've subscribed to my newsletter!
          </motion.p>
        </motion.div>
      </motion.div>
    );
  
    return (
      <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Logo and tagline */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="col-span-1 md:col-span-2 lg:col-span-1"
            >
              <div className="flex items-center mb-4">
                {/* Logo */}
                <img
                  src="/Y.png"
                  alt="Logo"
                  className="text-4xl text-purple-400 mr-3"
                  style={{ height: '160px' }}  // Larger size for the logo
                />
              </div>
              <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
              >
                <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
              </motion.button>
            </motion.div>
  
            {/* Quick links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
              <ul className="space-y-2">
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                    About Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                    Projects
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                    Contact Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                    Blog
                  </a>
                </motion.li>
              </ul>
            </motion.div>
  
            {/* Latest Blog */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
              >
                <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
                <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
                <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
              </motion.div>
            </motion.div>
  
            <NewsletterSignup />
          </div>
  
          {/* Social Icons */}
          <motion.div
            className="flex justify-center space-x-6 mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            {socialIcons.map(({ Icon, color, name, url }) => (
              <motion.a
                key={name}
                href={url}
                target="_blank" // Opens link in new tab
                rel="noopener noreferrer" // Security for external links
                onMouseEnter={() => setHoveredIcon(name)}
                onMouseLeave={() => setHoveredIcon(null)}
                whileHover={{ y: -5 }}
                className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
              >
                <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
                <AnimatePresence>
                  {hoveredIcon === name && (
                    <motion.span
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                    >
                      {name}
                    </motion.span>
                  )}
                </AnimatePresence>
              </motion.a>
            ))}
          </motion.div>
  
          {/* DNA Animation */}
          <SVGDoubleHelix />
  
          {/* Floating Particles */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-purple-500 rounded-full opacity-20"
                style={{
                  width: Math.random() * 10 + 5,
                  height: Math.random() * 10 + 5,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.2, 0.5, 0.2],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              />
            ))}
          </div>
  
          {/* Copyright */}
          <motion.div
            className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <p>&copy; {currentYear} Yasmine. All rights reserved. |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Privacy Policy Coming Soon!")}
              >
                Privacy Policy
              </button> |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Terms of Service Coming Soon!")}
              >
                Terms of Service
              </button>
            </p>
          </motion.div>
        </div>
        <AnimatePresence>
          {showThankYou && <ThankYouAnimation />}
        </AnimatePresence>
      </footer>
    );
  };

  const ScrollToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);
  
    useEffect(() => {
      const checkScrollTop = () => {
        // Show the scroll icon if user scrolled down 300 pixels or more
        if (!showScroll && window.scrollY > 300) {
          setShowScroll(true);
        } else if (showScroll && window.scrollY <= 300) {
          setShowScroll(false);
        }
      };
  
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);
  
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <div
        className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
        onClick={scrollTop}
      >
        <FaChevronUp size={24} />
      </div>
    );
  };
const AnimatedBackground = () => {
    const [particles, setParticles] = useState([]);

    useEffect(() => {
        const generateParticles = () => {
            const newParticles = [];
            for (let i = 0; i < 50; i++) {
                newParticles.push({
                    x: Math.random() * window.innerWidth,
                    y: Math.random() * window.innerHeight,
                    size: Math.random() * 4 + 1,
                    speedX: (Math.random() - 0.5) * 0.5,
                    speedY: (Math.random() - 0.5) * 0.5,
                });
            }
            setParticles(newParticles);
        };

        generateParticles();

        const animateParticles = () => {
            setParticles((prevParticles) =>
                prevParticles.map((particle) => ({
                    ...particle,
                    x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
                    y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
                }))
            );
        };

        const intervalId = setInterval(animateParticles, 50);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="fixed inset-0 pointer-events-none">
            {particles.map((particle, index) => (
                <motion.div
                    key={index}
                    className="absolute rounded-full bg-purple-200"
                    style={{
                        width: particle.size,
                        height: particle.size,
                        x: particle.x,
                        y: particle.y,
                    }}
                    animate={{
                        x: particle.x,
                        y: particle.y,
                    }}
                    transition={{
                        duration: 0.05,
                        ease: "linear",
                    }}
                />
            ))}
        </div>
    );
}

const Project1 = () => {
    const [activeSection, setActiveSection] = useState('overview');
    useEffect(() => {
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
      
        document.addEventListener('contextmenu', handleContextMenu);
      
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
    
      useEffect(() => {
        const handleKeydown = (e) => {
          // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
          if (
            e.keyCode === 123 || // F12
            (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
            (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
            (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
          ) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('keydown', handleKeydown);
      
        return () => {
          document.removeEventListener('keydown', handleKeydown);
        };
      }, []);
      useEffect(() => {
        const disableInspectMethods = (e) => {
          // Prevent "Inspect Element" via right-click or drag and drop on element
          if (e.type === 'mousedown' && e.button === 2) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('mousedown', disableInspectMethods);
      
        return () => {
          document.removeEventListener('mousedown', disableInspectMethods);
        };
      }, []);
        
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const CodeBlock = ({ code }) => (
        <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
            <code>{code}</code>
        </pre>
    );

    const goToNextSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const nextIndex = (currentIndex + 1) % sections.length; // Loop back to start
        setActiveSection(sections[nextIndex].id);
    };

    const goToPreviousSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const previousIndex = (currentIndex - 1 + sections.length) % sections.length; // Loop back to end
        setActiveSection(sections[previousIndex].id);
    };

    const sections = [
        { id: 'overview', title: 'Project Overview' },
        { id: 'data', title: 'Data Sources' },
        { id: 'methodology', title: 'Methodology' },
        { id: 'results', title: 'Results and Impact' },
        { id: 'code', title: 'Sample Code' },
        { id: 'references', title: 'References' },
    ];

    const sampleCode = `import pandas as pd
import matplotlib.pyplot as plt
import seaborn as sns
from sklearn.linear_model import LinearRegression
from sklearn.metrics import confusion_matrix, classification_report

# Load the datasets
pregnant_df = pd.read_csv('Pregnant_data.csv')  # Replace with actual file path
non_pregnant_df = pd.read_csv('Non_Pregnant_data.csv')  # Replace with actual file path

# Data Cleaning: Extract numeric values for hemoglobin levels
def extract_numeric(value):
    try:
        return float(value.split()[0])
    except (ValueError, AttributeError, IndexError):
        return None

pregnant_df['hemoglobin_level'] = pregnant_df['First Tooltip'].apply(extract_numeric)
non_pregnant_df['hemoglobin_level'] = non_pregnant_df['First Tooltip'].apply(extract_numeric)

# Drop rows with missing values
pregnant_cleaned = pregnant_df.dropna(subset=['hemoglobin_level'])
non_pregnant_cleaned = non_pregnant_df.dropna(subset=['hemoglobin_level'])

# Add a 'Group' column for Pregnant and Non-Pregnant distinction
pregnant_cleaned['Group'] = 'Pregnant'
non_pregnant_cleaned['Group'] = 'Non-Pregnant'

# Combine the two datasets
combined_data = pd.concat([pregnant_cleaned[['hemoglobin_level', 'Group']], 
                           non_pregnant_cleaned[['hemoglobin_level', 'Group']]], ignore_index=True)

# Visualization: Histogram of Hemoglobin Levels
plt.figure(figsize=(10, 6))
sns.histplot(combined_data, x='hemoglobin_level', hue='Group', kde=True, bins=30, palette=['blue', 'red'], alpha=0.6)
plt.title('Distribution of Hemoglobin Levels (Pregnant vs Non-Pregnant)')
plt.xlabel('Hemoglobin Level (g/dL)')
plt.ylabel('Frequency')
plt.grid(True)
plt.tight_layout()
plt.show()

# Linear Regression: Predict Hemoglobin Levels
# Encode 'Group' column (1 for Pregnant, 0 for Non-Pregnant)
combined_data['Group_encoded'] = combined_data['Group'].apply(lambda x: 1 if x == 'Pregnant' else 0)

# Define features and target variable
X = combined_data[['Group_encoded']]
y = combined_data['hemoglobin_level']

# Split the data into training and testing sets
from sklearn.model_selection import train_test_split
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2, random_state=42)

# Create and train the linear regression model
model = LinearRegression()
model.fit(X_train, y_train)

# Predictions and Evaluation
y_pred = model.predict(X_test)

# Visualization: Actual vs Predicted Hemoglobin Levels
plt.figure(figsize=(10, 6))
plt.scatter(X_test, y_test, color='black', label='Actual Data', alpha=0.7)
plt.plot(X_test, y_pred, color='red', label='Regression Line', linewidth=2)
plt.title('Linear Regression: Hemoglobin Levels by Group')
plt.xlabel('Group (0: Non-Pregnant, 1: Pregnant)')
plt.ylabel('Hemoglobin Level (g/dL)')
plt.legend()
plt.grid(True)
plt.tight_layout()
plt.show()

# Model Metrics
print(f"Coefficient: {model.coef_[0]:.2f}")
print(f"Intercept: {model.intercept_:.2f}")
`;

    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        {
            src: "https://i.imghippo.com/files/lAaic1726061669.png",
            description: "Linear regression plot displaying the relationship between hemoglobin levels (g/dL) and pregnancy status (Group 0: Non-pregnant, Group 1: Pregnant). The black dots represent actual data points, while the red line indicates the fitted regression line. The model shows a slight upward trend in hemoglobin levels for pregnant women."
        },
        {
            src: "https://i.imghippo.com/files/yQeua1726061692.png",
            description: "This box plot compares hemoglobin levels between pregnant and non-pregnant women. The blue box represents pregnant women, while the red box represents non-pregnant women. Anemia thresholds are indicated by dashed lines, with 11.0 g/dL for pregnant and 12.0 g/dL for non-pregnant women."
        },
        {
            src: "https://i.imghippo.com/files/LHeU61726061737.png",
            description: "The heatmap shows anemia prevalence from 2010 to 2019 across regions. Africa and South-East Asia have higher rates, while the Americas and Europe show lower levels. Darker blue indicates higher prevalence."
        }
    ];

    const slideLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const slideRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="bg-gradient-to-b from-purple-50 to-white min-h-screen font-sans">
            <AnimatedBackground />
            <Header />
            <main className="max-w-6xl mx-auto px-4 py-12">
                <motion.h1
                    className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-8 text-center"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                    style={{lineHeight: '1.3' }}

                >
                    Assessing Anemia in Pregnant and Non-Pregnant Women
                </motion.h1>

                <nav className="mb-6 sm:mb-12">
                    <ul className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
                        {sections.map((section) => (
                            <motion.li
                                key={section.id}
                                className="mb-2 sm:mb-0"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <button
                                    className={`px-3 sm:px-4 py-2 rounded-full text-sm sm:text-base ${activeSection === section.id
                                        ? 'bg-purple-600 text-white'
                                        : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                                        } transition duration-300`}
                                    onClick={() => setActiveSection(section.id)}
                                >
                                    {section.title}
                                </button>
                            </motion.li>
                        ))}
                    </ul>
                </nav>


                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeSection}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        {activeSection === 'overview' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Project Overview</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Anemia, particularly among women, remains a critical global health issue with severe implications, especially for those who are pregnant. This condition, marked by insufficient hemoglobin levels, can lead to significant health risks such as maternal mortality, low birth weight, and various complications during pregnancy. The World Health Organization (WHO) estimates that anemia affects a large proportion of women of reproductive age, with the burden being particularly heavy in developing countries. This project undertakes a comprehensive statistical analysis to explore the prevalence of anemia among pregnant and non-pregnant women, identify the key factors contributing to this health disparity, and provide insights for targeted public health interventions.
                                </motion.p>
                                {/* <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    Proteins, the intricate molecular machines that power all living organisms, derive their functions from their complex three-dimensional structures. For decades, unraveling these structures has been a monumental challenge in biology. Our project aims to overcome this hurdle by leveraging the latest advancements in machine learning and computational biology to predict protein structures with unprecedented accuracy and speed.
                                </motion.p> */}

                                <section className="mb-12">
                                    <h2 className="text-4xl font-bold text-purple-800 mb-4">Objectives</h2>
                                    <motion.p
                                        className="text-xl font-bold text-gray-700 leading-relaxed mb-6"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2 }}
                                    >
                                        The primary objectives of this study are to:
                                    </motion.p>
                                    <motion.ol
                                        className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Compare the prevalence of anemia between pregnant and non-pregnant women, utilizing global data from the WHO.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Analyze the distribution of hemoglobin levels and its association with anemia status.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Investigate the diagnostic challenges posed by overlapping hemoglobin levels between anemic and non-anemic individuals.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Employ statistical modeling and visualization to understand regional trends and variations in anemia prevalence.
                                        </li>
                                        <li>
                                            <span className="font-semibold"></span> Offer data-driven insights to guide public health strategies aimed at reducing anemia, particularly in vulnerable populations.
                                        </li>
                                    </motion.ol>

                                    <motion.div
                                        className="bg-purple-100 p-6 rounded-lg"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-800 mb-4">Significance</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                            This project addresses the urgent need to understand the dynamics of anemia among women, with a focus on pregnant women who are at heightened risk. By analyzing nearly two decades of global and regional data, the project aims to uncover trends, highlight diagnostic challenges, and inform public health policies. The insights derived from this study are crucial for developing effective, region-specific interventions that can mitigate the health risks associated with anemia, ultimately contributing to better health outcomes for women worldwide.
                                        </p>
                                        {/* <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                            <li className="mb-2">Data collection from multiple sources, integrating diverse structural information</li>
                                            <li className="mb-2">Rigorous quality filtering to remove low-resolution structures and experimental artifacts</li>
                                            <li className="mb-2">Advanced sequence alignment and clustering to reduce redundancy and bias in our training set</li>
                                            <li className="mb-2">Comprehensive feature extraction, capturing both local and global protein characteristics</li>
                                            <li>Innovative data augmentation techniques to enhance model generalization and robustness</li>
                                        </ol> */}
                                    </motion.div>
                                </section>

                                <motion.div
                                    className="relative w-4/5 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                    whileHover={{ scale: 1.02 }}
                                >
                                    <img
                                        src="https://i.imghippo.com/files/6H08M1726061591.png"
                                        alt="The Importance of Genomic Data in Precision Medicine"
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </motion.div>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    The map displays the global prevalence of anemia among women of reproductive age (WRA), categorized by severity. Countries are shaded according to four levels: severe (&gt;40%, shown in dark purple), moderate (20.0-39.9%, in red), mild (5.0-19.9%, in light pink), and normal (&lt;5.0%, in beige). The data highlights high anemia prevalence across many African countries and parts of South Asia and the Middle East, where severe rates are observed. In contrast, countries in North America, Europe, and Australia show relatively low anemia prevalence. Some regions lack available data.
                                </motion.p>

                                {/* <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    Our project not only pushes the boundaries of what's possible in computational biology but also democratizes access to these powerful tools. By making our algorithms and models openly available, we aim to accelerate research across the globe, fostering collaborations and driving innovations in fields ranging from medicine to sustainable agriculture.
                                </motion.p> */}
                            </section>
                        )}

                        {activeSection === 'data' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Data Sources</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The data used in this project is sourced from multiple reputable organizations, primarily the World Health Organization (WHO). The dataset encompasses global anemia prevalence data among women aged 15 to 49 from 2000 to 2019, with separate statistics for pregnant and non-pregnant women. This comprehensive dataset includes hemoglobin concentration levels, anemia classification criteria, and demographic variables such as age, region, and socioeconomic status.
                                </motion.p>
                                <motion.ol
                                    className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <li className="mb-4">
                                        <span className="font-semibold">World Health Organization (WHO):</span>
                                        <ul className="list-disc list-inside pl-6">
                                            <li className="mb-2">
                                                <span className="font-semibold">Global Anemia Data:</span> Provides detailed prevalence rates of anemia in women across different regions, categorized by pregnancy status.
                                            </li>
                                            <li className="mb-2">
                                                <span className="font-semibold">Hemoglobin Concentration Data:</span> Includes hemoglobin distribution data essential for understanding anemia's clinical thresholds.
                                            </li>
                                            <li>
                                                <span className="font-semibold">Socioeconomic and Demographic Data:</span> Offers additional context by including variables like education level, income, and access to healthcare.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span className="font-semibold">Kaggle:</span>
                                        <ul className="list-disc list-inside pl-6">
                                            <li>
                                                <span className="font-semibold">Supplementary Data:</span> Includes additional datasets that complement the WHO data, offering insights into regional health trends, nutritional status, and iron intake among women.
                                            </li>
                                        </ul>
                                    </li>
                                </motion.ol>

                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Data Visualization</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The dataset was visualized using histograms, box plots, and correlation matrices to identify trends and relationships between variables. These visualizations help in understanding the distribution of hemoglobin levels and the regional variations in anemia prevalence. Below are examples of some of the visualizations used in this project:
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">Histogram of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A histogram illustrating the distribution of hemoglobin levels among pregnant and non-pregnant women, highlighting the diagnostic challenges posed by overlapping ranges.
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">Box Plot of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A box plot comparing hemoglobin levels between anemic and non-anemic groups, showing the median values and interquartile ranges.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Correlation Heatmap:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A heatmap visualizing the correlation between anemia prevalence in different regions, segmented by pregnancy status.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </motion.div>

                            </section>
                        )}

                        {activeSection === 'methodology' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Methodology</h2>

                                <h3 className="text-2xl font-bold text-purple-700 mb-3">Data Preparation</h3>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The first step in the analysis involved data cleaning and organization using Python’s pandas library. This included handling missing values, standardizing data formats, and categorizing the dataset into relevant groups (e.g., pregnant vs. non-pregnant women). The cleaned dataset was then used for exploratory data analysis (EDA) to identify key trends and outliers.
                                </motion.p>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Exploratory Data Analysis (EDA)</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            The EDA phase involved generating descriptive statistics and visualizations to understand the distribution of hemoglobin levels and the prevalence of anemia. This phase also included creating box plots and histograms to compare the hemoglobin concentration between anemic and non-anemic individuals, as well as pregnant and non-pregnant women.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Hypothesis Testing and Validation</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            To validate the findings, we conducted hypothesis testing on the hemoglobin levels between different groups. The tests aimed to determine whether the observed differences in hemoglobin distributions between anemic and non-anemic women were statistically significant. This step was crucial in confirming the robustness of our results.
                                        </p>
                                    </motion.div>
                                </div>

                                <section className="p-4 md:p-8 bg-white text-gray-800 relative overflow-hidden">
  <h2 className="text-2xl md:text-4xl font-bold text-purple-700 mb-6 text-center">Visualizations</h2>
  <div className="relative w-full max-w-5xl mx-auto flex items-center justify-between space-x-4">
    <motion.button
      onClick={slideLeft}
      whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
      animate={{ x: [0, -8, 8, -8, 0] }}
      transition={{ duration: 0.05, ease: "easeInOut" }}
      className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
    >
      &#8592;
    </motion.button>

    <div className="w-full relative overflow-hidden rounded-lg shadow-lg flex justify-center items-center bg-white">
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={currentIndex}
          className="w-full flex flex-col items-center"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <motion.img
            src={images[currentIndex].src}
            alt={`Visualization ${currentIndex + 1}`}
            className="w-full sm:w-3/4 h-auto object-contain p-2"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div
            className="w-full p-4 sm:p-7 bg-white text-black rounded-b-lg shadow-lg"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <p className="text-base sm:text-lg leading-relaxed">
              {images[currentIndex].description}
            </p>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>

    <motion.button
      onClick={slideRight}
      whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
      animate={{ x: [0, 8, -8, 8, 0] }}
      transition={{ duration: 0.05, ease: "easeInOut" }}
      className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
    >
      &#8594;
    </motion.button>
  </div>
</section>


                                <motion.div
                                    className="bg-gray-100 p-6 rounded-lg mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Statistical Modeling</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        To further analyze the data, we employed several statistical models:
                                    </p>
                                    <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                        <li className="mb-2">
                                            <strong>Logistic Regression:</strong> Used to predict anemia status based on hemoglobin levels and other demographic factors. The model’s performance was evaluated using a confusion matrix, which was visualized using a heatmap to illustrate the true positives, true negatives, false positives, and false negatives.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Linear Regression:</strong> Applied to examine the trends in anemia prevalence over time across different regions. The regression analysis helped identify regions with declining or increasing anemia rates, highlighting areas where public health interventions may be needed.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Correlation Analysis:</strong> Conducted to explore the relationships between anemia prevalence in pregnant and non-pregnant women across various regions. The correlation matrix was visualized using a heatmap to identify strong positive or negative correlations, which indicate whether anemia trends in pregnant women align with those in non-pregnant women within the same regions.
                                        </li>
                                    </ul>

                                </motion.div>
                            </section>

                        )}

                        {activeSection === 'results' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Results and Impact</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    My project has produced outstanding results, advancing our understanding of anemia prevalence and its impact, and setting the stage for more targeted and effective public health strategies and interventions:
                                </motion.p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Hemoglobin Distribution</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                            The analysis revealed that non-anemic women typically have higher hemoglobin levels, with the distribution centered around 13 to 14 g/dL. In contrast, the anemic group had a lower distribution, peaking between 11 and 12 g/dL. The overlap in the 12 to 13 g/dL range indicates a diagnostic gray area where accurate classification can be challenging.                                        </motion.p>
                                    </motion.div>
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Anemia Prevalence Trends</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                            The study found that anemia prevalence among pregnant women has generally declined worldwide from 2010 to 2019, with the most significant decreases observed in the Eastern Mediterranean and Africa. However, Europe showed a slight increase. For non-pregnant women, the trends were more varied, with some regions experiencing increases in anemia prevalence.                                        </motion.p>
                                    </motion.div>
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Correlation Analysis</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                            The correlation analysis revealed a significant negative relationship between the global prevalence of anemia in pregnant and non-pregnant women. This suggests that increases in anemia prevalence among pregnant women may be associated with decreases in non-pregnant women, or vice versa, highlighting the complex interplay of factors influencing anemia in different populations.</motion.p>
                                    </motion.div>
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Public Health Implications</h3>
                                        <motion.p
                                            className="text-lg text-gray-700 leading-relaxed mb-4"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.2 }}
                                        >
                                            The results underscore the importance of region-specific public health interventions. In areas like South-East Asia and Africa, where anemia rates remain high, targeted strategies focusing on nutritional supplementation, education, and healthcare access are critical. Additionally, the findings highlight the need for improved diagnostic criteria that consider the overlapping hemoglobin ranges in anemic and non-anemic women.</motion.p>
                                    </motion.div>
                                </div>
                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Impact of the Findings</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The insights gained from this project can inform public health policies and interventions aimed at reducing anemia, particularly in high-risk populations like pregnant women in developing countries. By understanding the regional variations and the factors contributing to anemia, health organizations can develop more effective strategies for prevention and treatment.
                                    </p>
                                    {/* <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed">
                                        <li>Accelerated drug discovery processes by providing accurate protein structure models for virtual screening and rational drug design</li>
                                        <li>Enhanced understanding of genetic diseases by elucidating the structural effects of mutations, paving the way for personalized medicine approaches</li>
                                        <li>Facilitated the design of novel enzymes for industrial and environmental applications, contributing to sustainable biotechnology</li>
                                        <li>Improved protein engineering efforts, enabling the creation of proteins with enhanced stability, activity, or novel functions</li>
                                        <li>Advanced our fundamental understanding of protein folding and evolution, contributing to basic research in molecular biology</li>
                                    </ul> */}
                                </motion.div>
                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Visualization of Findings</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        Below are some of the key visualizations that summarize the study’s findings:
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">Histogram of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A histogram shows the distribution of hemoglobin levels (g/dL) for pregnant (blue) and non-pregnant (red) individuals, with density plots overlaid to highlight distribution shapes. Vertical dashed lines mark the anemia thresholds: 11.0 g/dL for pregnant (blue) and 12.0 g/dL for non-pregnant (red). The distribution for pregnant individuals is shifted towards lower hemoglobin levels, with more individuals near the anemia threshold. Both distributions are right-skewed.
                                                </li>
                                                <motion.div
                                                    className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                                    initial={{ scale: 0.8, opacity: 0 }}
                                                    animate={{ scale: 1, opacity: 1 }}
                                                    transition={{ duration: 0.6 }}
                                                    whileHover={{ scale: 1.02 }}
                                                >
                                                    <img
                                                        src="https://i.imghippo.com/files/3zhFP1726061928.png"
                                                        alt="The Importance of Genomic Data in Precision Medicine"
                                                        className="w-full h-auto object-cover rounded-lg"
                                                    />
                                                </motion.div>
                                            </ul>
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">Confusion matrix of Hemoglobin Levels:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    Confusion matrices for anemia classification show perfect model performance in both groups. For pregnant women, the model correctly identifies 3,824 non-anemic and 16 anemic cases with no misclassifications. In the non-pregnant group, it accurately classifies 3,314 non-anemic and 526 anemic cases, also without errors. The higher number of anemic women in the non-pregnant group indicates a more diverse distribution compared to the pregnant group. Both matrices highlight a highly accurate classification model.
                                                </li>
                                                <div className="flex flex-wrap justify-center gap-8 mb-12">
                                                    <motion.div
                                                        className="relative w-full sm:w-2/3 md:w-2/4 lg:w-2/5 overflow-hidden rounded-xl shadow-2xl"
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{ duration: 0.6 }}
                                                        whileHover={{ scale: 1.02 }}
                                                    >
                                                        <img
                                                            src="https://i.imghippo.com/files/G847u1726062085.png"
                                                            alt="The Importance of Genomic Data in Precision Medicine"
                                                            className="w-full h-auto object-cover rounded-lg"
                                                        />
                                                    </motion.div>
                                                    <motion.div
                                                        className="relative w-full sm:w-2/3 md:w-2/4 lg:w-2/5 overflow-hidden rounded-xl shadow-2xl"
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{ duration: 0.6 }}
                                                        whileHover={{ scale: 1.02 }}
                                                    >
                                                        <img
                                                            src="https://i.imghippo.com/files/vG16M1726062113.png"
                                                            alt="The Importance of Genomic Data in Precision Medicine"
                                                            className="w-full h-auto object-cover rounded-lg"
                                                        />
                                                    </motion.div>
                                                </div>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Pheatmap of Anemia Prevalence:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    A heatmap shows a correlation matrix of anemia prevalence between pregnant and non-pregnant women across WHO regions. Most regions, like Africa, the Americas, and Southeast Asia, have high correlations close to 1, indicating strong similarities. However, Europe and the Eastern Mediterranean show notably lower correlations. A red-to-blue gradient highlights these differences.
                                                </li>
                                            </ul>
                                            <motion.div
                                                className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                                initial={{ scale: 0.8, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{ duration: 0.6 }}
                                                whileHover={{ scale: 1.02 }}
                                            >
                                                <img
                                                    src="https://i.imghippo.com/files/DmeEG1726062136.png"
                                                    alt="The Importance of Genomic Data in Precision Medicine"
                                                    className="w-full h-auto object-cover rounded-lg"
                                                />
                                            </motion.div>
                                        </li>
                                    </ol>
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'code' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Sample Code</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Below is a sample Python code snippet demonstrating how we analyze and visualize hemoglobin levels for pregnant and non-pregnant individuals. This code provides a comprehensive look into the statistical analysis and visualization of our data:
                                </motion.p>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <CodeBlock code={sampleCode} />
                                </motion.div>
                                <motion.div
                                    className="mt-8 flex flex-wrap gap-4"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <a
                                        href="https://github.com/YasmineElnasharty/Assessing_Anemia_in_Pregnant_and_Non-Pregnant_Women.git"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
                                    >
                                        <FaGithub className="mr-2" /> View on GitHub
                                    </a>
                                    {/* <a
                                        href="https://colab.research.google.com/drive/your-notebook-id" // Replace with actual Colab URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-400 transition duration-300"
                                    >
                                        <SiGooglecolab className="mr-2" /> Open in Colab
                                    </a>
                                    <a
                                        href="https://jupyter.org/try" // Or replace with your own Jupyter URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-400 transition duration-300"
                                    >
                                        <SiJupyter className="mr-2" /> Try in Jupyter
                                    </a> */}
                                </motion.div>
                                <motion.div
                                    className="mt-8 bg-blue-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-blue-800 mb-4">Code Explanation</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        This code snippet outlines the process of analyzing and visualizing hemoglobin levels using Python:
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">Data Loading and Cleaning:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    We begin by loading two datasets: one for pregnant individuals and one for non-pregnant individuals.
                                                </li>
                                                <li>
                                                    The 'extract_numeric' function is used to parse and clean the hemoglobin level data from text fields.
                                                </li>
                                                <li>
                                                    We then remove any rows with missing hemoglobin values and add a 'Group' column to differentiate between pregnant and non-pregnant individuals.
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">Data Combination and Visualization:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The cleaned datasets are combined into a single DataFrame.
                                                </li>
                                                <li>
                                                    A histogram is created to visualize the distribution of hemoglobin levels for both groups, with different colors representing each group.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Linear Regression Analysis:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The 'Group' column is encoded into binary values (1 for Pregnant, 0 for Non-Pregnant).
                                                </li>
                                                <li>
                                                    We use this encoded column to predict hemoglobin levels with a linear regression model.
                                                </li>
                                                <li>
                                                    The model is trained and evaluated, and the results are visualized by plotting actual vs. predicted hemoglobin levels.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-semibold">Model Metrics:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    The code prints the coefficient and intercept of the linear regression model, providing insight into the relationship between group status and hemoglobin levels.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                                        This example demonstrates the integration of data cleaning, statistical modeling, and visualization to provide a detailed analysis of hemoglobin levels across different groups.
                                    </p>
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'references' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">References</h2>
                                <ul className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8 space-y-4">
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/3878044/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Demayer EM, Tegman A. Prevalence of anemia in the world World Health Organ Qlty. 1998;38:302–18                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/12423980/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Ezzati M, Lopez AD, Rodgers A, Hoorn SV, Murray CJ. Selected major risk factors and global and regional burden of disease Lancet. 2002; 360:1347–60                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/11519193/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Breyman C. Current aspects of diagnosis and therapy of iron deficiency anemia in pregnancy Schweiz Rundsch Med Prax. 2001; 90:1281–93                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/18498676/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            De Benoist B, McLean E, Egli I, Cogswell M Worldwide Prevalence of Anaemia 1993-2005: WHO Global Database on Anaemia. 2008 Geneva World Health Organization                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/7537709/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Axemo P, Liljestrand J, Bergstrom S, Gebre-Medhin M. Aetiology of late fetal death in Maputo Gynecol Obstet Invest. 1995;39:103–9                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/11160595/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Brabin BJ, Premji Z, Verhoeff F. An analysis of anaemia and child mortality J Nutr. 2001;132:636S–48S                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.who.int/data/gho/data/indicators/indicator-details/GHO/prevalence-of-anaemia-in-pregnant-women-(-)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            World Health Organization. (n.d.-b). Prevalence of anaemia in pregnant women (aged 15-49) (%). World Health Organization                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.who.int/data/gho/data/indicators/indicator-details/GHO/anaemia-in-non-pregnant-women-prevalence-(-)"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            World Health Organization. (n.d.-b). Prevalence of anaemia in non-pregnant women (aged 15-49) (%). World Health Organization.                                         </a>
                                    </li>
                                </ul>
                            </section>
                        )}

                    <div className="flex justify-between mt-6">
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToPreviousSection}
                            >
                                Previous
                            </motion.button>
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToNextSection}
                            >
                                Next
                            </motion.button>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </main>
            <ScrollToTopButton />
            <Footer />
        </div>
    );
};

export default Project1;
