import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';  // Import your Contact page
import Blog from './components/Blog';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Blog1 from './components/blogs/Blog1';
import Blog2 from './components/blogs/Blog2';
import Blog3 from './components/blogs/Blog3';
import Blog4 from './components/blogs/Blog4';
import Project1 from './components/projects/Project1';
import Project2 from './components/projects/Project2';
import Project3 from './components/projects/Project3';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/Home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/About" element={<About />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/blogs/Blog1" element={<Blog1 />} />
        <Route path="/blogs/Blog2" element={<Blog2 />} />
        <Route path="/blogs/Blog3" element={<Blog3 />} />
        <Route path="/blogs/Blog4" element={<Blog4 />} />
        <Route path="/projects/Project1" element={<Project1 />} />
        <Route path="/projects/Project2" element={<Project2 />} />
        <Route path="/projects/Project3" element={<Project3 />} />
      </Routes>
    </Router>
  );
};

export default App;
