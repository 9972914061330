import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ThumbsUp, User, Clock, Tag, MoreHorizontal } from 'lucide-react';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter, FaResearchgate } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import { db } from '../../firebase'; // Import Firestore
import CommentForm from '../CommentForm';
import CommentsList from '../CommentsList';
import NewsletterSignup from '../NewsletterSignup'; 
const ScrollToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);
  
    useEffect(() => {
      const checkScrollTop = () => {
        // Show the scroll icon if user scrolled down 300 pixels or more
        if (!showScroll && window.scrollY > 300) {
          setShowScroll(true);
        } else if (showScroll && window.scrollY <= 300) {
          setShowScroll(false);
        }
      };
  
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);
  
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <div
        className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
        onClick={scrollTop}
      >
        <FaChevronUp size={24} />
      </div>
    );
  };

const Blog1 = () => {
    const [comment, setComment] = useState('');
    const blogId = 'blog1';
    const admin = {
        username: 'Admin', // Replace with your admin name
        avatar: 'https://i.pravatar.cc/150?img=5', // Replace with your admin avatar URL
    };
    useEffect(() => {
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
      
        document.addEventListener('contextmenu', handleContextMenu);
      
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
    
      useEffect(() => {
        const handleKeydown = (e) => {
          // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
          if (
            e.keyCode === 123 || // F12
            (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
            (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
            (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
          ) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('keydown', handleKeydown);
      
        return () => {
          document.removeEventListener('keydown', handleKeydown);
        };
      }, []);
      useEffect(() => {
        const disableInspectMethods = (e) => {
          // Prevent "Inspect Element" via right-click or drag and drop on element
          if (e.type === 'mousedown' && e.button === 2) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('mousedown', disableInspectMethods);
      
        return () => {
          document.removeEventListener('mousedown', disableInspectMethods);
        };
      }, []);
        

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/prism/1.24.1/prism.min.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const blogPosts = [
        {
            title: 'Precision Medicine: Bioinformatics Role in Tailoring Treatments',
            image: 'https://media.istockphoto.com/id/1420177680/vector/biomarker-discovery-for-diagnostic-and-prognostic-or-predictive-medical-purposes-conceptual.jpg?s=612x612&w=0&k=20&c=HTLzvtzVzbIasmK-PM0GKWPzk2rQa004n5suE4YM_I8=', // Image of data flow diagram
            category: 'Clinical Bioinformatics',
            date: 'Aug 3, 2024',
            excerpt: 'How bioinformatics is advancing precision medicine by utilizing genomic data to tailor treatments to individual patients...',
            readTime: '5 min read',
            url: '/blogs/Blog1'
        },
        {
            title: 'Revolutionizing Genomic Data Analysis: The Power of Bioinformatics',
            image: 'https://t3.ftcdn.net/jpg/07/66/13/88/240_F_766138899_xLGGWEhVVqtyrWUyqDIHTuBhJjmA2cas.jpg', // Image of DNA sequencing visualization
            category: 'Genomics',
            date: 'Jul 25, 2024',
            excerpt: 'Exploring the role of bioinformatics in unlocking the complexities of genomic data for healthcare breakthroughs...',
            readTime: '5 min read',
            url: '/blogs/Blog2'
        },
        {
            title: 'Protein Structure Prediction with AlphaFold: A New Era in Bioinformatics',
            image: 'https://assets.technologynetworks.com/production/dynamic/images/content/378804/alphafold-analyzes-millions-of-predicted-protein-structures-378804-1280x720.webp?cb=12466579', // Image of complex network graphs
            category: 'Proteomics',
            date: 'Jul 17, 2024',
            excerpt: 'How AlphaFold is reshaping protein structure prediction and its impact on bioinformatics...',
            readTime: '7 min read',
            url: '/blogs/Blog3'
        },
        {
            title: 'Unveiling Cancer Genomics: The Role of Bioinformatics in Cancer Research',
            image: 'https://media.istockphoto.com/id/2151333984/photo/cancer-cell-view.jpg?s=612x612&w=0&k=20&c=AFjXl5V9MvSBqstZH4H0ojzS_dC9uCfJ_8XTOxmPPCs=', // Image of 3D protein structure
            category: 'Cancer Bioinformatics',
            date: 'Jul 10, 2024',
            excerpt: 'How bioinformatics is advancing our understanding of cancer genomics and paving the way for new treatments...',
            readTime: '8 min read',
            url: '/blogs/Blog4'
        },
        // {
        //     title: 'The Impact of AI in Bioinformatics',
        //     image: 'https://picsum.photos/seed/bioai/800/600', // Image of AI-assisted molecular modeling
        //     category: 'AI & Machine Learning',
        //     date: 'May 5, 2024',
        //     excerpt: 'Exploring how artificial intelligence is revolutionizing bioinformatics research and applications...',
        //     readTime: '5 min read'
        // },
        // {
        //     title: 'Data Visualization in Bioinformatics',
        //     image: 'https://picsum.photos/seed/biovis/800/600', // Image of complex biological data visualization
        //     category: 'Data Visualization',
        //     date: 'May 2, 2024',
        //     excerpt: 'Best practices for creating clear and informative visualizations of complex biological data...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Metagenomics: A New Frontier',
        //     image: 'https://picsum.photos/seed/metagenomics/800/600', // Image of microbial communities
        //     category: 'Genomics',
        //     date: 'April 30, 2024',
        //     excerpt: 'Discover the potential of metagenomics in understanding complex microbial ecosystems...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Big Data Challenges in Bioinformatics',
        //     image: 'https://picsum.photos/seed/bigdata/800/600', // Image of big data cloud and bioinformatics symbols
        //     category: 'Data Processing',
        //     date: 'April 27, 2024',
        //     excerpt: 'Addressing the challenges and opportunities presented by big data in bioinformatics...',
        //     readTime: '5min read'
        // },
        // {
        //     title: 'Single-Cell RNA Sequencing Techniques',
        //     image: 'https://picsum.photos/seed/scrna/800/600', // Image of single-cell RNA sequencing data
        //     category: 'Genomics',
        //     date: 'April 25, 2024',
        //     excerpt: 'An overview of the latest single-cell RNA sequencing techniques and their applications...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Machine Learning in Drug Discovery',
        //     image: 'https://picsum.photos/seed/drugml/800/600', // Image of AI-driven drug discovery
        //     category: 'AI & Machine Learning',
        //     date: 'April 22, 2024',
        //     excerpt: 'How machine learning is accelerating drug discovery processes in bioinformatics...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Genetic Variants and Personalized Medicine',
        //     image: 'https://picsum.photos/seed/personalmed/800/600', // Image of personalized medicine concept
        //     category: 'Genomics',
        //     date: 'April 20, 2024',
        //     excerpt: 'Understanding the role of genetic variants in the development of personalized medicine...',
        //     readTime: '5 min read'
        // },
        // {
        //     title: 'CRISPR and Genome Editing Innovations',
        //     image: 'https://picsum.photos/seed/crispr/800/600', // Image of CRISPR technology visualization
        //     category: 'Genomics',
        //     date: 'April 18, 2024',
        //     excerpt: 'Exploring the latest innovations in CRISPR and its impact on genome editing...',
        //     readTime: '5min read'
        // },
        // {
        //     title: 'Bioinformatics for Cancer Genomics',
        //     image: 'https://picsum.photos/seed/cancergenomics/800/600', // Image of cancer cells and genomic data
        //     category: 'Genomics',
        //     date: 'April 15, 2024',
        //     excerpt: 'How bioinformatics is driving advancements in cancer genomics and personalized oncology...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Epigenomics: Beyond the Genome',
        //     image: 'https://picsum.photos/seed/epigenomics/800/600', // Image of epigenetic modifications
        //     category: 'Genomics',
        //     date: 'April 12, 2024',
        //     excerpt: 'An introduction to epigenomics and its role in understanding gene expression...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'The Power of Transcriptomics',
        //     image: 'https://picsum.photos/seed/transcriptomics/800/600', // Image of transcriptomic data visualization
        //     category: 'Genomics',
        //     date: 'April 10, 2024',
        //     excerpt: 'Unlocking the secrets of gene expression with advanced transcriptomic analysis...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Harnessing the Potential of Synthetic Biology',
        //     image: 'https://picsum.photos/seed/syntheticbio/800/600', // Image of synthetic biology structures
        //     category: 'Synthetic Biology',
        //     date: 'April 8, 2024',
        //     excerpt: 'The latest advancements in synthetic biology and their applications in bioinformatics...',
        //     readTime: '5min read'
        // },
        // {
        //     title: 'Network Biology: Connecting the Dots',
        //     image: 'https://picsum.photos/seed/networkbio/800/600', // Image of biological networks
        //     category: 'Systems Biology',
        //     date: 'April 5, 2024',
        //     excerpt: 'Understanding complex biological systems through network biology and bioinformatics...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Proteomics: A Deeper Dive into Proteins',
        //     image: 'https://picsum.photos/seed/proteomics/800/600', // Image of proteomic data
        //     category: 'Proteomics',
        //     date: 'April 3, 2024',
        //     excerpt: 'Exploring the proteome with cutting-edge proteomics techniques and tools...',
        //     readTime: '5 min read'
        // },
        // {
        //     title: 'Integrative Omics: Unifying Biological Data',
        //     image: 'https://picsum.photos/seed/integrativeomics/800/600', // Image of integrative omics data
        //     category: 'Multi-Omics',
        //     date: 'April 1, 2024',
        //     excerpt: 'The role of integrative omics in creating a holistic view of biological systems...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Clinical Bioinformatics: Bridging Research and Healthcare',
        //     image: 'https://picsum.photos/seed/clinicalbio/800/600', // Image of clinical data in bioinformatics
        //     category: 'Clinical Bioinformatics',
        //     date: 'March 29, 2024',
        //     excerpt: 'How clinical bioinformatics is transforming patient care and medical research...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Evolutionary Biology and Bioinformatics',
        //     image: 'https://picsum.photos/seed/evolutionbio/800/600', // Image of evolutionary trees
        //     category: 'Evolutionary Biology',
        //     date: 'March 27, 2024',
        //     excerpt: 'Exploring the intersection of evolutionary biology and bioinformatics...',
        //     readTime: '5min read'
        // },
        // {
        //     title: 'Data Integration in Bioinformatics',
        //     image: 'https://picsum.photos/seed/dataintegration/800/600', // Image of data integration in bioinformatics
        //     category: 'Data Processing',
        //     date: 'March 25, 2024',
        //     excerpt: 'Strategies for integrating diverse biological data sources in bioinformatics...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Population Genomics: Insights from Big Data',
        //     image: 'https://picsum.photos/seed/populationgenomics/800/600', // Image of population genomics data
        //     category: 'Genomics',
        //     date: 'March 23, 2024',
        //     excerpt: 'Understanding human diversity through population genomics and bioinformatics...',
        //     readTime: '6 min read'
        // },
        // {
        //     title: 'Bioinformatics in Agricultural Genomics',
        //     image: 'https://picsum.photos/seed/aggenomics/800/600', // Image of agricultural crops and genomic data
        //     category: 'Agrigenomics',
        //     date: 'March 20, 2024',
        //     excerpt: 'The role of bioinformatics in improving crop yield and agricultural sustainability...',
        //     readTime: '7 min read'
        // },
        // {
        //     title: 'Gene Expression Analysis Tools and Techniques',
        //     image: 'https://picsum.photos/seed/geneexpression/800/600', // Image of gene expression data visualization
        //     category: 'Genomics',
        //     date: 'March 18, 2024',
        //     excerpt: 'A review of the latest tools and techniques for gene expression analysis...',
        //     readTime: '5min read'
        // },
        // {
        //     title: 'Understanding Microbiomes through Bioinformatics',
        //     image: 'https://picsum.photos/seed/microbiomes/800/600', // Image of microbiome data
        //     category: 'Microbiome',
        //     date: 'March 15, 2024',
        //     excerpt: 'Exploring the complex world of microbiomes using bioinformatics...',
        //     readTime: '6 min read'
        // }
    ];

    const SVGDoubleHelix = () => {
        return (
            <svg
                viewBox="0 0 800 600"
                className="w-full h-full opacity-20"
                style={{
                    position: 'absolute',
                    top: '130%', // Adjust this value to move the helix lower
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
                <DNAStrand color="url(#grad1)" offset={0} />
                <DNAStrand color="url(#grad1)" offset={Math.PI} />
            </svg>
        );
    };

    const DNAStrand = ({ color, offset }) => {
        const points = 20;
        const ySpread = 80;
        const xSpread = 800;

        return (
            <>
                {[...Array(points)].map((_, i) => {
                    const x = (i / (points - 1)) * xSpread;
                    const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
                    return (
                        <g key={i}>
                            <motion.circle
                                cx={x}
                                cy={y}
                                r={3}
                                fill={color}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
                                transition={{
                                    duration: 3,
                                    repeat: Infinity,
                                    delay: i * 0.2,
                                }}
                            />
                            {i < points - 1 && (
                                <motion.line
                                    x1={x}
                                    y1={y}
                                    x2={(i + 1) / (points - 1) * xSpread}
                                    y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                                    stroke={color}
                                    strokeWidth={1}
                                    initial={{ pathLength: 0 }}
                                    animate={{ pathLength: [0, 1, 0] }}
                                    transition={{
                                        duration: 3,
                                        repeat: Infinity,
                                        delay: i * 0.2,
                                    }}
                                />
                            )}
                        </g>
                    );
                })}
            </>
        );
    };

    const Footer = () => {
        const currentYear = new Date().getFullYear();
        const [hoveredIcon, setHoveredIcon] = useState(null);
        const [email, setEmail] = useState('');
        const [showThankYou, setShowThankYou] = useState(false);
      
        // Social media details
        const socialIcons = [
          { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
          { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
          { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
        ];
      
      
        
        const handleSubmit = (e) => {
          e.preventDefault();
          if (email) {
            emailjs.send(
              'service_f0vv34a',  // Your service ID
              'template_x9vg3re', // Your template ID
              {
                to_email: email,  // Dynamically set the recipient's email
                message: 'Thank you for subscribing to our newsletter!'
              },
              'oM0GV2_SQASnm0uWu'    // Your public key
            )
            .then((response) => {
              console.log('Email sent successfully:', response);
              setShowThankYou(true);
              setEmail('');
            })
            .catch((error) => {
              console.error('Error sending email:', error);
              alert('There was an error subscribing. Please try again later.');
            });
          }
        };
      
        useEffect(() => {
          if (showThankYou) {
            const timer = setTimeout(() => setShowThankYou(false), 5000);
            return () => clearTimeout(timer);
          }
        }, [showThankYou]);
      
        const ThankYouAnimation = () => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
          >
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={500}
              gravity={0.2}
            />
            <motion.div
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{ type: 'spring', stiffness: 260, damping: 20 }}
              className="text-9xl mb-8"
            >
              🎉
            </motion.div>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-center"
            >
              <motion.h2
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
                className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
              >
                Thank You!
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="text-3xl text-purple-200 drop-shadow-md"
              >
                You've subscribed to my newsletter!
              </motion.p>
            </motion.div>
          </motion.div>
        );
      
        return (
          <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 relative z-10">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                {/* Logo and tagline */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="col-span-1 md:col-span-2 lg:col-span-1"
                >
                  <div className="flex items-center mb-4">
                    {/* Logo */}
                    <img
                      src="/Y.png"
                      alt="Logo"
                      className="text-4xl text-purple-400 mr-3"
                      style={{ height: '160px' }}  // Larger size for the logo
                    />
                  </div>
                  <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
                  >
                    <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
                  </motion.button>
                </motion.div>
      
                {/* Quick links */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
                  <ul className="space-y-2">
                    <motion.li
                      whileHover={{ x: 5, color: '#D1D5DB' }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                        About Me
                      </a>
                    </motion.li>
                    <motion.li
                      whileHover={{ x: 5, color: '#D1D5DB' }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                        Projects
                      </a>
                    </motion.li>
                    <motion.li
                      whileHover={{ x: 5, color: '#D1D5DB' }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                        Contact Me
                      </a>
                    </motion.li>
                    <motion.li
                      whileHover={{ x: 5, color: '#D1D5DB' }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                        Blog
                      </a>
                    </motion.li>
                  </ul>
                </motion.div>
      
                {/* Latest Blog */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                >
                  <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
                  <motion.div
                    whileHover={{ scale: 1.03 }}
                    className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
                  >
                    <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
                    <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
                    <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
                  </motion.div>
                </motion.div>
      
                <NewsletterSignup />
              </div>
      
              {/* Social Icons */}
              <motion.div
                className="flex justify-center space-x-6 mt-12"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.8 }}
              >
                {socialIcons.map(({ Icon, color, name, url }) => (
                  <motion.a
                    key={name}
                    href={url}
                    target="_blank" // Opens link in new tab
                    rel="noopener noreferrer" // Security for external links
                    onMouseEnter={() => setHoveredIcon(name)}
                    onMouseLeave={() => setHoveredIcon(null)}
                    whileHover={{ y: -5 }}
                    className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
                  >
                    <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
                    <AnimatePresence>
                      {hoveredIcon === name && (
                        <motion.span
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                        >
                          {name}
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </motion.a>
                ))}
              </motion.div>
      
              {/* DNA Animation */}
              <SVGDoubleHelix />
      
              {/* Floating Particles */}
              <div className="absolute inset-0 overflow-hidden pointer-events-none">
                {[...Array(20)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute bg-purple-500 rounded-full opacity-20"
                    style={{
                      width: Math.random() * 10 + 5,
                      height: Math.random() * 10 + 5,
                      left: `${Math.random() * 100}%`,
                      top: `${Math.random() * 100}%`,
                    }}
                    animate={{
                      y: [0, -30, 0],
                      opacity: [0.2, 0.5, 0.2],
                    }}
                    transition={{
                      duration: Math.random() * 3 + 2,
                      repeat: Infinity,
                      ease: 'easeInOut',
                    }}
                  />
                ))}
              </div>
      
              {/* Copyright */}
              <motion.div
                className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                <p>&copy; {currentYear} Yasmine. All rights reserved. |
                  <button
                    className="ml-2 hover:text-purple-200 transition duration-300"
                    onClick={() => alert("Privacy Policy Coming Soon!")}
                  >
                    Privacy Policy
                  </button> |
                  <button
                    className="ml-2 hover:text-purple-200 transition duration-300"
                    onClick={() => alert("Terms of Service Coming Soon!")}
                  >
                    Terms of Service
                  </button>
                </p>
              </motion.div>
            </div>
            <AnimatePresence>
              {showThankYou && <ThankYouAnimation />}
            </AnimatePresence>
          </footer>
        );
      };

    const MenuBar = () => {
        const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

        const linkStyle = {
            color: '#1F2937',
            textTransform: 'uppercase',
            textDecoration: 'none',
            letterSpacing: '0.15em',
            display: 'inline-block',
            padding: '15px 20px',
            position: 'relative',
            fontWeight: '700',
        };

        const hoverEffectStyle = {
            content: '""',
            background: '#D946EF',
            bottom: 0,
            height: '2px',
            left: '50%',
            position: 'absolute',
            transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
            width: 0,
        };

        const liStyle = {
            display: 'table-cell',
            position: 'relative',
            padding: '15px 0',
        };

        const ulStyle = {
            margin: '0 auto',
            padding: 0,
            listStyle: 'none',
            display: 'table',
            width: '800px',
            textAlign: 'center',
        };

        const mobileMenuStyle = {
            position: 'absolute',
            top: '60px',
            left: '0',
            width: '100%',
            backgroundColor: '#FFFFFF',
            padding: '10px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 100,
        };

        const mobileLinkStyle = {
            ...linkStyle,
            padding: '10px 20px',
        };

        return (
            <>
                {/* Desktop View */}
                <motion.nav
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{
                        position: 'relative',
                        top: '10px',
                        left: 0,
                        width: '100%',
                        zIndex: 50,
                        backgroundColor: 'transparent',
                    }}
                    className="desktop-menu"
                >
                    <ul style={ulStyle}>
                        {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                            <motion.li
                                key={text}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                style={liStyle}
                            >
                                <motion.a
                                    href={`/${text.toLowerCase()}`}
                                    style={linkStyle}
                                    whileHover={{ scale: 1.1 }}
                                    onMouseEnter={(e) => {
                                        const hoverSpan = e.currentTarget.querySelector('span');
                                        hoverSpan.style.width = '100%';
                                        hoverSpan.style.left = '0';
                                    }}
                                    onMouseLeave={(e) => {
                                        const hoverSpan = e.currentTarget.querySelector('span');
                                        hoverSpan.style.width = '0';
                                        hoverSpan.style.left = '50%';
                                    }}
                                >
                                    {text}
                                    <span style={hoverEffectStyle}></span>
                                </motion.a>
                            </motion.li>
                        ))}
                    </ul>
                </motion.nav>

                {/* Mobile View */}
                <motion.nav
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{
                        position: 'relative',
                        top: '10px',
                        left: 0,
                        width: '100%',
                        zIndex: 50,
                        backgroundColor: 'transparent',
                    }}
                    className="mobile-menu"
                >
                    <div
                        style={{ padding: '15px 20px', cursor: 'pointer' }}
                        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                        <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                        <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    </div>
                    {isMobileMenuOpen && (
                        <motion.ul
                            style={mobileMenuStyle}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                                <motion.li
                                    key={text}
                                    style={{ padding: '10px 0' }}
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                                        {text}
                                    </a>
                                </motion.li>
                            ))}
                        </motion.ul>
                    )}
                </motion.nav>

                <style>
                    {`
                    @media (min-width: 768px) {
                        .mobile-menu {
                            display: none;
                        }
                    }
                    @media (max-width: 767px) {
                        .desktop-menu {
                            display: none;
                        }
                    }
                `}
                </style>
            </>
        );
    };

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const randomCategories = shuffleArray([...new Set(blogPosts.map((post) => post.category))]).slice(0, 5);
    const randomPopularPosts = shuffleArray(blogPosts).slice(0, 5);

    const SidebarCategories = ({ randomCategories = [], blogPosts = [] }) => (
        <motion.div
            className="bg-white p-4 sm:p-6 rounded-3xl shadow-lg mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        >
            <h3 className="text-2xl font-bold mb-4 text-gray-800">Categories</h3>
            <ul className="space-y-3">
                {randomCategories.map((category, index) => (
                    <motion.li
                        key={category}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0, transition: { duration: 0.3, delay: index * 0.1 } }}
                        whileHover={{ scale: 1.02 }}
                        className="bg-gray-50 hover:bg-purple-50 transition-colors duration-300 rounded-xl overflow-hidden"
                    >
                        <div className="flex items-center justify-between p-3 sm:p-4">
                            <span className="flex items-center text-sm sm:text-base text-gray-700">
                                <Tag size={16} className="mr-3 text-purple-500" />
                                {category}
                            </span>
                            <span
                                className="flex items-left justify-center text-xs sm:text-sm bg-purple-100 text-purple-700 rounded-full"
                                style={{
                                    minWidth: '28px', // Ensures a minimum width for the circle
                                    padding: '4px 8px', // Adjust padding for centering text
                                    textAlign: 'left', // Centers text within the circle
                                }}
                            >
                                {blogPosts.filter((post) => post.category === category).length} posts
                            </span>
                        </div>
                    </motion.li>
                ))}
            </ul>
        </motion.div>
    );


    const PopularPosts = ({ randomPopularPosts = [] }) => (
        <motion.div
            className="bg-white p-4 sm:p-6 rounded-3xl shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        >
            <h3 className="text-2xl font-bold mb-4 text-gray-800">Popular Posts</h3>
            <ul className="space-y-4">
                {randomPopularPosts.map((post, index) => (
                    <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0, transition: { duration: 0.3, delay: index * 0.1 } }}
                        whileHover={{ scale: 1.02 }}
                        className="group bg-gray-50 hover:bg-purple-50 transition-colors duration-300 rounded-xl overflow-hidden"
                    >
                        <Link to={post.url} className="block">
                            <div className="flex items-center p-3 sm:p-4">
                                <img src={post.image} alt={post.title} className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-lg mr-4" />
                                <div className="flex-grow">
                                    <h4 className="font-semibold text-sm sm:text-base mb-1 text-gray-800 group-hover:text-purple-700 transition-colors duration-300">{post.title}</h4>
                                    <p className="text-xs sm:text-sm text-gray-500">
                                        <span className="inline-block bg-purple-100 text-purple-700 px-2 py-1 rounded-full mr-2">{post.category}</span>
                                        {post.date}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </motion.li>
                ))}
            </ul>
        </motion.div>
    );



    const FutureOfAISection = () => (
        <motion.section
            className="mb-12 relative overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
        >
            <div className="absolute rounded-3xl" />
            <div className="relative z-10 p-8 md:p-12">
                <motion.h2
                    className="text-5xl md:text-6xl font-extrabold mb-8"
                    style={{
                        background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    The Future of Precision Medicine
                </motion.h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <p className="text-xl font-light leading-relaxed text-gray-700 mb-6">
                            The future of precision medicine is bright, with bioinformatics at its core. Advances in machine learning and artificial intelligence are expected to enhance the ability to interpret complex genomic data and predict patient outcomes more accurately. Additionally, the continued growth of public genomic databases and biobanks will provide invaluable resources for developing new precision medicine strategies.
                        </p>
                        <p className="text-xl font-light leading-relaxed text-gray-700">
                            Imagine a world where personalized medicine is the norm, tailored to your unique genetic makeup. AI algorithms
                            could analyze vast genomic databases in seconds, identifying potential health risks and suggesting preventive
                            measures before symptoms even appear.
                        </p>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <h3 className="text-2xl font-bold mb-4 text-gray-700">Looking Ahead:</h3>
                        <ul className="list-disc list-inside text-lg text-gray-700 space-y-3">
                            <li>Combining genomic and clinical data is complex.</li>
                            <li>Advanced bioinformatics tools are needed for integration.</li>
                            <li>Integrating genomic data with EHRs needs standardization.</li>
                            <li>Privacy safeguards are essential for EHR integration.</li>
                            <li>Ensuring broad access to precision medicine is crucial.</li>
                        </ul>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );

    const sampleComments = [
        { user: 'Dr. Smith', content: 'Fascinating article! The potential of AI in genomics is truly remarkable.', likes: 15, avatar: 'https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
        { user: 'GeneticsCoder', content: 'Great code example! I\'d love to see more advanced ML models applied to genomic data.', likes: 8, avatar: 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
        { user: 'BioTechEnthusiast', content: 'The ethical considerations mentioned are crucial. We need to ensure responsible development of these technologies.', likes: 12, avatar: 'https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' },
    ];



    // 3. Floating Particles Animation
    const FloatingParticlesAnimation = () => {
        return (
            <div className="fixed inset-0 pointer-events-none">
                {[...Array(50)].map((_, i) => (
                    <motion.div
                        key={i}
                        className="absolute bg-indigo-400 rounded-full opacity-20"
                        style={{
                            width: Math.random() * 20 + 5,
                            height: Math.random() * 20 + 5,
                        }}
                        initial={{
                            x: Math.random() * window.innerWidth,
                            y: Math.random() * window.innerHeight,
                        }}
                        animate={{
                            x: Math.random() * window.innerWidth,
                            y: Math.random() * window.innerHeight,
                        }}
                        transition={{
                            duration: Math.random() * 10 + 20,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                    />
                ))}
            </div>
        );
    };


    return (
        <div className="min-h-screen bg-white text-gray-900 font-sans relative overflow-hidden">
            <FloatingParticlesAnimation />
            <MenuBar />
            <div className="max-w-full flex flex-col lg:flex-row relative z-10 px-4 lg:px-12 py-4">
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8 }}
                    className="w-full lg:w-3/4 lg:pr-8"
                >
                    {/* Header section */}
                    <header className="mb-12 text-center">
                        <motion.h1
                            className="text-4x1 md:text-5xl font-extrabold mb-4"
                            style={{
                                background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                lineHeight: '1.4' 
                            }}
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            Precision Medicine: Bioinformatics Role in Tailoring Treatments
                        </motion.h1>
                        <motion.div
                            className="text-xl text-gray-300 flex items-center justify-center space-x-4"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <motion.div
                                className="text-lg md:text-xl flex flex-wrap items-center justify-center space-x-6"
                                initial={{ y: 20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                {[
                                    { Icon: User, text: 'Yasmine Elnasharty' },
                                    { Icon: Clock, text: 'August 3, 2024' },
                                    { Icon: Tag, text: 'Clinical Bioinformatics' },
                                ].map(({ Icon, text }, index) => (
                                    <motion.span
                                        key={index}
                                        className="flex items-center text-gray-700 my-2"
                                        whileHover={{ scale: 1.05 }}
                                    >
                                        <Icon size={20} className="mr-2 text-indigo-600" />
                                        {text}
                                    </motion.span>
                                ))}
                            </motion.div>
                        </motion.div>
                    </header>

                    {/* Main image section */}
                    <motion.div
                        className="relative w-full h-[28rem] mb-12 overflow-hidden rounded-xl shadow-2xl"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.6 }}
                        whileHover={{ scale: 1.02 }}
                    >
                        <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-600 opacity-75 z-10"></div>
                        <motion.div
                            className="absolute inset-[3px] z-20 rounded-lg overflow-hidden"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.3 }}
                        >
                            <motion.img
                                src="https://i.imghippo.com/files/C2XOi1726071368.jpg"
                                alt="AI and DNA visualization"
                                className="w-full h-full object-cover"
                                initial={{ scale: 1.1 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 10, repeat: Infinity, repeatType: 'reverse' }}
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
                        </motion.div>
                        <motion.div
                            className="absolute bottom-0 left-0 p-8 z-30"
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                        >
                            <motion.h2
                                className="text-3xl md:text-3xl font-bold mb-3 text-white"
                                initial={{ x: -20 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                            >
                                Personalized Care Powered by Genomic Insights
                            </motion.h2>
                            <motion.p
                                className="text-lg md:text-xl text-indigo-200"
                                initial={{ x: -20 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.5, delay: 1 }}
                            >

                            </motion.p>
                        </motion.div>
                        <motion.div
                            className="absolute top-4 right-4 bg-indigo-600 text-white px-4 py-2 rounded-full z-30"
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 1.2 }}
                        >
                            Tailored Treatment
                        </motion.div>
                    </motion.div>

                    {/* Introduction section */}
                    <section className="mb-12">
                        <p style={{ fontSize: '21px' }} className="text-lg mb-4 font-light leading-relaxed text-black-900">
                            Precision medicine represents a transformative shift in healthcare, moving away from a “one-size-fits-all” approach to a more personalized strategy. This method focuses on tailoring treatments to individual patients based on their genetic makeup, lifestyle, and environment, thereby improving outcomes and reducing adverse effects. Central to this revolution is bioinformatics, which plays a critical role in interpreting the vast amounts of genomic data necessary to realize personalized medicine.
                        </p>

                        <motion.blockquote
                            className="border-l-4 border-indigo-500 pl-4 py-2 my-8 text-2xl italic font-serif text-black-900"
                            initial={{ x: -50, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            "The future of medicine will be personalized, predictive, and preventive, made possible by breakthroughs in genomics and bioinformatics."
                            <footer className="text-sm mt-2 font-sans text-gray-500">— Francis S. Collins, Former Director of the National Institutes of Health (NIH) and leader of the Human Genome Project</footer>
                        </motion.blockquote>
                    </section>

                    {/* Key Applications section */}
                    <motion.section
                        className="mb-8 bg-gray-50 p-4 sm:p-6 rounded-xl"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        >
                        <h2
                            className="text-xl sm:text-2xl md:text-3xl font-semibold mb-4 text-center sm:text-left"
                            style={{
                            background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            }}
                        >
                            The Genomic Basis of Precision Medicine
                        </h2>
                        <div className="space-y-4 text-base sm:text-lg text-gray-700">
                            <motion.p
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            className="break-words"
                            >
                            Precision medicine is based on the understanding that genetic variations influence how patients respond to drugs. Bioinformaticians analyze genomic data to identify these variations, allowing for more precise and effective treatments.
                            </motion.p>

                            <ul className="space-y-3">
                            <motion.li
                                className="flex items-start break-words"
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.3 }}
                            >
                                <span className="text-indigo-500 mr-2 flex-shrink-0">•</span>
                                <div>
                                <strong className="block sm:inline">Pharmacogenomics: </strong>
                                <span>This field examines how genetic variations affect an individual's drug response. By analyzing variations in drug-metabolizing enzymes (e.g., CYP450), bioinformaticians can predict how a patient will respond to a given drug.</span>
                                </div>
                            </motion.li>

                            <motion.li
                                className="flex items-start break-words"
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.3, delay: 0.1 }}
                            >
                                <span className="text-indigo-500 mr-2 flex-shrink-0">•</span>
                                <div>
                                <strong className="block sm:inline">Polygenic Risk Scores: </strong>
                                <span>These scores quantify an individual's risk of developing a disease by analyzing multiple genetic variants. This data helps clinicians anticipate conditions such as heart disease, cancer, and diabetes with greater accuracy.</span>
                                </div>
                            </motion.li>
                            </ul>
                        </div>
                        </motion.section>

                    {/* The Importance of Genomic Data in Precision Medicine section */}
                    <section className="mb-12">
                        <h2
                            className="text-2xl md:text-3xl font-semibold mb-4"
                            style={{
                                background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            The Importance of Genomic Data in Precision Medicine
                        </h2>

                        <p className="text-lg mb-4 font-light text-gray-700">
                            The cornerstone of precision medicine lies in understanding genetic variations that contribute to disease susceptibility and drug response. The advent of high-throughput sequencing technologies has made it possible to sequence entire genomes rapidly and affordably, resulting in an explosion of genomic data. Analyzing and interpreting this data is where bioinformatics becomes indispensable.
                        </p>

                        <div className="text-lg mb-6 font-light text-gray-700">
                            <p className="mb-2 font-semibold">Key Contributions:</p>
                            <ul className="list-disc list-inside">
                                <li className="mb-2">
                                    <strong>Identification of Genetic Mutations:</strong> Bioinformatics tools help identify genetic mutations associated with diseases, predict how these mutations might affect protein function, and determine their potential impact on a patient’s response to specific treatments.
                                </li>
                                <li>
                                    <strong>Drug Metabolism Insights:</strong> For instance, bioinformatics analyses have shown how single nucleotide polymorphisms (SNPs) in genes encoding cytochrome P450 enzymes can affect drug metabolism, leading to personalized drug dosing recommendations.
                                </li>
                            </ul>
                        </div>

                        {/* Adding an image right after the text */}
                        <motion.div
                            className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.6 }}
                            whileHover={{ scale: 1.02 }}
                        >
                            <img
                                src="https://i.imghippo.com/files/dbNhA1726063179.jpg"
                                alt="The Importance of Genomic Data in Precision Medicine"
                                className="w-full h-auto object-cover rounded-lg"
                            />
                        </motion.div>
                    </section>

                    <section className="mb-12">
                        <h2
                            className="text-2xl md:text-3xl font-semibold mb-4"
                            style={{
                                background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            Challenges in Precision Medicine
                        </h2>
                        <p className="text-lg mb-4 font-light text-gray-700">
                            Precision medicine holds great promise but faces several challenges, particularly in data integration and accessibility.
                        </p>

                        <div className="text-lg mb-6 font-light text-gray-700">
                            <p className="mb-2 font-semibold">Key Challenges:</p>
                            <ul className="list-disc list-inside">
                                <li className="mb-2">
                                    <strong>Data Integration:</strong> Combining various data types—genomic, transcriptomic, proteomic, and clinical—into a unified model for predicting patient outcomes is complex. Bioinformatics plays a crucial role in developing algorithms that manage this integration.
                                </li>
                                <li>
                                    <strong>Electronic Health Records (EHRs):</strong> Integrating genomic data with EHRs is another significant challenge. EHRs contain valuable clinical information, but merging this with genomic data in a meaningful way requires standardization, system interoperability, and privacy safeguards. Bioinformatics must evolve to seamlessly combine EHR and genomic data for personalized treatment.
                                </li>
                            </ul>
                        </div>

                        <motion.div
                            className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ duration: 0.6 }}
                            whileHover={{ scale: 1.02 }}
                        >
                            <img
                                src="https://news.unchealthcare.org/wp-content/uploads/sites/1159/2020/06/program-for-precision-medicine-in-health-care-leads-effort-to-incorporate-genetic-and-genomic-data-in-electronic-health-record-image2.jpeg"
                                alt="Challenges in Precision Medicine"
                                className="w-full h-auto object-cover rounded-lg"
                            />
                        </motion.div>
                    </section>


                    {/* The Future of AI in Genomics section */}
                    <FutureOfAISection />


                    {/* References section */}
                    <motion.section
                        className="mb-12 bg-gray-50 p-6 rounded-xl"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2
                            className="text-2xl md:text-3xl font-semibold mb-4"
                            style={{
                                background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            References
                        </h2>
                        <div className="space-y-4 text-lg text-gray-700">
                            <ul className="list-disc list-inside pl-6">
                                <li>
                                    <a
                                        href="https://sci-hub.se/10.1056/NEJMp1500523"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                    >
                                        Collins, F. S., & Varmus, H. (2015). A New Initiative on Precision Medicine. New England Journal of Medicine, 372(9), 793-795.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://pubmed.ncbi.nlm.nih.gov/22536618/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                        
                                    >
                                        National Research Council (US) Committee on A Framework for Developing a New Taxonomy of Disease. (2011). Toward Precision Medicine: Building a Knowledge Network for Biomedical Research and a New Taxonomy of Disease. National Academies Press.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://pubmed.ncbi.nlm.nih.gov/25928209/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                    >
                                        Ashley, E. A. (2016). The Precision Medicine Initiative: A New National Effort. JAMA, 315(6), 649-650.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://pubmed.ncbi.nlm.nih.gov/11287972/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                    >
                                        Druker, B. J., et al. (2001). Imatinib Mesylate in Chronic Myeloid Leukemia. New England Journal of Medicine, 344, 1031-1037.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://pubmed.ncbi.nlm.nih.gov/11248153/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                    >
                                        Slamon, D. J., et al. (2001). Use of Chemotherapy Plus a Monoclonal Antibody against HER2 for Metastatic Breast Cancer that Overexpresses HER2. New England Journal of Medicine, 344(11), 783-792.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://pubmed.ncbi.nlm.nih.gov/14574440/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-500 hover:text-indigo-700 transition duration-300"
                                    >
                                        Ingelman-Sundberg, M. (2004). Human Drug Metabolising Cytochrome P450 Enzymes: Properties and Polymorphisms. Naunyn-Schmiedeberg's Archives of Pharmacology, 369(1), 89-104.
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </motion.section>

                    {/* Join the Conversation section */}
                    <section className="mb-12">
                        <h2
                            className="text-3xl font-semibold mb-4"
                            style={{
                                background: 'linear-gradient(to right, #3b82f6, #8b5cf6)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            Join the Conversation
                        </h2>
                        <motion.div
                            className="mb-6"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <CommentForm blogId={blogId} />
                        </motion.div>
                        <CommentsList admin={admin} blogId={blogId} />
                    </section>


                    {/* Footer */}
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    className="w-full lg:w-2/6"
                >
                    <SidebarCategories randomCategories={randomCategories} blogPosts={blogPosts} />
                    <PopularPosts randomPopularPosts={randomPopularPosts} />
                </motion.div>
            </div>
            <ScrollToTopButton />

            <Footer />
        </div>
    );
};

export default Blog1;
