import React, { useState, useRef, useEffect } from 'react';
import { ArrowRight, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence, useMotionValue } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from './NewsletterSignup'; 

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      // Show the scroll icon if user scrolled down 300 pixels or more
      if (!showScroll && window.scrollY > 300) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
      onClick={scrollTop}
    >
      <FaChevronUp size={24} />
    </div>
  );
};

// Particle component
const Particle = ({ color }) => {
  const x = useMotionValue(Math.random() * window.innerWidth);
  const y = useMotionValue(Math.random() * window.innerHeight);
  const size = Math.random() * 4 + 1;

  useEffect(() => {
    const intervalId = setInterval(() => {
      x.set(Math.random() * window.innerWidth);
      y.set(Math.random() * window.innerHeight);
    }, Math.random() * 5000 + 2000);

    return () => clearInterval(intervalId);
  }, [x, y]);

  return (
    <motion.div
      className="absolute rounded-full"
      style={{
        x,
        y,
        width: size,
        height: size,
        backgroundColor: color,
        boxShadow: `0 0 ${size * 2}px ${color}`,
      }}
      transition={{ duration: 2, ease: 'easeInOut' }}
    />
  );
};

// ParticleBackground component
const ParticleBackground = () => {
  return (
    <div className="fixed inset-0 z-0">
      {[...Array(100)].map((_, i) => (
        <Particle key={i} color={i % 2 === 0 ? '#8B5CF6' : '#D946EF'} />
      ))}
    </div>
  );
};

// SVGDoubleHelix component
const SVGDoubleHelix = () => {
  return (
    <svg
      viewBox="0 0 800 600"
      className="w-full h-full opacity-20"
      style={{
        position: 'absolute',
        top: '130%', // Adjust this value to move the helix lower
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <DNAStrand color="url(#grad1)" offset={0} />
      <DNAStrand color="url(#grad1)" offset={Math.PI} />
    </svg>
  );
};

const DNAStrand = ({ color, offset }) => {
  const points = 20;
  const ySpread = 80;
  const xSpread = 800;

  return (
    <>
      {[...Array(points)].map((_, i) => {
        const x = (i / (points - 1)) * xSpread;
        const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
        return (
          <g key={i}>
            <motion.circle
              cx={x}
              cy={y}
              r={3}
              fill={color}
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
            {i < points - 1 && (
              <motion.line
                x1={x}
                y1={y}
                x2={(i + 1) / (points - 1) * xSpread}
                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                stroke={color}
                strokeWidth={1}
                initial={{ pathLength: 0 }}
                animate={{ pathLength: [0, 1, 0] }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: i * 0.2,
                }}
              />
            )}
          </g>
        );
      })}
    </>
  );
};

// Footer component
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  // Social media details
  const socialIcons = [
    { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
    { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
    { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
  ];


  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      emailjs.send(
        'service_f0vv34a',  // Your service ID
        'template_x9vg3re', // Your template ID
        {
          to_email: email,  // Dynamically set the recipient's email
          message: 'Thank you for subscribing to our newsletter!'
        },
        'oM0GV2_SQASnm0uWu'    // Your public key
      )
      .then((response) => {
        console.log('Email sent successfully:', response);
        setShowThankYou(true);
        setEmail('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('There was an error subscribing. Please try again later.');
      });
    }
  };

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => setShowThankYou(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const ThankYouAnimation = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
        gravity={0.2}
      />
      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className="text-9xl mb-8"
      >
        🎉
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="text-center"
      >
        <motion.h2
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
          className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
        >
          Thank You!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-3xl text-purple-200 drop-shadow-md"
        >
          You've subscribed to my newsletter!
        </motion.p>
      </motion.div>
    </motion.div>
  );

  return (
    <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and tagline */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="col-span-1 md:col-span-2 lg:col-span-1"
          >
            <div className="flex items-center mb-4">
              {/* Logo */}
              <img
                src="/Y.png"
                alt="Logo"
                className="text-4xl text-purple-400 mr-3"
                style={{ height: '160px' }}  // Larger size for the logo
              />
            </div>
            <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
            >
              <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
            </motion.button>
          </motion.div>

          {/* Quick links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
            <ul className="space-y-2">
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                  About Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                  Projects
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                  Contact Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                  Blog
                </a>
              </motion.li>
            </ul>
          </motion.div>

          {/* Latest Blog */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
            >
              <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
              <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
              <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
            </motion.div>
          </motion.div>

          <NewsletterSignup />
        </div>

        {/* Social Icons */}
        <motion.div
          className="flex justify-center space-x-6 mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          {socialIcons.map(({ Icon, color, name, url }) => (
            <motion.a
              key={name}
              href={url}
              target="_blank" // Opens link in new tab
              rel="noopener noreferrer" // Security for external links
              onMouseEnter={() => setHoveredIcon(name)}
              onMouseLeave={() => setHoveredIcon(null)}
              whileHover={{ y: -5 }}
              className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
            >
              <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
              <AnimatePresence>
                {hoveredIcon === name && (
                  <motion.span
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                  >
                    {name}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.a>
          ))}
        </motion.div>

        {/* DNA Animation */}
        <SVGDoubleHelix />

        {/* Floating Particles */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-purple-500 rounded-full opacity-20"
              style={{
                width: Math.random() * 10 + 5,
                height: Math.random() * 10 + 5,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -30, 0],
                opacity: [0.2, 0.5, 0.2],
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            />
          ))}
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <p>&copy; {currentYear} Yasmine. All rights reserved. |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Privacy Policy Coming Soon!")}
            >
              Privacy Policy
            </button> |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Terms of Service Coming Soon!")}
            >
              Terms of Service
            </button>
          </p>
        </motion.div>
      </div>
      <AnimatePresence>
        {showThankYou && <ThankYouAnimation />}
      </AnimatePresence>
    </footer>
  );
};



// MenuBar component
const MenuBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const linkStyle = {
    color: '#ffffff',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    display: 'inline-block',
    padding: '15px 20px',
    position: 'relative',
    fontWeight: '700',
  };

  const hoverEffectStyle = {
    content: '""',
    background: '#D946EF',
    bottom: 0,
    height: '2px',
    left: '50%',
    position: 'absolute',
    transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
    width: 0,
  };

  const liStyle = {
    display: 'table-cell',
    position: 'relative',
    padding: '15px 0',
  };

  const ulStyle = {
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    display: 'table',
    width: '800px',
    textAlign: 'center',
  };

  const mobileMenuStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '100%',
    backgroundColor: '#1a1a1a',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
  };

  const mobileLinkStyle = {
    ...linkStyle,
    padding: '10px 20px',
  };

  return (
    <>
      {/* Desktop View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="desktop-menu"
      >
        <div
          style={{
            position: 'absolute',
            left: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/Y.png"
            alt="Logo"
            style={{ height: '160px', marginLeft: '50px' }}
            onClick={() => window.location.reload()}
          />
        </div>
        <ul style={ulStyle}>
          {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
            <motion.li
              key={text}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={liStyle}
            >
              <motion.a
                href={`/${text.toLowerCase()}`}
                style={linkStyle}
                whileHover={{ scale: 1.1 }}
                onMouseEnter={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '100%';
                  hoverSpan.style.left = '0';
                }}
                onMouseLeave={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '0';
                  hoverSpan.style.left = '50%';
                }}
              >
                {text}
                <span style={hoverEffectStyle}></span>
              </motion.a>
            </motion.li>
          ))}
        </ul>
      </motion.nav>

      {/* Mobile View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="mobile-menu"
      >
        <div
          style={{ padding: '15px 20px', cursor: 'pointer' }}
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
        </div>
        {isMobileMenuOpen && (
          <motion.ul
            style={mobileMenuStyle}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
              <motion.li
                key={text}
                style={{ padding: '10px 0' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                  {text}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </motion.nav>

      <style>
        {`
                    @media (min-width: 768px) {
                        .mobile-menu {
                            display: none;
                        }
                    }
                    @media (max-width: 767px) {
                        .desktop-menu {
                            display: none;
                        }
                    }
                `}
      </style>
    </>
  );
};

// BlogPage component
const Blog = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const cardsRef = useRef(null);
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
        (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  useEffect(() => {
    const disableInspectMethods = (e) => {
      // Prevent "Inspect Element" via right-click or drag and drop on element
      if (e.type === 'mousedown' && e.button === 2) {
        e.preventDefault();
      }
    };

    document.addEventListener('mousedown', disableInspectMethods);

    return () => {
      document.removeEventListener('mousedown', disableInspectMethods);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Sample data for blog entries
  const blogPosts = [
    {
      title: 'Precision Medicine: Bioinformatics Role in Tailoring Treatments',
      image: 'https://media.istockphoto.com/id/1420177680/vector/biomarker-discovery-for-diagnostic-and-prognostic-or-predictive-medical-purposes-conceptual.jpg?s=612x612&w=0&k=20&c=HTLzvtzVzbIasmK-PM0GKWPzk2rQa004n5suE4YM_I8=', // Image of data flow diagram
      category: 'Clinical Bioinformatics',
      date: 'Aug 3, 2024',
      excerpt: 'How bioinformatics is advancing precision medicine by utilizing genomic data to tailor treatments to individual patients...',
      readTime: '5 min read',
      url: '/blogs/Blog1'
    },
    {
      title: 'Revolutionizing Genomic Data Analysis: The Power of Bioinformatics',
      image: 'https://t3.ftcdn.net/jpg/07/66/13/88/240_F_766138899_xLGGWEhVVqtyrWUyqDIHTuBhJjmA2cas.jpg', // Image of DNA sequencing visualization
      category: 'Genomics',
      date: 'Jul 25, 2024',
      excerpt: 'Exploring the role of bioinformatics in unlocking the complexities of genomic data for healthcare breakthroughs...',
      readTime: '5 min read',
      url: '/blogs/Blog2'
    },
    {
      title: 'Protein Structure Prediction with AlphaFold: A New Era in Bioinformatics',
      image: 'https://assets.technologynetworks.com/production/dynamic/images/content/378804/alphafold-analyzes-millions-of-predicted-protein-structures-378804-1280x720.webp?cb=12466579', // Image of complex network graphs
      category: 'Proteomics',
      date: 'Jul 17, 2024',
      excerpt: 'How AlphaFold is reshaping protein structure prediction and its impact on bioinformatics...',
      readTime: '7 min read',
      url: '/blogs/Blog3'
    },
    {
      title: 'Unveiling Cancer Genomics: The Role of Bioinformatics in Cancer Research',
      image: 'https://media.istockphoto.com/id/2151333984/photo/cancer-cell-view.jpg?s=612x612&w=0&k=20&c=AFjXl5V9MvSBqstZH4H0ojzS_dC9uCfJ_8XTOxmPPCs=', // Image of 3D protein structure
      category: 'Cancer Bioinformatics',
      date: 'Jul 10, 2024',
      excerpt: 'How bioinformatics is advancing our understanding of cancer genomics and paving the way for new treatments...',
      readTime: '8 min read',
      url: '/blogs/Blog4'
    },
    // {
    //     title: 'The Impact of AI in Bioinformatics',
    //     image: 'https://picsum.photos/seed/bioai/800/600', // Image of AI-assisted molecular modeling
    //     category: 'AI & Machine Learning',
    //     date: 'May 5, 2024',
    //     excerpt: 'Exploring how artificial intelligence is revolutionizing bioinformatics research and applications...',
    //     readTime: '5 min read'
    // },
    // {
    //     title: 'Data Visualization in Bioinformatics',
    //     image: 'https://picsum.photos/seed/biovis/800/600', // Image of complex biological data visualization
    //     category: 'Data Visualization',
    //     date: 'May 2, 2024',
    //     excerpt: 'Best practices for creating clear and informative visualizations of complex biological data...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Metagenomics: A New Frontier',
    //     image: 'https://picsum.photos/seed/metagenomics/800/600', // Image of microbial communities
    //     category: 'Genomics',
    //     date: 'April 30, 2024',
    //     excerpt: 'Discover the potential of metagenomics in understanding complex microbial ecosystems...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Big Data Challenges in Bioinformatics',
    //     image: 'https://picsum.photos/seed/bigdata/800/600', // Image of big data cloud and bioinformatics symbols
    //     category: 'Data Processing',
    //     date: 'April 27, 2024',
    //     excerpt: 'Addressing the challenges and opportunities presented by big data in bioinformatics...',
    //     readTime: '5min read'
    // },
    // {
    //     title: 'Single-Cell RNA Sequencing Techniques',
    //     image: 'https://picsum.photos/seed/scrna/800/600', // Image of single-cell RNA sequencing data
    //     category: 'Genomics',
    //     date: 'April 25, 2024',
    //     excerpt: 'An overview of the latest single-cell RNA sequencing techniques and their applications...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Machine Learning in Drug Discovery',
    //     image: 'https://picsum.photos/seed/drugml/800/600', // Image of AI-driven drug discovery
    //     category: 'AI & Machine Learning',
    //     date: 'April 22, 2024',
    //     excerpt: 'How machine learning is accelerating drug discovery processes in bioinformatics...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Genetic Variants and Personalized Medicine',
    //     image: 'https://picsum.photos/seed/personalmed/800/600', // Image of personalized medicine concept
    //     category: 'Genomics',
    //     date: 'April 20, 2024',
    //     excerpt: 'Understanding the role of genetic variants in the development of personalized medicine...',
    //     readTime: '5 min read'
    // },
    // {
    //     title: 'CRISPR and Genome Editing Innovations',
    //     image: 'https://picsum.photos/seed/crispr/800/600', // Image of CRISPR technology visualization
    //     category: 'Genomics',
    //     date: 'April 18, 2024',
    //     excerpt: 'Exploring the latest innovations in CRISPR and its impact on genome editing...',
    //     readTime: '5min read'
    // },
    // {
    //     title: 'Bioinformatics for Cancer Genomics',
    //     image: 'https://picsum.photos/seed/cancergenomics/800/600', // Image of cancer cells and genomic data
    //     category: 'Genomics',
    //     date: 'April 15, 2024',
    //     excerpt: 'How bioinformatics is driving advancements in cancer genomics and personalized oncology...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Epigenomics: Beyond the Genome',
    //     image: 'https://picsum.photos/seed/epigenomics/800/600', // Image of epigenetic modifications
    //     category: 'Genomics',
    //     date: 'April 12, 2024',
    //     excerpt: 'An introduction to epigenomics and its role in understanding gene expression...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'The Power of Transcriptomics',
    //     image: 'https://picsum.photos/seed/transcriptomics/800/600', // Image of transcriptomic data visualization
    //     category: 'Genomics',
    //     date: 'April 10, 2024',
    //     excerpt: 'Unlocking the secrets of gene expression with advanced transcriptomic analysis...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Harnessing the Potential of Synthetic Biology',
    //     image: 'https://picsum.photos/seed/syntheticbio/800/600', // Image of synthetic biology structures
    //     category: 'Synthetic Biology',
    //     date: 'April 8, 2024',
    //     excerpt: 'The latest advancements in synthetic biology and their applications in bioinformatics...',
    //     readTime: '5min read'
    // },
    // {
    //     title: 'Network Biology: Connecting the Dots',
    //     image: 'https://picsum.photos/seed/networkbio/800/600', // Image of biological networks
    //     category: 'Systems Biology',
    //     date: 'April 5, 2024',
    //     excerpt: 'Understanding complex biological systems through network biology and bioinformatics...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Proteomics: A Deeper Dive into Proteins',
    //     image: 'https://picsum.photos/seed/proteomics/800/600', // Image of proteomic data
    //     category: 'Proteomics',
    //     date: 'April 3, 2024',
    //     excerpt: 'Exploring the proteome with cutting-edge proteomics techniques and tools...',
    //     readTime: '5 min read'
    // },
    // {
    //     title: 'Integrative Omics: Unifying Biological Data',
    //     image: 'https://picsum.photos/seed/integrativeomics/800/600', // Image of integrative omics data
    //     category: 'Multi-Omics',
    //     date: 'April 1, 2024',
    //     excerpt: 'The role of integrative omics in creating a holistic view of biological systems...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Clinical Bioinformatics: Bridging Research and Healthcare',
    //     image: 'https://picsum.photos/seed/clinicalbio/800/600', // Image of clinical data in bioinformatics
    //     category: 'Clinical Bioinformatics',
    //     date: 'March 29, 2024',
    //     excerpt: 'How clinical bioinformatics is transforming patient care and medical research...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Evolutionary Biology and Bioinformatics',
    //     image: 'https://picsum.photos/seed/evolutionbio/800/600', // Image of evolutionary trees
    //     category: 'Evolutionary Biology',
    //     date: 'March 27, 2024',
    //     excerpt: 'Exploring the intersection of evolutionary biology and bioinformatics...',
    //     readTime: '5min read'
    // },
    // {
    //     title: 'Data Integration in Bioinformatics',
    //     image: 'https://picsum.photos/seed/dataintegration/800/600', // Image of data integration in bioinformatics
    //     category: 'Data Processing',
    //     date: 'March 25, 2024',
    //     excerpt: 'Strategies for integrating diverse biological data sources in bioinformatics...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Population Genomics: Insights from Big Data',
    //     image: 'https://picsum.photos/seed/populationgenomics/800/600', // Image of population genomics data
    //     category: 'Genomics',
    //     date: 'March 23, 2024',
    //     excerpt: 'Understanding human diversity through population genomics and bioinformatics...',
    //     readTime: '6 min read'
    // },
    // {
    //     title: 'Bioinformatics in Agricultural Genomics',
    //     image: 'https://picsum.photos/seed/aggenomics/800/600', // Image of agricultural crops and genomic data
    //     category: 'Agrigenomics',
    //     date: 'March 20, 2024',
    //     excerpt: 'The role of bioinformatics in improving crop yield and agricultural sustainability...',
    //     readTime: '7 min read'
    // },
    // {
    //     title: 'Gene Expression Analysis Tools and Techniques',
    //     image: 'https://picsum.photos/seed/geneexpression/800/600', // Image of gene expression data visualization
    //     category: 'Genomics',
    //     date: 'March 18, 2024',
    //     excerpt: 'A review of the latest tools and techniques for gene expression analysis...',
    //     readTime: '5min read'
    // },
    // {
    //     title: 'Understanding Microbiomes through Bioinformatics',
    //     image: 'https://picsum.photos/seed/microbiomes/800/600', // Image of microbiome data
    //     category: 'Microbiome',
    //     date: 'March 15, 2024',
    //     excerpt: 'Exploring the complex world of microbiomes using bioinformatics...',
    //     readTime: '6 min read'
    // }
  ];

  const categories = ['All', ...new Set(blogPosts.map(post => post.category))];

  const filteredPosts = blogPosts.filter(post =>
    (activeCategory === 'All' || post.category === activeCategory) &&
    (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.excerpt.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        fontFamily: "'Poppins', sans-serif",
        minHeight: '100vh',
        position: 'relative',
        zIndex: 1,
        color: '#fff',
        backgroundColor: '#000',
      }}
    >
      {/* Background image */}
      <ParticleBackground />
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('https://soleytherapeutics.com/wp-content/uploads/2023/05/Mask-Group-72.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1,
        opacity: 0.2,
      }}></div>

      <div style={{ position: 'relative', zIndex: 3 }}>
        <MenuBar />

        <div style={{ padding: '40px 20px', maxWidth: '1200px', margin: '0 auto' }}>
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{ textAlign: 'center', marginBottom: '40px' }}
          >
            <h1 style={{ fontSize: '48px', color: '#D946EF', fontWeight: 'bold', marginBottom: '20px' }}>
              Discover My Latest Insights
            </h1>
            <p style={{ fontSize: '18px', color: '#E9D5FF', maxWidth: '800px', margin: '0 auto' }}>
              Dive into a world of web design, development, and digital innovation. Our blog brings you cutting-edge insights and practical tips to elevate your digital presence.
            </p>
          </motion.div>

          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{ marginBottom: '30px' }}
          >
            <input
              type="text"
              placeholder="Search blog posts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 20px',
                borderRadius: '25px',
                border: '2px solid #8B5CF6',
                backgroundColor: 'rgba(139, 92, 246, 0.1)',
                color: '#fff',
                fontSize: '16px',
                outline: 'none',
              }}
            />
          </motion.div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', marginBottom: '40px' }}
          >
            {categories.map((category) => (
              <motion.button
                key={category}
                onClick={() => setActiveCategory(category)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  padding: '8px 16px',
                  borderRadius: '20px',
                  backgroundColor: activeCategory === category ? '#D946EF' : 'transparent',
                  color: activeCategory === category ? '#000' : '#E9D5FF',
                  border: `2px solid ${activeCategory === category ? '#D946EF' : '#E9D5FF'}`,
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                }}
              >
                {category}
              </motion.button>
            ))}
          </motion.div>

          <motion.div
            ref={cardsRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: '30px',
            }}
          >
            <AnimatePresence>
              {filteredPosts.map((post, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ scale: 1.05, boxShadow: '0 20px 40px rgba(139, 92, 246, 0.3)' }}
                  style={{
                    backgroundColor: 'rgba(139, 92, 246, 0.3)',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    boxShadow: '0 10px 30px rgba(139, 92, 246, 0.2)',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.location.href = post.url} // Make the entire card clickable
                >
                  <div style={{
                    height: '250px',
                    backgroundImage: `url(${post.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}></div>
                  <div style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <span style={{ color: '#D946EF', fontSize: '14px' }}>{post.category}</span>
                      <span style={{ color: '#A78BFA', fontSize: '14px' }}>{post.date}</span>
                    </div>
                    <h2 style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '10px', color: '#E9D5FF' }}>{post.title}</h2>
                    <p style={{ fontSize: '16px', color: '#C4B5FD', marginBottom: '15px' }}>{post.excerpt}</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span style={{ color: '#A78BFA', fontSize: '14px' }}>{post.readTime}</span>
                      <motion.a
                        href={post.url} // Use the url field here
                        style={{ color: '#D946EF', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '16px' }}
                        whileHover={{ x: 5 }}
                        onClick={(e) => e.stopPropagation()} // Prevents the onClick from being triggered twice
                      >
                        Read More <ArrowRight style={{ marginLeft: '5px' }} size={16} />
                      </motion.a>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>

        </div>
        <ScrollToTopButton />

        <Footer />
      </div>

    </motion.div>
  );
};

export default Blog;
