import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDocs } from "firebase/firestore";
import Comment from './Comment';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, Loader } from 'lucide-react';

const CommentsList = ({ blogId, admin }) => { // Accept blogId as a prop
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const commentsQuery = query(collection(db, "blogs", blogId, "comments"), orderBy("timestamp", "desc"));
    
    const unsubscribe = onSnapshot(commentsQuery, async (snapshot) => {
      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const commentData = doc.data();
          
          const repliesQuery = query(
            collection(db, "blogs", blogId, "comments", doc.id, "replies"),
            orderBy("timestamp", "asc")
          );
          
          const repliesSnapshot = await getDocs(repliesQuery);
          const replies = repliesSnapshot.docs.map(replyDoc => ({
            id: replyDoc.id,
            ...replyDoc.data()
          }));
          
          return {
            id: doc.id,
            ...commentData,
            replies: replies || []
          };
        })
      );
      
      setComments(fetchedComments);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [blogId]);

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex flex-col items-center justify-center h-64"
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          className="mb-4"
        >
          <Loader size={48} className="text-purple-500" />
        </motion.div>
        <p className="text-purple-700 font-semibold text-lg">Loading comments...</p>
      </motion.div>
    );
  }

  return (
    <AnimatePresence>
      {comments.length === 0 ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className="flex flex-col items-center justify-center h-64"
        >
          <Sparkles size={48} className="text-purple-500 mb-4" />
          <p className="text-purple-700 font-semibold text-lg">
            No comments yet. Be the first to start the conversation!
          </p>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="space-y-6"
        >
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} admin={admin} blogId={blogId} /> // Pass blogId
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CommentsList;
