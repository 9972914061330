import React, { useEffect, useRef, useState } from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import { DNABlock } from './DNAAnimation'; // Import the DNA animation component
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, ArrowUpRight, Mail, ExternalLink, Clock, Tag, Linkedin, Github } from 'lucide-react';
import { useAnimation } from 'framer-motion';
import { Send, User, MessageSquare } from 'lucide-react';
import { MapPin, Phone, Instagram, Twitter, Facebook } from 'lucide-react';
import { Link } from 'react-router-dom'
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from './NewsletterSignup'; 

const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      // Show the scroll icon if user scrolled down 300 pixels or more
      if (!showScroll && window.scrollY > 300) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
      onClick={scrollTop}
    >
      <FaChevronUp size={24} />
    </div>
  );
};

const SubSection = () => {
  const particlesContainerRef = useRef(null);
  const [imageOffset, setImageOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setImageOffset(scrollY * -1);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (particlesContainerRef.current) {
      window.particlesJS(particlesContainerRef.current.id, {
        particles: {
          number: { value: 300, density: { enable: true, value_area: 4000 } },
          color: { value: '#ffffff' },
          shape: {
            type: 'circle',
            stroke: { width: 0, color: '#000000' },
            polygon: { nb_sides: 5 },
            image: { src: 'img/github.svg', width: 100, height: 100 }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 6,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: '#ffffff',
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 6,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: { enable: true, mode: 'grab' },
            onclick: { enable: true, mode: 'push' },
            resize: true
          },
          modes: {
            grab: { distance: 140, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    }
  }, []);

  return (
    <div
      className="relative overflow-hidden"
      style={{
        height: '260vh',
        paddingTop: '10px',
        paddingBottom: '0px',
        position: 'relative',
        overflow: 'hidden',
        background: 'linear-gradient(180deg, rgba(48, 16, 104, 0.95), black, rgba(48, 16, 104, 0.95))',
      }}
    >
      <MenuBar />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="absolute inset-0 z-0 bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://tacittx.com/wp-content/uploads/2023/08/Tacit-Homepage-Background.png")',
          filter: 'brightness(1.20)',
          height: '200%',
          minHeight: '100vh',
          width: '130%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transform: `translateY(${imageOffset}px)`,
        }}
      ></motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.2 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 z-0"
        style={{
          background: 'linear-gradient(180deg, rgba(48, 16, 104, 0.95), black, rgba(48, 16, 104, 0.95))',
        }}
      ></motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 0.5 }}
        id="particles-js"
        ref={particlesContainerRef}
        className="absolute inset-0 z-10"
      ></motion.div>

      <div
        className="relative z-20 max-w-3xl mx-auto"
        style={{
          marginTop: '-150px',
        }}
      >
        <RNARepairInfo />
      </div>

      <div
        className="absolute z-20 w-[90vw] sm:w-[70vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw]"
        style={{
          bottom: '10%',
          right: '5%',
          maxWidth: '500px',
          aspectRatio: '1 / 1',
        }}
      >
        <SciencePromo
          text="Bioinformatics: Shaping the Future"
          subtext="Building tools and algorithms to better understand complex biological data, while I continue to learn and grow in bioinformatics."
        />
      </div>
    </div>
  );
};
// MenuBar Component
const MenuBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const linkStyle = {
    color: '#ffffff',
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '0.15em',
    display: 'inline-block',
    padding: '15px 20px',
    position: 'relative',
    fontWeight: '700',
  };

  const hoverEffectStyle = {
    content: '""',
    background: '#D946EF',
    bottom: 0,
    height: '2px',
    left: '50%',
    position: 'absolute',
    transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
    width: 0,
  };

  const liStyle = {
    display: 'table-cell',
    position: 'relative',
    padding: '15px 0',
  };

  const ulStyle = {
    margin: '0 auto',
    padding: 0,
    listStyle: 'none',
    display: 'table',
    width: '800px',
    textAlign: 'center',
  };

  const mobileMenuStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    width: '100%',
    backgroundColor: '#000',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
  };

  const mobileLinkStyle = {
    ...linkStyle,
    padding: '10px 20px',
  };

  return (
    <>
      {/* Desktop View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="desktop-menu"
      >
        <div
          style={{
            position: 'absolute',
            left: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/Y.png"
            alt="Logo"
            style={{ height: '160px', marginLeft: '50px' }}
            onClick={() => window.location.reload()}
          />
        </div>
        <ul style={ulStyle}>
          {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
            <motion.li
              key={text}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              style={liStyle}
            >
              <motion.a
                href={`/${text.toLowerCase()}`}
                style={linkStyle}
                whileHover={{ scale: 1.1 }}
                onMouseEnter={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '100%';
                  hoverSpan.style.left = '0';
                }}
                onMouseLeave={(e) => {
                  const hoverSpan = e.currentTarget.querySelector('span');
                  hoverSpan.style.width = '0';
                  hoverSpan.style.left = '50%';
                }}
              >
                {text}
                <span style={hoverEffectStyle}></span>
              </motion.a>
            </motion.li>
          ))}
        </ul>
      </motion.nav>

      {/* Mobile View */}
      <motion.nav
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          position: 'relative',
          top: '10px',
          left: 0,
          width: '100%',
          zIndex: 50,
          backgroundColor: 'transparent',
        }}
        className="mobile-menu"
      >
        <div
          style={{ padding: '15px 20px', cursor: 'pointer' }}
          onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
        >
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
          <div style={{ width: '30px', height: '3px', backgroundColor: '#ffffff', margin: '6px 0' }}></div>
        </div>
        {isMobileMenuOpen && (
          <motion.ul
            style={mobileMenuStyle}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
              <motion.li
                key={text}
                style={{ padding: '10px 0' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                  {text}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </motion.nav>

      <style>
        {`
                  @media (min-width: 768px) {
                      .mobile-menu {
                          display: none;
                      }
                  }
                  @media (max-width: 767px) {
                      .desktop-menu {
                          display: none;
                      }
                  }
              `}
      </style>
    </>
  );
};

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);

  // Social media details
  const socialIcons = [
    { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
    { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
    { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
  ];


  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      emailjs.send(
        'service_f0vv34a',  // Your service ID
        'template_x9vg3re', // Your template ID
        {
          to_email: email,  // Dynamically set the recipient's email
          message: 'Thank you for subscribing to our newsletter!'
        },
        'oM0GV2_SQASnm0uWu'    // Your public key
      )
      .then((response) => {
        console.log('Email sent successfully:', response);
        setShowThankYou(true);
        setEmail('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('There was an error subscribing. Please try again later.');
      });
    }
  };

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => setShowThankYou(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  const ThankYouAnimation = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={500}
        gravity={0.2}
      />
      <motion.div
        initial={{ scale: 0, rotate: -180 }}
        animate={{ scale: 1, rotate: 0 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        className="text-9xl mb-8"
      >
        🎉
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="text-center"
      >
        <motion.h2
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
          className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
        >
          Thank You!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-3xl text-purple-200 drop-shadow-md"
        >
          You've subscribed to my newsletter!
        </motion.p>
      </motion.div>
    </motion.div>
  );

  return (
    <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and tagline */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="col-span-1 md:col-span-2 lg:col-span-1"
          >
            <div className="flex items-center mb-4">
              {/* Logo */}
              <img
                src="/Y.png"
                alt="Logo"
                className="text-4xl text-purple-400 mr-3"
                style={{ height: '160px' }}  // Larger size for the logo
              />
            </div>
            <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
            >
              <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
            </motion.button>
          </motion.div>

          {/* Quick links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
            <ul className="space-y-2">
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                  About Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                  Projects
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                  Contact Me
                </a>
              </motion.li>
              <motion.li
                whileHover={{ x: 5, color: '#D1D5DB' }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                  Blog
                </a>
              </motion.li>
            </ul>
          </motion.div>

          {/* Latest Blog */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
            >
              <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
              <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
              <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
            </motion.div>
          </motion.div>

          <NewsletterSignup />
        </div>

        {/* Social Icons */}
        <motion.div
          className="flex justify-center space-x-6 mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          {socialIcons.map(({ Icon, color, name, url }) => (
            <motion.a
              key={name}
              href={url}
              target="_blank" // Opens link in new tab
              rel="noopener noreferrer" // Security for external links
              onMouseEnter={() => setHoveredIcon(name)}
              onMouseLeave={() => setHoveredIcon(null)}
              whileHover={{ y: -5 }}
              className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
            >
              <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
              <AnimatePresence>
                {hoveredIcon === name && (
                  <motion.span
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                  >
                    {name}
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.a>
          ))}
        </motion.div>

        {/* DNA Animation */}
        <SVGDoubleHelix />

        {/* Floating Particles */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute bg-purple-500 rounded-full opacity-20"
              style={{
                width: Math.random() * 10 + 5,
                height: Math.random() * 10 + 5,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
              }}
              animate={{
                y: [0, -30, 0],
                opacity: [0.2, 0.5, 0.2],
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                ease: 'easeInOut',
              }}
            />
          ))}
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <p>&copy; {currentYear} Yasmine. All rights reserved. |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Privacy Policy Coming Soon!")}
            >
              Privacy Policy
            </button> |
            <button
              className="ml-2 hover:text-purple-200 transition duration-300"
              onClick={() => alert("Terms of Service Coming Soon!")}
            >
              Terms of Service
            </button>
          </p>
        </motion.div>
      </div>
      <AnimatePresence>
        {showThankYou && <ThankYouAnimation />}
      </AnimatePresence>
    </footer>
  );
};

// RNARepairInfo Component
const RNARepairInfo = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.5 }}
      className="text-white min-h-screen p-4 sm:p-8"
      style={{ backgroundColor: 'transparent', paddingTop: '270px' }}
    >
      <div className="max-w-3xl mx-auto sm:ml-[-180px]">
        <motion.h2
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.7 }}
          className="text-blue-300 text-xl sm:text-2xl mb-4"
          style={{ color: '#E9D5FF' }}
        >
          Unlocking the Secrets of Genetic Data
        </motion.h2>
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.9 }}
          className="text-4xl sm:text-9xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 animate-gradient-x"
        >
          Yasmine's<br />
          Portfolio
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 1.1 }}
          className="pl-4"
          style={{
            borderLeft: '4px solid',
            borderImage: 'linear-gradient(to bottom, purple, pink) 1',
          }}
        >
          <p className="text-base sm:text-xl" style={{ color: '#E9D5FF' }}>
            My work applies computational methods to analyze genomic data, with a focus on cancer research.
            By leveraging bioinformatics, it uncovers patterns to advance cancer diagnostics and precision medicine, driving innovations in healthcare.
            These insights contribute to more effective treatments and a deeper understanding of cancer biology.
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

const SciencePromo = ({ text, subtext }) => {
  return (
    <motion.div
      className="relative w-full h-full flex items-center justify-center"
      style={{
        width: '100%',
        height: '100%',
        maxWidth: '500px',
        maxHeight: '500px',
        aspectRatio: '1 / 1',
      }}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="z-10 rounded-full p-4 sm:p-6 md:p-8 text-white text-center flex flex-col items-center justify-center"
        style={{
          width: '100%',
          height: '100%',
          opacity: 0.93,
          background: 'linear-gradient(135deg, #6D28D9, #9333EA, #F472B6)',
        }}
        whileHover={{ scale: 1.05 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <motion.h2 
          className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-2 sm:mb-3 md:mb-4"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {text}
        </motion.h2>
        <motion.p 
          className="text-xs sm:text-sm md:text-base lg:text-lg mb-3 sm:mb-4 md:mb-5"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {subtext}
        </motion.p>
        <motion.button
          whileHover={{
            scale: 1.1,
            backgroundColor: '#6D28D9',
            color: '#ffffff',
            boxShadow: '0px 10px 20px rgba(109, 40, 217, 0.5)',
          }}
          whileTap={{ scale: 0.95 }}
          className="bg-white text-purple-600 font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full shadow-lg transition duration-300 ease-in-out text-xs sm:text-sm md:text-base"
        >
          <Link to="/Portfolio" className="no-underline text-purple-600">
          Discover My Work
        </Link>
          <motion.span
            className="ml-2 inline-block"
            animate={{ x: [0, 5, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            &#8594;
          </motion.span>
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

const SVGDoubleHelix = () => {
  return (
    <svg
      viewBox="0 0 800 600"
      className="w-full h-full opacity-20"
      style={{
        position: 'absolute',
        top: '130%',// Adjust this value to move the helix lower
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <DNAStrand color="url(#grad1)" offset={0} />
      <DNAStrand color="url(#grad1)" offset={Math.PI} />
    </svg>
  );
};

const DNAStrand = ({ color, offset }) => {
  const points = 20;
  const ySpread = 80;
  const xSpread = 800;

  return (
    <>
      {[...Array(points)].map((_, i) => {
        const x = (i / (points - 1)) * xSpread;
        const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
        return (
          <g key={i}>
            <motion.circle
              cx={x}
              cy={y}
              r={3}
              fill={color}
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
            {i < points - 1 && (
              <motion.line
                x1={x}
                y1={y}
                x2={(i + 1) / (points - 1) * xSpread}
                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                stroke={color}
                strokeWidth={1}
                initial={{ pathLength: 0 }}
                animate={{ pathLength: [0, 1, 0] }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: i * 0.2,
                }}
              />
            )}
          </g>
        );
      })}
    </>
  );
};

const projects = [
  {
    title: 'Assessing Anemia in Pregnant and Non-Pregnant Women',
    description: 'A project examines the prevalence of anemia in pregnant and non-pregnant women using clinical data and bioinformatics analysis. This study helps identify key biomarkers and trends across different demographics. ',
    image: 'https://img.freepik.com/free-photo/3d-render-blood-cells-abstract-background_1048-6338.jpg?size=626&ext=jpg&ga=GA1.2.487250843.1725002202&semt=ais_hybrid', // Replace with actual project image
    url: '/projects/Project1',
    technologies: ['Python', 'WHO']
  },
  {
    title: 'Exploring Protein Folding with AlphaFold',
    description: 'A project investigates protein folding mechanisms using AlphaFold to predict and analyze protein structures. The insights gained can improve our understanding of disease-causing protein misfolding. This project leverages AI to model complex proteins.',
    image: 'https://t3.ftcdn.net/jpg/08/32/93/74/240_F_832937483_yubrxQBohjjh5XCFarDKaqizJh05E0Gi.jpg', // Replace with actual project image
    url: '/projects/Project3',
    technologies: ['Python', 'AlphaFold', 'AI', 'ChimeraX']
  },
  {
    title: 'Comparative Genomic Analysis Identifying and Characterizing (SNPs) in DNA Sequences',
    description: 'A project conducts a comparative genomic analysis to identify and characterize single nucleotide polymorphisms (SNPs) in DNA sequences.',
    technologies: ['NCBI', 'Python'],
    image: 'https://i.ytimg.com/vi/x4m1_P5Ljf8/maxresdefault.jpg',
    url: '/projects/Project2'
  },
];

const blogPosts = [
  {
    title: 'Revolutionizing Genomic Data Analysis: The Power of Bioinformatics',
    image: 'https://t3.ftcdn.net/jpg/07/66/13/88/240_F_766138899_xLGGWEhVVqtyrWUyqDIHTuBhJjmA2cas.jpg', // Image of DNA sequencing visualization
    category: 'Genomics', // Example category
    readTime: '5', // Example read time
    tags: ['Genomics', 'Data Analysis'], // Ensure this array is defined
    excerpt: 'Exploring the role of bioinformatics in unlocking the complexities of genomic data for healthcare breakthroughs...',
    url: '/blogs/Blog2'
  },
  {
    title: 'Precision Medicine: Bioinformatics Role in Tailoring Treatments',
    image: 'https://media.istockphoto.com/id/1420177680/vector/biomarker-discovery-for-diagnostic-and-prognostic-or-predictive-medical-purposes-conceptual.jpg?s=612x612&w=0&k=20&c=HTLzvtzVzbIasmK-PM0GKWPzk2rQa004n5suE4YM_I8=', // Image of data flow diagram
    category: 'Clinical Bioinformatics',
    date: 'Aug 3, 2024',
    excerpt: 'How bioinformatics is advancing precision medicine by utilizing genomic data to tailor treatments to individual patients...',
    readTime: '5',
    tags: ['Medication', 'Genomics'], // Ensure this array is defined
    url: 'blogs/Blog1',
  },
  {
    title: 'Unveiling Cancer Genomics: The Role of Bioinformatics in Cancer Research',
    image: 'https://media.istockphoto.com/id/2151333984/photo/cancer-cell-view.jpg?s=612x612&w=0&k=20&c=AFjXl5V9MvSBqstZH4H0ojzS_dC9uCfJ_8XTOxmPPCs=', // Image of 3D protein structure
    category: 'Cancer Bioinformatics',
    date: 'Jul 10, 2024',
    excerpt: 'How bioinformatics is advancing our understanding of cancer genomics and paving the way for new treatments...',
    readTime: '8',
    tags: ['Oncology', 'Bioinformatics'], // Ensure this array is defined
    url: '/blogs/Blog4'
  },
];

const InsightsSection = ({ blogPosts }) => {
  return (
    <section className="py-12 px-4 bg-white relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="text-7xl font-extrabold mb-20 text-center relative z-10"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
            Insights & Explore
          </span>
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          {blogPosts.map((post, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
            >
              <motion.div
                className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-2xl opacity-0 group-hover:opacity-100 transition duration-300 blur-sm"
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
              />
              <motion.div
                className="relative bg-white rounded-2xl overflow-hidden shadow-lg transition-all duration-300 ease-in-out transform group-hover:-translate-y-2 group-hover:shadow-2xl"
              >
                <div className="h-56 bg-gray-200 relative overflow-hidden">
                  <motion.img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.4 }}
                  />
                  <motion.div
                    className="absolute top-0 left-0 bg-gradient-to-r from-blue-500 to-pink-500 text-white text-xs font-bold px-4 py-2 rounded-br-lg"
                    initial={{ x: -100 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    {post.category}
                  </motion.div>
                </div>
                <div className="p-8">
                  <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" style={{ fontFamily: "'Montserrat', sans-serif" }}>{post.title}</h3>
                  <p className="text-gray-600 mb-6" style={{ fontFamily: "'Lora', serif" }}>{post.excerpt}</p>
                  <div className="flex justify-between items-center text-sm text-gray-500 mb-6">
                    <div className="flex items-center">
                      <Clock size={16} className="mr-2 text-purple-500" />
                      <span style={{ fontFamily: "'Roboto', sans-serif" }}>{post.readTime} min read</span>
                    </div>
                    <div className="flex items-center">
                      <Tag size={16} className="mr-2 text-pink-500" />
                      <span style={{ fontFamily: "'Roboto', sans-serif" }}>{post.tags.join(', ')}</span>
                    </div>
                  </div>
                  <motion.a
                    href={post.url}
                    className="inline-block bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Read Full Article
                    <ExternalLink size={18} className="inline-block ml-2" />
                  </motion.a>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          className="mt-16 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <motion.button
            className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white text-xl font-bold py-4 px-8 rounded-full shadow-lg transition-all duration-300"
            whileHover={{ scale: 1.05, boxShadow: "0 15px 30px rgba(0,0,0,0.2)" }}
            whileTap={{ scale: 0.95 }}
            href="/Blog"
          >
            Explore More Blogs
            <ChevronRight size={24} className="inline-block ml-2" />
          </motion.button>
        </motion.div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-blue-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute bottom-1/4 left-1/2 w-96 h-96 bg-pink-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>
    </section>
  );
};

const ProjectsSection = ({ projects }) => {
  const [hoveredProject, setHoveredProject] = useState(null);

  return (
    <section className="py-12 px-4 bg-white relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="text-7xl font-extrabold mb-20 text-center relative z-10"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
            Visionary Projects
          </span>
        </motion.h2>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="relative group"
              onMouseEnter={() => setHoveredProject(index)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              <motion.div
                className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-2xl opacity-0 group-hover:opacity-100 transition duration-300 blur-sm"
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
              />
              <motion.div
                className="relative bg-white rounded-2xl overflow-hidden shadow-lg transition-all duration-300 ease-in-out transform group-hover:-translate-y-2 group-hover:shadow-2xl"
              >
                <div className="h-56 bg-gray-200 relative overflow-hidden">
                  <motion.img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.4 }}
                  />
                </div>
                <div className="p-8">
                  <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" style={{ fontFamily: "'Montserrat', sans-serif" }}>{project.title}</h3>
                  <p className="text-gray-600 mb-6" style={{ fontFamily: "'Lora', serif" }}>{project.description}</p>
                  <div className="flex flex-wrap gap-2 mb-6">
                    {project.technologies.map((tech, techIndex) => (
                      <span key={techIndex} className="bg-gray-100 text-gray-800 text-xs font-medium px-2 py-1 rounded-full">
                        {tech}
                      </span>
                    ))}
                  </div>
                  <motion.a
                    href={project.url}
                    className="inline-block bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white font-bold py-3 px-6 rounded-full transition-all duration-300"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Explore Project
                    <ArrowUpRight size={18} className="inline-block ml-2" />
                  </motion.a>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          className="mt-16 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <motion.a
            href="/Portfolio"
            className="inline-block bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white text-xl font-bold py-4 px-8 rounded-full shadow-lg transition-all duration-300"
            whileHover={{ scale: 1.05, boxShadow: "0 15px 30px rgba(0,0,0,0.2)" }}
            whileTap={{ scale: 0.95 }}
          >
            Discover More Projects
            <ChevronRight size={24} className="inline-block ml-2" />
          </motion.a>
        </motion.div>
      </div>

      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-blue-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/3 right-1/4 w-96 h-96 bg-purple-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute bottom-1/4 left-1/2 w-96 h-96 bg-pink-300 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>
    </section>
  );
};


const InteractiveContactSection = () => {

  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { name, email, message } = formData;

    // Construct the mailto link
    const subject = encodeURIComponent(`New Message from ${name}`);
    const body = encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\nMessage: ${message}`);
    const mailtoLink = `mailto:elnashartyasmine@gmail.com?subject=${subject}&body=${body}`;

    // Open the mailto link
    window.location.href = mailtoLink;

    setIsSubmitting(false);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  const formVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 200, damping: 10 }
    }
  };

  return (
    <section className="py-12 px-4 bg-white text-gray-800">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="text-7xl font-extrabold mb-20 text-center relative z-10"
          style={{ fontFamily: "'Playfair Display', serif" }}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
            Let's Explore Together
          </span>
        </motion.h2>
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Contact Information */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="lg:w-1/2 space-y-8"
          >
            <p className="text-xl leading-relaxed font-light" style={{ fontFamily: "'Poppins', sans-serif" }}>
              I'd love to connect! Whether you have questions about my research, are interested in collaboration, or just want to chat about bioinformatics, feel free to reach out.
            </p>
            <div className="space-y-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
              <div className="flex items-center">
                <MapPin className="mr-4 text-purple-500" size={24} />
                <span className="text-lg">Abd El-moniem Riyad Street, Desouk, Egypt</span>
              </div>
              <div className="flex items-center">
                <Phone className="mr-4 text-purple-500" size={24} />
                <span className="text-lg">(+20) 106 283 6803</span>
              </div>
              <div className="flex items-center">
                <Mail className="mr-4 text-purple-500" size={24} />
                <span className="text-lg">elnashartyasmine@gmail.com</span>
              </div>
            </div>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/in/yasmine-elnasharty/" className="text-purple-500 hover:text-pink-500 transition-colors" target="_blank" rel="noopener noreferrer">
                <Linkedin size={32} />
              </a>
              <a href="https://github.com/YasmineElnasharty" className="text-purple-500 hover:text-pink-500 transition-colors" target="_blank" rel="noopener noreferrer">
                <Github size={32} />
              </a>
            </div>
          </motion.div>

          {/* Contact Form */}
          <motion.div
            initial="hidden"
            animate="visible"
            variants={formVariants}
            className="lg:w-1/2 w-full p-4 mt-[-2rem]"
          >
            <form onSubmit={handleSubmit} className="space-y-6 rounded-2xl p-8">
              <motion.div variants={childVariants} className="relative group">
                <User className="absolute top-3 left-3 text-purple-400 transition-all duration-300 group-focus-within:text-pink-500" size={20} />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your Name"
                  className="w-full pl-10 pr-4 py-3 bg-white bg-opacity-70 border-b-2 border-purple-300 text-gray-800 placeholder-gray-400 focus:outline-none focus:border-pink-500 focus:ring-2 focus:ring-pink-200 transition-all duration-300 rounded-lg"
                  required
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                />
              </motion.div>
              <motion.div variants={childVariants} className="relative group">
                <Mail className="absolute top-3 left-3 text-purple-400 transition-all duration-300 group-focus-within:text-pink-500" size={20} />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your Email"
                  className="w-full pl-10 pr-4 py-3 bg-white bg-opacity-70 border-b-2 border-purple-300 text-gray-800 placeholder-gray-400 focus:outline-none focus:border-pink-500 focus:ring-2 focus:ring-pink-200 transition-all duration-300 rounded-lg"
                  required
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                />
              </motion.div>
              <motion.div variants={childVariants} className="relative group">
                <MessageSquare className="absolute top-3 left-3 text-purple-400 transition-all duration-300 group-focus-within:text-pink-500" size={20} />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Your Message"
                  className="w-full pl-10 pr-4 py-3 bg-white bg-opacity-70 border-b-2 border-purple-300 text-gray-800 placeholder-gray-400 focus:outline-none focus:border-pink-500 focus:ring-2 focus:ring-pink-200 transition-all duration-300 resize-none h-32 rounded-lg"
                  required
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                ></textarea>
              </motion.div>
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white font-bold py-5 px-8 rounded-full hover:opacity-90 transition-all duration-300 flex items-center justify-center overflow-hidden relative"
                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(123, 31, 162, 0.5)" }}
                whileTap={{ scale: 0.95 }}
                disabled={isSubmitting}
                style={{ fontFamily: "'Poppins', sans-serif" }}
              >
                <motion.span
                  initial={{ y: 0 }}
                  animate={isSubmitting ? { y: -30 } : { y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center absolute"
                >
                  <Send className="mr-2" size={20} />
                  Send Message
                </motion.span>
                <motion.span
                  initial={{ y: 30 }}
                  animate={isSubmitting ? { y: 0 } : { y: 30 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center absolute"
                >
                  <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </motion.span>
              </motion.button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Home = () => {

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
  
    document.addEventListener('contextmenu', handleContextMenu);
  
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
        (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
      ) {
        e.preventDefault();
      }
    };
  
    document.addEventListener('keydown', handleKeydown);
  
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);
  useEffect(() => {
    const disableInspectMethods = (e) => {
      // Prevent "Inspect Element" via right-click or drag and drop on element
      if (e.type === 'mousedown' && e.button === 2) {
        e.preventDefault();
      }
    };
  
    document.addEventListener('mousedown', disableInspectMethods);
  
    return () => {
      document.removeEventListener('mousedown', disableInspectMethods);
    };
  }, []);
    
  
  const controls = useAnimation();

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1 }
    }));
  }, [controls]);

  return (
    <div className="relative z-10">
      <SubSection />
      <DNABlock />


      <div className="bg-white text-gray-800">
        {/* About Section */}


        {/* Projects Section */}
        <ProjectsSection projects={projects} />

        {/* Blog Section */}
        <InsightsSection blogPosts={blogPosts} />


        {/* Contact Section */}
        <InteractiveContactSection />
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};

export default Home;