import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../firebase';
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import axios from 'axios';
import Confetti from 'react-confetti';
import { Send } from 'lucide-react';

const EMAILJS_SERVICE_ID = 'service_f0vv34a';
const EMAILJS_TEMPLATE_ID_CONFIRM = 'template_x9vg3re';
const EMAILJS_USER_ID = 'oM0GV2_SQASnm0uWu';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Add email to Firestore using email as the document ID
      await setDoc(doc(db, "newsletter", email), {
        email: email,
        subscribedAt: serverTimestamp(),
      });

      // Send confirmation email via EmailJS
      const data = {
        service_id: EMAILJS_SERVICE_ID,
        template_id: EMAILJS_TEMPLATE_ID_CONFIRM,
        user_id: EMAILJS_USER_ID,
        template_params: {
          'to_email': email,
        },
      };

      const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setShowThankYou(true);
        setEmail('');
      } else {
        throw new Error('Failed to send confirmation email.');
      }
    } catch (err) {
      console.error("Error during subscription:", err.response ? err.response.data : err.message);
      if (err.code === 'permission-denied') {
        setError('This email is already subscribed.');
      } else {
        setError('There was an error subscribing. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };
 

  // Automatically hide the thank you message after 5 seconds
  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => setShowThankYou(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Stay Updated</h3>
        <p className="text-purple-200 mb-4">Stay updated with my latest blogs and breakthroughs in bioinformatics</p>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-purple-800 bg-opacity-50 text-white placeholder-purple-300 px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
            required
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-md shadow-lg hover:shadow-xl transition duration-300 flex items-center justify-center group"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Subscribing...
              </>
            ) : (
              <>
                <Send className="mr-2" size={20} />
                Subscribe
              </>
            )}
          </motion.button>
        </form>
      </motion.div>

      {/* Thank You Animation */}
      <AnimatePresence>
        {showThankYou && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
          >
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={500}
              gravity={0.2}
            />
            <motion.div
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{ type: 'spring', stiffness: 260, damping: 20 }}
              className="text-9xl mb-8"
            >
              🎉
            </motion.div>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-center"
            >
              <motion.h2
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
                className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
              >
                Thank You!
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="text-3xl text-purple-200 drop-shadow-md"
              >
                You've subscribed to my newsletter!
              </motion.p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NewsletterSignup;
