import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, Heart, Award, Hand } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, doc, updateDoc, increment, onSnapshot } from "firebase/firestore";

const Comment = ({ comment, admin, blogId }) => { 
  const [reactions, setReactions] = useState(comment.reactions || { heart: 0, clap: 0 });
  const [isPosting, setIsPosting] = useState(false);
  const [replies, setReplies] = useState(comment.replies || []);

  const isAdmin = comment.username === admin.username;
  const isYasmine = comment.username === 'Yasmine';

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "blogs", blogId, "comments", comment.id), (doc) => { 
      if (doc.exists()) {
        const updatedComment = doc.data();
        setReactions(updatedComment.reactions || { heart: 0, clap: 0 });
      }
    });

    const unsubscribeReplies = onSnapshot(collection(db, "blogs", blogId, "comments", comment.id, "replies"), (snapshot) => { 
      const updatedReplies = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setReplies(updatedReplies);
      if (isPosting) setIsPosting(false);
    });

    return () => {
      unsubscribe();
      unsubscribeReplies();
    };
  }, [blogId, comment.id, isPosting]);

  const handleReaction = async (type) => {
    try {
      const commentRef = doc(db, "blogs", blogId, "comments", comment.id); 
      await updateDoc(commentRef, {
        [`reactions.${type}`]: increment(1)
      });
    } catch (error) {
      console.error("Error updating reaction: ", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Just now';
    const distance = formatDistanceToNow(timestamp.toDate(), { addSuffix: true });
    return distance.replace('about ', '').replace('less than a minute ago', 'just now');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-8"
    >
      <motion.div
        className="bg-white rounded-3xl shadow-lg p-6 transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1 relative overflow-hidden"
        whileHover={{ scale: 1.02 }}
      >
        <motion.div
          className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-300 to-pink-300"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.5 }}
        />
        <div className="flex items-start space-x-4">
          <motion.img
            src={
              isYasmine
                ? 'https://img.icons8.com/bubbles/100/girl.png'
                : isAdmin
                ? 'https://img.icons8.com/bubbles/100/system-administrator-female.png'
                : 'https://img.icons8.com/bubbles/100/user.png'
            }
            alt={comment.username}
            className="w-16 h-16 rounded-full object-cover border-4 border-purple-300"
            whileHover={{ scale: 1.1, rotate: 5 }}
          />
          <div className="flex-1">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-bold text-lg text-purple-700 flex items-center font-serif">
                {comment.username}
                {isAdmin && (
                  <motion.span
                    whileHover={{ scale: 1.2, rotate: 360 }}
                    className="ml-2 text-yellow-500"
                  >
                    <Award size={18} />
                  </motion.span>
                )}
              </h4>
              <div className="flex items-center text-sm text-gray-500 font-light">
                <Clock size={14} className="mr-1" />
                {formatTimestamp(comment.timestamp)}
              </div>
            </div>
            <p className="text-gray-700 mb-4 leading-relaxed font-sans text-lg tracking-wide"
               style={{
                 textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                 fontWeight: 400,
                 lineHeight: 1.6,
                 letterSpacing: '0.025em',
               }}
            >
              {comment.content}
            </p>
            <div className="flex items-center space-x-4">
              <motion.button
                className="flex items-center text-pink-500 hover:text-pink-700 focus:outline-none transition-colors duration-300"
                onClick={() => handleReaction('heart')}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Heart size={18} className="mr-1" fill={reactions.heart > 0 ? 'currentColor' : 'none'} />
                {reactions.heart}
              </motion.button>
              <motion.button
                className="flex items-center text-blue-500 hover:text-blue-700 focus:outline-none transition-colors duration-300"
                onClick={() => handleReaction('clap')}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Hand size={18} className="mr-1" fill={reactions.clap > 0 ? 'currentColor' : 'none'} />
                {reactions.clap}
              </motion.button>
            </div>

            {/* Replies Section */}
            <div className="mt-6 space-y-4">
              {replies.map((reply, index) => (
                <motion.div
                  key={reply.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="flex items-start bg-purple-50 p-4 rounded-lg"
                >
                  <img
                    src={
                      reply.username === 'Yasmine'
                        ? 'https://img.icons8.com/bubbles/100/system-administrator-female.png'
                        : reply.username === admin.username
                        ? 'https://img.icons8.com/bubbles/100/system-administrator-female.png'
                        : 'https://img.icons8.com/bubbles/100/user.png'
                    }
                    alt={reply.username}
                    className="w-12 h-12 rounded-full mr-4 object-cover border-2 border-purple-300"
                  />
                  <div className="flex-1">
                    <div className="flex justify-between items-center mb-2">
                      <h5 className="font-semibold text-purple-700 font-serif">{reply.username}</h5>
                      <div className="flex items-center text-xs text-gray-500 font-light">
                        <Clock size={12} className="mr-1" />
                        {formatTimestamp(reply.timestamp)}
                      </div>
                    </div>
                    <p className="text-gray-700 font-sans text-base tracking-wide"
                       style={{
                         textShadow: '0 1px 1px rgba(0,0,0,0.05)',
                         fontWeight: 400,
                         lineHeight: 1.5,
                         letterSpacing: '0.02em',
                       }}
                    >
                      {reply.content}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Comment;
