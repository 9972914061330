import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGithub, FaEnvelope, FaTwitter, FaDownload } from 'react-icons/fa';
import { SiDart } from 'react-icons/si';
import { AnimatePresence } from 'framer-motion';
import { SiJupyter, SiGooglecolab } from 'react-icons/si';
import Confetti from 'react-confetti';
import { FaChevronUp } from 'react-icons/fa'; // Or any other icon you prefer
import emailjs from 'emailjs-com';
import NewsletterSignup from '../NewsletterSignup'; 

const ScrollToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);
  
    useEffect(() => {
      const checkScrollTop = () => {
        // Show the scroll icon if user scrolled down 300 pixels or more
        if (!showScroll && window.scrollY > 300) {
          setShowScroll(true);
        } else if (showScroll && window.scrollY <= 300) {
          setShowScroll(false);
        }
      };
  
      window.addEventListener('scroll', checkScrollTop);
      return () => {
        window.removeEventListener('scroll', checkScrollTop);
      };
    }, [showScroll]);
  
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    return (
      <div
        className={`fixed bottom-4 right-4 z-50 p-3 rounded-full bg-purple-500 text-white shadow-lg cursor-pointer transition-transform duration-300 ${showScroll ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
        onClick={scrollTop}
      >
        <FaChevronUp size={24} />
      </div>
    );
  };

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const linkStyle = {
        color: '#1F2937',
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '0.15em',
        display: 'inline-block',
        padding: '15px 20px',
        position: 'relative',
        fontWeight: '700',
    };

    const hoverEffectStyle = {
        content: '""',
        background: '#D946EF',
        bottom: 0,
        height: '2px',
        left: '50%',
        position: 'absolute',
        transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
        width: 0,
    };

    const liStyle = {
        display: 'table-cell',
        position: 'relative',
        padding: '15px 0',
    };

    const ulStyle = {
        margin: '0 auto',
        padding: 0,
        listStyle: 'none',
        display: 'table',
        width: '800px',
        textAlign: 'center',
    };

    const mobileMenuStyle = {
        position: 'absolute',
        top: '60px',
        left: '0',
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 100,
    };

    const mobileLinkStyle = {
        ...linkStyle,
        padding: '10px 20px',
    };

    return (
        <>
            {/* Desktop View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="desktop-menu"
            >
                <div
          style={{
            position: 'absolute',
            left: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/Y.png"
            alt="Logo"
            style={{ height: '120px', marginLeft: '50px' }}
            onClick={() => window.location.reload()}
          />
        </div>
                <ul style={ulStyle}>
                    {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                        <motion.li
                            key={text}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            style={liStyle}
                        >
                            <motion.a
                                href={`/${text.toLowerCase()}`}
                                style={linkStyle}
                                whileHover={{ scale: 1.1 }}
                                onMouseEnter={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '100%';
                                    hoverSpan.style.left = '0';
                                }}
                                onMouseLeave={(e) => {
                                    const hoverSpan = e.currentTarget.querySelector('span');
                                    hoverSpan.style.width = '0';
                                    hoverSpan.style.left = '50%';
                                }}
                            >
                                {text}
                                <span style={hoverEffectStyle}></span>
                            </motion.a>
                        </motion.li>
                    ))}
                </ul>
            </motion.nav>

            {/* Mobile View */}
            <motion.nav
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                    position: 'relative',
                    top: '10px',
                    left: 0,
                    width: '100%',
                    zIndex: 50,
                    backgroundColor: 'transparent',
                }}
                className="mobile-menu"
            >
                <div
                    style={{ padding: '15px 20px', cursor: 'pointer' }}
                    onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                >
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                    <div style={{ width: '30px', height: '3px', backgroundColor: '#1F2937', margin: '6px 0' }}></div>
                </div>
                {isMobileMenuOpen && (
                    <motion.ul
                        style={mobileMenuStyle}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {['Home', 'About', 'Portfolio', 'Blog', 'Contact'].map((text, index) => (
                            <motion.li
                                key={text}
                                style={{ padding: '10px 0' }}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                <a href={`/${text.toLowerCase()}`} style={mobileLinkStyle}>
                                    {text}
                                </a>
                            </motion.li>
                        ))}
                    </motion.ul>
                )}
            </motion.nav>

            <style>
                {`
                    @media (min-width: 768px) {
                        .mobile-menu {
                            display: none;
                        }
                    }
                    @media (max-width: 767px) {
                        .desktop-menu {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
};
const SVGDoubleHelix = () => {
    return (
        <svg
            viewBox="0 0 800 600"
            className="w-full h-full opacity-20"
            style={{
                position: 'absolute',
                top: '130%',// Adjust this value to move the helix lower
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: '#8B5CF6', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#EC4899', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <DNAStrand color="url(#grad1)" offset={0} />
            <DNAStrand color="url(#grad1)" offset={Math.PI} />
        </svg>
    );
};
const DNAStrand = ({ color, offset }) => {
    const points = 20;
    const ySpread = 80;
    const xSpread = 800;

    return (
        <>
            {[...Array(points)].map((_, i) => {
                const x = (i / (points - 1)) * xSpread;
                const y = Math.sin((i / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2;
                return (
                    <g key={i}>
                        <motion.circle
                            cx={x}
                            cy={y}
                            r={3}
                            fill={color}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: [0, 1, 0], y: y + Math.random() * 20 - 10 }}
                            transition={{
                                duration: 3,
                                repeat: Infinity,
                                delay: i * 0.2,
                            }}
                        />
                        {i < points - 1 && (
                            <motion.line
                                x1={x}
                                y1={y}
                                x2={(i + 1) / (points - 1) * xSpread}
                                y2={Math.sin(((i + 1) / (points - 1)) * Math.PI * 4 + offset) * (ySpread / 4) + ySpread / 2}
                                stroke={color}
                                strokeWidth={1}
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: [0, 1, 0] }}
                                transition={{
                                    duration: 3,
                                    repeat: Infinity,
                                    delay: i * 0.2,
                                }}
                            />
                        )}
                    </g>
                );
            })}
        </>
    );
};
const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [email, setEmail] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
  
    // Social media details
    const socialIcons = [
      { Icon: FaLinkedin, color: '#0077B5', name: 'LinkedIn', url: 'https://www.linkedin.com/in/yasmine-elnasharty/' },
      { Icon: FaGithub, color: '#333', name: 'GitHub', url: 'https://github.com/YasmineElnasharty' },
      { Icon: FaEnvelope, color: '#D44638', name: 'Email', url: 'mailto:elnashartyasmine@gmail.com' },
    ];
  
  
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (email) {
        emailjs.send(
          'service_f0vv34a',  // Your service ID
          'template_x9vg3re', // Your template ID
          {
            to_email: email,  // Dynamically set the recipient's email
            message: 'Thank you for subscribing to our newsletter!'
          },
          'oM0GV2_SQASnm0uWu'    // Your public key
        )
        .then((response) => {
          console.log('Email sent successfully:', response);
          setShowThankYou(true);
          setEmail('');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          alert('There was an error subscribing. Please try again later.');
        });
      }
    };
  
    useEffect(() => {
      if (showThankYou) {
        const timer = setTimeout(() => setShowThankYou(false), 5000);
        return () => clearTimeout(timer);
      }
    }, [showThankYou]);
  
    const ThankYouAnimation = () => (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
      >
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.2}
        />
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          className="text-9xl mb-8"
        >
          🎉
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-center"
        >
          <motion.h2
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
            className="text-6xl font-bold text-white mb-4 drop-shadow-lg"
          >
            Thank You!
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-3xl text-purple-200 drop-shadow-md"
          >
            You've subscribed to my newsletter!
          </motion.p>
        </motion.div>
      </motion.div>
    );
  
    return (
      <footer className="bg-gradient-to-r from-black via-purple-900 to-black text-white py-16 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Logo and tagline */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="col-span-1 md:col-span-2 lg:col-span-1"
            >
              <div className="flex items-center mb-4">
                {/* Logo */}
                <img
                  src="/Y.png"
                  alt="Logo"
                  className="text-4xl text-purple-400 mr-3"
                  style={{ height: '160px' }}  // Larger size for the logo
                />
              </div>
              <p className="text-purple-200 mb-6">Transforming complex data into biological insights, step by step.</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-xl transition duration-300"
              >
                <a href="/Portfolio">Explore My Projects</a> {/* Link to Portfolio Page */}
              </motion.button>
            </motion.div>
  
            {/* Quick links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Quick Links</h3>
              <ul className="space-y-2">
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/About" className="hover:text-purple-300 transition duration-300"> {/* Link to About Page */}
                    About Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Portfolio" className="hover:text-purple-300 transition duration-300"> {/* Link to Portfolio Page */}
                    Projects
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Contact" className="hover:text-purple-300 transition duration-300"> {/* Link to Contact Page */}
                    Contact Me
                  </a>
                </motion.li>
                <motion.li
                  whileHover={{ x: 5, color: '#D1D5DB' }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="/Blog" className="hover:text-purple-300 transition duration-300"> {/* Link to Blog Page */}
                    Blog
                  </a>
                </motion.li>
              </ul>
            </motion.div>
  
            {/* Latest Blog */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 border-b-2 border-purple-500 pb-2">Latest Blog</h3>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="bg-purple-800 bg-opacity-50 p-4 rounded-lg shadow-md"
              >
                <h4 className="font-semibold text-purple-200 mb-2">Precision Medicine: Bioinformatics Role in Tailoring Treatments</h4>
                <p className="text-sm text-purple-300 mb-2">Clinical Bioinformatics, 2024</p>
                <a href="/blogs/Blog1" className="text-pink-400 hover:text-pink-300 transition duration-300">Read More →</a> {/* Link to Latest Blog Post */}
              </motion.div>
            </motion.div>
  
            <NewsletterSignup />
          </div>
  
          {/* Social Icons */}
          <motion.div
            className="flex justify-center space-x-6 mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            {socialIcons.map(({ Icon, color, name, url }) => (
              <motion.a
                key={name}
                href={url}
                target="_blank" // Opens link in new tab
                rel="noopener noreferrer" // Security for external links
                onMouseEnter={() => setHoveredIcon(name)}
                onMouseLeave={() => setHoveredIcon(null)}
                whileHover={{ y: -5 }}
                className="relative text-3xl text-purple-300 hover:text-white transition duration-300"
              >
                <Icon style={{ color: hoveredIcon === name ? color : undefined }} />
                <AnimatePresence>
                  {hoveredIcon === name && (
                    <motion.span
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute mt-2 text-sm bg-purple-800 px-2 py-1 rounded"
                    >
                      {name}
                    </motion.span>
                  )}
                </AnimatePresence>
              </motion.a>
            ))}
          </motion.div>
  
          {/* DNA Animation */}
          <SVGDoubleHelix />
  
          {/* Floating Particles */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            {[...Array(20)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute bg-purple-500 rounded-full opacity-20"
                style={{
                  width: Math.random() * 10 + 5,
                  height: Math.random() * 10 + 5,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                }}
                animate={{
                  y: [0, -30, 0],
                  opacity: [0.2, 0.5, 0.2],
                }}
                transition={{
                  duration: Math.random() * 3 + 2,
                  repeat: Infinity,
                  ease: 'easeInOut',
                }}
              />
            ))}
          </div>
  
          {/* Copyright */}
          <motion.div
            className="mt-12 pt-8 border-t border-purple-800 text-center text-sm text-purple-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <p>&copy; {currentYear} Yasmine. All rights reserved. |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Privacy Policy Coming Soon!")}
              >
                Privacy Policy
              </button> |
              <button
                className="ml-2 hover:text-purple-200 transition duration-300"
                onClick={() => alert("Terms of Service Coming Soon!")}
              >
                Terms of Service
              </button>
            </p>
          </motion.div>
        </div>
        <AnimatePresence>
          {showThankYou && <ThankYouAnimation />}
        </AnimatePresence>
      </footer>
    );
  };

const AnimatedBackground = () => {
    const [particles, setParticles] = useState([]);

    useEffect(() => {
        const generateParticles = () => {
            const newParticles = [];
            for (let i = 0; i < 50; i++) {
                newParticles.push({
                    x: Math.random() * window.innerWidth,
                    y: Math.random() * window.innerHeight,
                    size: Math.random() * 4 + 1,
                    speedX: (Math.random() - 0.5) * 0.5,
                    speedY: (Math.random() - 0.5) * 0.5,
                });
            }
            setParticles(newParticles);
        };

        generateParticles();

        const animateParticles = () => {
            setParticles((prevParticles) =>
                prevParticles.map((particle) => ({
                    ...particle,
                    x: (particle.x + particle.speedX + window.innerWidth) % window.innerWidth,
                    y: (particle.y + particle.speedY + window.innerHeight) % window.innerHeight,
                }))
            );
        };

        const intervalId = setInterval(animateParticles, 50);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="fixed inset-0 pointer-events-none">
            {particles.map((particle, index) => (
                <motion.div
                    key={index}
                    className="absolute rounded-full bg-purple-200"
                    style={{
                        width: particle.size,
                        height: particle.size,
                        x: particle.x,
                        y: particle.y,
                    }}
                    animate={{
                        x: particle.x,
                        y: particle.y,
                    }}
                    transition={{
                        duration: 0.05,
                        ease: "linear",
                    }}
                />
            ))}
        </div>
    );
}

const Project3 = () => {
    const [activeSection, setActiveSection] = useState('overview');

    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };
    useEffect(() => {
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
      
        document.addEventListener('contextmenu', handleContextMenu);
      
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);
    
      useEffect(() => {
        const handleKeydown = (e) => {
          // Check for F12 or Ctrl+Shift+I/Ctrl+Shift+J/Ctrl+U/Ctrl+S
          if (
            e.keyCode === 123 || // F12
            (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)) || // Ctrl+Shift+I, Ctrl+Shift+C, Ctrl+Shift+J
            (e.ctrlKey && e.keyCode === 85) || // Ctrl+U to view source
            (e.ctrlKey && e.keyCode === 83) // Ctrl+S to save page
          ) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('keydown', handleKeydown);
      
        return () => {
          document.removeEventListener('keydown', handleKeydown);
        };
      }, []);
      useEffect(() => {
        const disableInspectMethods = (e) => {
          // Prevent "Inspect Element" via right-click or drag and drop on element
          if (e.type === 'mousedown' && e.button === 2) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('mousedown', disableInspectMethods);
      
        return () => {
          document.removeEventListener('mousedown', disableInspectMethods);
        };
      }, []);
        
    const CodeBlock = ({ code }) => (
        <pre className="bg-gray-800 text-white p-4 rounded-lg overflow-x-auto">
            <code>{code}</code>
        </pre>
    );

    const goToNextSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const nextIndex = (currentIndex + 1) % sections.length; // Loop back to start
        setActiveSection(sections[nextIndex].id);
    };

    const goToPreviousSection = () => {
        const currentIndex = sections.findIndex((section) => section.id === activeSection);
        const previousIndex = (currentIndex - 1 + sections.length) % sections.length; // Loop back to end
        setActiveSection(sections[previousIndex].id);
    };

    const sections = [
        { id: 'overview', title: 'Project Overview' },
        { id: 'data', title: 'Data Sources' },
        { id: 'methodology', title: 'Methodology' },
        { id: 'results', title: 'Results and Impact' },
        { id: 'code', title: 'Sample Code' },
        { id: 'references', title: 'References' },
    ];

    const sampleCode = `import matplotlib.pyplot as plt
from Bio import SeqIO
from Bio.SeqUtils import IsoelectricPoint, molecular_weight

# Kyte-Doolittle hydropathy scale
kd_scale = {
    'A': 1.8, 'C': 2.5, 'D': -3.5, 'E': -3.5, 'F': 2.8,
    'G': -0.4, 'H': -0.2, 'I': 4.5, 'K': -3.9, 'L': 3.8,
    'M': 1.9, 'N': -3.5, 'P': -1.6, 'Q': -3.5, 'R': -4.5,
    'S': -0.8, 'T': -0.7, 'V': 4.2, 'W': -0.9, 'Y': -1.3
}

# Function to load the sequence from a FASTA file
def load_sequence(file_path):
    record = SeqIO.read(file_path, "fasta")
    return str(record.seq)

# Function to calculate amino acid composition
def calculate_composition(sequence):
    aa_counts = {aa: sequence.count(aa) for aa in set(sequence)}
    total_aa = len(sequence)
    composition = {aa: count / total_aa for aa, count in aa_counts.items()}
    return composition

# Function to calculate hydropathy values based on Kyte-Doolittle scale
def calculate_hydropathy(sequence, window=9):
    hydropathy = []
    scale = [kd_scale.get(aa, 0) for aa in sequence]
    for i in range(len(sequence) - window + 1):
        window_scale = scale[i:i + window]
        hydropathy.append(sum(window_scale) / window)
    return hydropathy

# Function to plot amino acid composition using matplotlib
def plot_composition(composition, title):
    plt.figure(figsize=(8, 4))
    amino_acids = list(composition.keys())
    fractions = list(composition.values())

    # Create a bar plot with a colormap
    plt.bar(amino_acids, fractions, color=plt.get_cmap('viridis')(range(len(amino_acids))))
    plt.xlabel('Amino Acid', fontsize=12)
    plt.ylabel('Fraction', fontsize=12)
    plt.title(f'Amino Acid Composition of {title}', fontsize=14)
    plt.grid(True, linestyle='--', alpha=0.7)
    plt.tight_layout()
    plt.show()

# Function to plot hydropathy plot
def plot_hydropathy(sequence, title):
    hydropathy = calculate_hydropathy(sequence, window=9)

    plt.figure(figsize=(8, 4))
    plt.plot(hydropathy, color='teal', lw=2)
    plt.title(f'Hydropathy Plot of {title}', fontsize=14)
    plt.xlabel('Amino Acid Position', fontsize=12)
    plt.ylabel('Hydropathy', fontsize=12)
    plt.grid(True, linestyle='--', alpha=0.7)
    plt.tight_layout()
    plt.show()

# Example usage:
# Load sequence from FASTA file
fasta_file = '/content/Protein1.fasta'
sequence = load_sequence(fasta_file)
protein_name = 'Protein1'  # Example protein name

# Calculate and plot amino acid composition
composition = calculate_composition(sequence)
plot_composition(composition, protein_name)

# Plot hydropathy plot
plot_hydropathy(sequence, protein_name) 
`;

    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        {
            src: "https://i.postimg.cc/gjjsxb5H/20240910-125203.jpg",
            description: "The first plot shows the amino acid composition of Protein1, depicting the fraction of each amino acid present in the sequence. Amino acids such as leucine (L) and alanine (A) have a higher fraction, indicating their prevalence in this protein. The second plot represents the hydropathy profile of Protein1, illustrating the hydrophobic or hydrophilic nature of each amino acid position, with positive values indicating hydrophobic regions and negative values hydrophilic regions, providing insights into potential membrane-spanning or solvent-exposed regions."
        },
        {
            src: "https://i.postimg.cc/Bv3CxKk6/20240910-125459.jpg",
            description: "The first plot represents the amino acid composition of Protein2, showing the distribution of different amino acids by their fraction in the protein sequence. Leucine (L) appears to be the most abundant, with notable amounts of glutamine (Q) and alanine (A) as well. The second plot shows the hydropathy profile of Protein2, indicating variations in hydrophobicity along the sequence, with significant hydrophobic regions toward the latter part of the protein."
        },
        {
            src: "https://i.postimg.cc/2S709G4v/IMG-20240910-130042-408.png",
            description: "The heatmap provides a comparative visualization of hydrophobicity and flexibility for Protein1 and Protein2. The top section shows Protein1's hydrophobicity and flexibility, with the hydrophobic regions represented by green and yellow hues, and flexibility as a uniformly lighter green band, indicating moderate flexibility throughout the sequence. In contrast, Protein2’s hydrophobicity is more variable, with distinct regions of high hydrophobicity (green) and hydrophilicity (red), while its flexibility shows a similar moderate trend to Protein1, but covering a shorter sequence length due to its size. The scale bar on the right shows the range of property values from hydrophilic (red) to hydrophobic (green)."
        }
    ];

    const slideLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const slideRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="bg-gradient-to-b from-purple-50 to-white min-h-screen font-sans">
            <AnimatedBackground />
            <Header />
            <main className="max-w-6xl mx-auto px-4 py-12">
                <motion.h1
                    className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-8 text-center"
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                >
                    Exploring Protein Folding with AlphaFold
                </motion.h1>

                <nav className="mb-6 sm:mb-12">
                    <ul className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
                        {sections.map((section) => (
                            <motion.li
                                key={section.id}
                                className="mb-2 sm:mb-0"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <button
                                    className={`px-3 sm:px-4 py-2 rounded-full text-sm sm:text-base ${activeSection === section.id
                                        ? 'bg-purple-600 text-white'
                                        : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                                        } transition duration-300`}
                                    onClick={() => setActiveSection(section.id)}
                                >
                                    {section.title}
                                </button>
                            </motion.li>
                        ))}
                    </ul>
                </nav>


                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeSection}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        {activeSection === 'overview' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Project Overview</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    Proteins play a fundamental role in all living organisms, performing critical functions that depend heavily on their three-dimensional structures. These structures are determined by the way proteins fold, a process influenced by their amino acid sequences. Understanding protein folding is crucial for insights into various biological processes, including how proteins interact with other molecules and their roles in health and disease.
                                </motion.p>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    However, traditional methods for determining protein structures, such as X-ray crystallography and electron microscopy, are often time-consuming and costly. The advent of AlphaFold, an AI-driven model developed by DeepMind, has revolutionized this field by providing rapid and accurate predictions of protein structures directly from their amino acid sequences.
                                </motion.p>

                                <section className="mb-12">
                                    <h2 className="text-4xl font-bold text-purple-800 mb-4">Objectives</h2>
                                    <motion.p
                                        className="text-xl font-semibold text-gray-700 leading-relaxed mb-6"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2 }}
                                    >
                                        This project explores the intricate process of protein folding, with a specific focus on AlphaFold's groundbreaking contributions. The objectives are:
                                    </motion.p>
                                    <motion.ol
                                        className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <li className="mb-2">
                                            <span className="font-semibold">Understanding Protein Folding: </span> Gain a deeper insight into how proteins fold and why this process is vital for their function.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold">Evaluating AlphaFold's Impact: </span> Assess how AlphaFold predicts protein structures and explore its implications for drug discovery, genomics, and precision medicine.
                                        </li>
                                        {/* <li className="mb-2">
                                            <span className="font-semibold"></span> Investigate the diagnostic challenges posed by overlapping hemoglobin levels between anemic and non-anemic individuals.
                                        </li>
                                        <li className="mb-2">
                                            <span className="font-semibold"></span> Employ statistical modeling and visualization to understand regional trends and variations in anemia prevalence.
                                        </li>
                                        <li>
                                            <span className="font-semibold"></span> Offer data-driven insights to guide public health strategies aimed at reducing anemia, particularly in vulnerable populations.
                                        </li> */}
                                    </motion.ol>

                                    <motion.div
                                        className="bg-gray-100 p-6 rounded-lg mb-8"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.8 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-800 mb-4">Significance</h3>
                                        {/* <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                            To further analyze the data, we employed several statistical models:
                                        </p> */}
                                        <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Revolutionizing Structural Biology:</strong> AlphaFold offers unprecedented accuracy in predicting protein structures, transforming research across biology and medicine.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Applications in Drug Discovery:</strong>By predicting protein structures, AlphaFold accelerates the drug discovery process, identifying potential drug targets faster.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Impacts on Genomics and Precision Medicine:</strong>Understanding protein structures helps in interpreting genomic data, leading to personalized medical treatments.
                                            </li>
                                        </ul>

                                    </motion.div>
                                </section>

                                <motion.div
                                    className="relative w-5/6 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                    whileHover={{ scale: 1.02 }}
                                >
                                    <img
                                        src="https://i.imghippo.com/files/nhHNb1725972170.png"
                                        alt="The Importance of Genomic Data in Precision Medicine"
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </motion.div>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >

                                    This diagram shows the workflow for predicting a protein's 3D structure. Starting with the protein sequence, embeddings and sequence-residue edges are calculated, followed by residue-residue interactions. These data feed into a structure module, which predicts the 3D protein model, along with confidence scores and pairwise residue distances.                                </motion.p>

                                {/* <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    Our project not only pushes the boundaries of what's possible in computational biology but also democratizes access to these powerful tools. By making our algorithms and models openly available, we aim to accelerate research across the globe, fostering collaborations and driving innovations in fields ranging from medicine to sustainable agriculture.
                                </motion.p> */}
                            </section>
                        )}

                        {activeSection === 'data' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Data Sources</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    In this project, the protein sequences are sourced from reputable databases, ensuring accuracy and relevance to the research objectives. Two specific proteins were chosen for this study: the Chaperone protein YscY and the Yop proteins translocation protein X, both selected for their relatively short sequences, which facilitate efficient computation and analysis. The protein sequences are retrieved from UniProt, a reliable and widely-used database for protein information. Additionally, the project utilizes advanced tools and platforms, such as ColabFold for structure prediction and ChimeraX for visualization. These resources enable a streamlined workflow, from sequence alignment to the detailed analysis of predicted protein structures. The data collected not only provides a foundation for predicting protein folding but also offers insights into their potential biological functions and interactions.
                                </motion.p>
                                <motion.ul
    className="bg-purple-100 p-4 sm:p-8 rounded-lg text-base sm:text-xl text-gray-700 leading-relaxed mb-6"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.4 }}
  >
    <li className="mb-4">
      <h3 className="text-2xl sm:text-3xl font-bold text-purple-800 mb-2">Protein Sequences:</h3>
      <div className="text-base sm:text-lg bg-purple-200 p-3 sm:p-4 rounded-lg text-gray-700 leading-relaxed mb-2">
        <span className="text-xl sm:text-2xl font-semibold">Chaperone protein YscY (UniProtKB ID: 7QIH_1): Length of 274 amino acids.</span>
      </div>
      <ul className="list-disc list-inside pl-4 sm:pl-7">
        <li className="mb-2">
          <span className="font-semibold">Sequence:</span>
          <p className="text-sm sm:text-base text-gray-700 bg-purple-200 p-3 sm:p-4 rounded-lg leading-relaxed mt-1 break-words">
            MGHHHHHHGNITLTKRQQEFLLLNGWLQLQCGHAERACILLDALLTLNPEHLAGRRCRIVALLNNNQGERAEKEAQWLISHDP
            LQAGNWICLSRAQQLNGDLDKARHAYQHYLELKDHNESP
          </p>
        </li>
        <li className="mb-2">
          <span className="font-semibold">Function:</span>
          <p className="text-sm sm:text-base text-gray-700 bg-purple-200 p-3 sm:p-4 rounded-lg leading-relaxed mt-1">
            Assists in the correct folding of other proteins.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <div className="text-base sm:text-lg bg-purple-200 p-3 sm:p-4 rounded-lg text-gray-700 leading-relaxed mb-2">
        <span className="text-xl sm:text-2xl font-semibold">Yop proteins translocation protein X (UniProtKB ID: 7QIH_2): Length of 170 amino acids.</span>
      </div>
      <ul className="list-disc list-inside pl-4 sm:pl-7">
        <li className="mb-2">
          <span className="font-semibold">Sequence:</span>
          <p className="text-sm sm:text-base text-gray-700 bg-purple-200 p-3 sm:p-4 rounded-lg leading-relaxed mt-1 break-words">
            GAMGTAQSKRSLWDFASPGYTFHGLHRAQDYRRELDTLQSLLTTSQSSELQAAAALLKCQQDDDRLLQIILNLLHKV
          </p>
        </li>
        <li className="mb-2">
          <span className="font-semibold">Function:</span>
          <p className="text-sm sm:text-base text-gray-700 bg-purple-200 p-3 sm:p-4 rounded-lg leading-relaxed mt-1">
            Aids in transporting proteins across cell membranes.
          </p>
        </li>
      </ul>
    </li>
  </motion.ul>

                                {/* <motion.div
                                            className="relative w-2/3 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                            initial={{ scale: 0.8, opacity: 0 }}
                                            animate={{ scale: 1, opacity: 1 }}
                                            transition={{ duration: 0.6 }}
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <img
                                                src="https://i.postimg.cc/7LQD23WG/Graphical-representation-of-Homo-sapiens-chromosome-3.png"
                                                alt="The Importance of Genomic Data in Precision Medicine"
                                                className="w-full h-auto object-cover rounded-lg"
                                            />
                                        </motion.div>
                                        <motion.p
                                            className="text-xl text-gray-700 leading-relaxed mb-6"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ delay: 0.6 }}
                                        >
                                            This image is a genomic visualization of a region on Homo sapiens chromosome 3, displaying various annotation tracks. The top section shows gene structures (MLH1 and RPL29P11) with exons and introns, based on NCBI RefSeq annotations. Below that, there are tracks showing SNP (dbSNP b156 v2) and ClinVar variants. Further down, RNA-seq data shows exon coverage and intron-spanning reads, indicating expression levels across different samples, scaled logarithmically. The bottom track depicts intron feature annotations with various intron lengths.
                                        </motion.p> */}



                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-5">Tools and Platforms:</h3>

                                        <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>ColabFold:</strong> A streamlined version of AlphaFold optimized for use with Google Colab, utilizing GPU support for faster computations.
                                            </li>
                                            <li className="mb-2">
                                                <strong>ChimeraX:</strong>A visualization tool for analyzing and understanding molecular structures, providing insights into the predicted protein folds.
                                            </li>
                                        </ul>

                                    </motion.div>
                                    <motion.div
                                        className="bg-white p-6 rounded-lg shadow-md"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-5">Data Collection and Preparation:</h3>

                                        <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Sequence Alignment:</strong> Performed using ColabFold to identify approximately 300 sequences that closely match the input sequences.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Visualization Tools:</strong>ChimeraX is used to visualize the predicted protein structures, enabling detailed examination of their three-dimensional conformations.
                                            </li>
                                        </ul>

                                    </motion.div>
                                </div>
                                <motion.div
                                    className="relative w-3/4 mx-auto mb-12 overflow-hidden rounded-xl shadow-2xl"
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                    whileHover={{ scale: 1.02 }}
                                >
                                    <img
                                        src="https://i.imghippo.com/files/KBPIz1725970343.png"
                                        alt="The Importance of Genomic Data in Precision Medicine"
                                        className="w-full h-auto object-cover rounded-lg"
                                    />
                                </motion.div>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    This image shows the ChimeraX interface, a molecular visualization tool. The interface displays a molecular structure on a black background, with various options for manipulating the display, including molecule display styles, background colors, and lighting settings. The side panel on the right provides detailed information about the loaded structure, including its chains, description, and non-standard residues. Below, there are buttons for controlling models, such as showing or hiding specific components. The bottom command bar allows users to input text commands.
                                </motion.p>
                            </section>
                        )}

                        {activeSection === 'methodology' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Methodology</h2>

                                <h3 className="text-2xl font-bold text-purple-700 mb-3">Protein Sequence Collection</h3>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The first step of the project involved collecting protein sequences from reliable databases, specifically UniProt, a well-established source of protein information. The proteins chosen for this study were the Chaperone protein YscY and Yop proteins translocation protein X. These proteins were selected due to their relatively short amino acid sequences, which consist of approximately 200 residues. This selection was crucial as shorter sequences allow for more efficient computational processing, which is essential given the project’s time constraints.                                </motion.p> <motion.p
                                        className="text-xl text-gray-700 leading-relaxed mb-6"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: 0.2 }}
                                    >
                                    This project also involves a comprehensive analysis of protein sequences using various bioinformatics tools and techniques. The analysis includes the calculation of molecular weight, isoelectric point, amino acid composition, and hydropathy, as well as the visualization of these properties through plots. The project also includes structural analysis of proteins from PDB files to extract secondary structure information.</motion.p>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                    <motion.div
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.4 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Structure Prediction Using ColabFold</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            After gathering the protein sequences, the project used ColabFold, an AlphaFold implementation, to predict the 3D structures. Installed on a Google Colab VM with GPU support, ColabFold performed sequence alignment to prepare the data and then utilized AlphaFold’s deep learning algorithms for accurate structure prediction.</p>
                                    </motion.div>

                                    <motion.div
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-3">Visualization with ChimeraX</h3>
                                        <p className="text-lg text-gray-700 leading-relaxed">
                                            Once the structures were predicted, the project used ChimeraX for advanced visualization. The predicted protein structures were loaded into ChimeraX and colored according to prediction confidence levels. This detailed visualization helped in assessing the accuracy and biological relevance of the predictions by clearly distinguishing different regions and domains of the proteins.                                        </p>
                                    </motion.div>
                                </div>

                                <motion.ul
                                    className="bg-purple-100 p-8 rounded-lg text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <li className="mb-2">
                                        <h3 className="text-3xl font-bold text-purple-800 mb-2">Summary of Coding Steps</h3>

                                        <p className="text-lg bg-purple-100 p-4 rounded-lg text-gray-700 leading-relaxed">
                                            <span className="text-2xl font-semibold">1. Protein Sequence Preparation</span>
                                        </p>
                                        <ul className="list-disc list-inside pl-9">
                                            <li className="mb-1">
                                                <span>Collected and stored protein sequences in FASTA format files for analysis.</span>
                                            </li>
                                        </ul>

                                        <p className="text-lg bg-purple-100 p-4 rounded-lg text-gray-700 leading-relaxed">
                                            <span className="text-2xl font-semibold">2. Calculation of Protein Properties</span>
                                        </p>
                                        <ul className="list-disc list-inside pl-9">
                                            <li className="mb-1">
                                                <span>Computed molecular weight, isoelectric point, amino acid composition, and hydropathy for each protein.</span>
                                            </li>
                                        </ul>

                                        <p className="text-lg bg-purple-100 p-4 rounded-lg text-gray-700 leading-relaxed">
                                            <span className="text-2xl font-semibold">3. Visualization of Protein Properties</span>
                                        </p>
                                        <ul className="list-disc list-inside pl-9">
                                            <li className="mb-1">
                                                <span>Created bar plots for amino acid composition and line plots for hydropathy to visualize protein properties.</span>
                                            </li>
                                        </ul>

                                        <p className="text-lg bg-purple-100 p-4 rounded-lg text-gray-700 leading-relaxed">
                                            <span className="text-2xl font-semibold">4. Structural Analysis Using PDB Files</span>
                                        </p>
                                        <ul className="list-disc list-inside pl-9">
                                            <li className="mb-1">
                                                <span>Analyzed secondary structure information from PDB files to understand protein 3D structures.</span>
                                            </li>
                                        </ul>

                                        <p className="text-lg bg-purple-100 p-4 rounded-lg text-gray-700 leading-relaxed">
                                            <span className="text-2xl font-semibold">5. Comparison of Protein Properties</span>
                                        </p>
                                        <ul className="list-disc list-inside pl-9">
                                            <li className="mb-1">
                                                <span>Developed a heatmap to compare hydrophobicity and flexibility between two proteins.</span>
                                            </li>
                                        </ul>
                                    </li>
                                </motion.ul>


                                <section className="p-4 sm:p-8 bg-white text-gray-800 relative overflow-hidden">
                                <h2 className="text-2xl sm:text-4xl font-bold text-purple-700 mb-4 sm:mb-6 text-center">
                                    Visualizations
                                </h2>
                                
                                <div className="relative w-full max-w-5xl mx-auto flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4">
                                    {/* Left Arrow Button */}
                                    <motion.button
                                    onClick={slideLeft}
                                    whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
                                    animate={{ x: [0, -8, 8, -8, 0] }}
                                    transition={{ duration: 0.05, ease: "easeInOut" }}
                                    className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
                                    >
                                    &#8592;
                                    </motion.button>

                                    {/* Image and Description */}
                                    <div className="w-full relative overflow-hidden rounded-lg shadow-lg flex justify-center items-center bg-white">
                                    <AnimatePresence mode="wait" initial={false}>
                                        <motion.div
                                        key={currentIndex}
                                        className="w-full flex flex-col items-center"
                                        initial={{ opacity: 0, x: -100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 100 }}
                                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                                        >
                                        <motion.img
                                            src={images[currentIndex].src}
                                            alt={`Visualization ${currentIndex + 1}`}
                                            className="w-full sm:w-3/4 h-auto object-contain"
                                            initial={{ scale: 0.9 }}
                                            animate={{ scale: 1 }}
                                            transition={{ duration: 0.3 }}
                                        />
                                        <motion.div
                                            className="w-full p-4 sm:p-7 bg-white text-black rounded-b-lg shadow-lg"
                                            initial={{ y: 50, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            transition={{ delay: 0.2, duration: 0.5 }}
                                        >
                                            <p className="text-base sm:text-lg leading-relaxed">
                                            {images[currentIndex].description}
                                            </p>
                                        </motion.div>
                                        </motion.div>
                                    </AnimatePresence>
                                    </div>

                                    {/* Right Arrow Button */}
                                    <motion.button
                                    onClick={slideRight}
                                    whileHover={{ scale: 1.15, boxShadow: "0px 0px 15px rgba(255, 105, 180, 0.7)" }}
                                    animate={{ x: [0, 8, -8, 8, 0] }}
                                    transition={{ duration: 0.05, ease: "easeInOut" }}
                                    className="z-10 p-2 sm:p-4 bg-purple-700 text-white rounded-full shadow-lg transition duration-300"
                                    >
                                    &#8594;
                                    </motion.button>
                                </div>
                                </section>


                                <motion.div
                                    className="bg-gray-100 p-6 rounded-lg mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Evaluation of Prediction Accuracy</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        The final step in the methodology involved evaluating the accuracy of the predicted protein structures. This was done using specific metrics, such as the Predicted Local Distance Difference Test (pLDDT) and Predicted Aligned Error. The pLDDT score measures how confidently the model predicts the distances between neighboring residues, while the Predicted Aligned Error assesses how closely the predicted model aligns with known protein structures. These metrics provided critical insights into the reliability of the predictions and their potential implications for understanding the biological functions of the proteins. Through these evaluation techniques, the project ensured that the predicted protein folds were both accurate and meaningful.
                                    </p>
                                    {/* <ul className="list-disc list-inside text-lg text-gray-700 leading-relaxed mb-4">
                                        <li className="mb-2">
                                            <strong>Logistic Regression:</strong> Used to predict anemia status based on hemoglobin levels and other demographic factors. The model’s performance was evaluated using a confusion matrix, which was visualized using a heatmap to illustrate the true positives, true negatives, false positives, and false negatives.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Linear Regression:</strong> Applied to examine the trends in anemia prevalence over time across different regions. The regression analysis helped identify regions with declining or increasing anemia rates, highlighting areas where public health interventions may be needed.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Correlation Analysis:</strong> Conducted to explore the relationships between anemia prevalence in pregnant and non-pregnant women across various regions. The correlation matrix was visualized using a heatmap to identify strong positive or negative correlations, which indicate whether anemia trends in pregnant women align with those in non-pregnant women within the same regions.
                                        </li>
                                    </ul> */}

                                </motion.div>
                            </section>

                        )}

                        {activeSection === 'results' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Results and Impact</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    The analysis of protein structures using ColabFold and ChimeraX provided valuable insights into the molecular characteristics of the sequences studied. The integration of advanced visualization techniques significantly enhanced our understanding of the predicted structural conformations.
                                </motion.p>
                                <div className="grid grid-cols-1 gap-4 mb-8 w-full">
                                    <motion.div
                                        className="bg-purple-100 p-6 rounded-lg w-full"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-5">Protein Structure Prediction:</h3>

                                        <ul className="list-disc list-inside text-lg text-gray-700 pl-5 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Chaperone protein YscY:</strong> Predicted to form a stable structure that facilitates the correct folding of other proteins.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Yop proteins translocation protein X:</strong> Predicted to have a structure conducive to translocating other proteins across cell membranes.
                                            </li>
                                        </ul>
                                        <motion.div
                                            className="relative w-5/6 mx-auto mb-5 mt-8 overflow-hidden rounded-xl shadow-2xl border-2 border-purple-800 p-4 rounded-lg"
                                            initial={{ scale: 0.8, opacity: 0 }}
                                            animate={{ scale: 1, opacity: 1 }}
                                            transition={{ duration: 0.6 }}
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <img
                                                src="https://i.imghippo.com/files/MWxuz1725971804.jpg"
                                                alt="The Importance of Genomic Data in Precision Medicine"
                                                className="w-full h-auto object-cover rounded-lg mb-4"
                                            />
                                            <motion.p
                                                className="text-l font-semibold text-gray-700 leading-relaxed"
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ delay: 0.6 }}
                                            >
                                                This image depicts the 3D ribbon structures of the two protein chains, colored in green and purple. The helical regions are prominently shown, and small red spheres likely represent water molecules or other small ligands interacting with the protein structure. The visual highlights the spatial arrangement of helices and loops within the proteins, providing insight into their structural conformation.
                                            </motion.p>
                                        </motion.div>
                                    </motion.div>

                                    <motion.div
                                        className="bg-purple-100 p-6 rounded-lg shadow-md w-full"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-5">Sequence Alignment Analysis:</h3>

                                        <ul className="list-disc list-inside text-lg text-gray-700 pl-5 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Coverage:</strong> High sequence coverage (~250 sequences) for the C-terminus of the first protein, with lower coverage for the N-terminus.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Identity Scores:</strong>
                                                <ul className="list-disc list-inside pl-6 mt-2">
                                                    <li>
                                                        <span className="font-semibold">Red:</span> <span className="text-gray-600">Indicates regions with low sequence identity.</span>
                                                    </li>
                                                    <li>
                                                        <span className="font-semibold">Green:</span> <span className="text-gray-600">Represents moderate sequence identity.</span>
                                                    </li>
                                                    <li>
                                                        <span className="font-semibold">Blue:</span> <span className="text-gray-600">Denotes high sequence identity, particularly in the top regions of the plot.</span>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </motion.div>

                                    <motion.div
                                        className="bg-purple-100 p-6 rounded-lg shadow-md w-full"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.6 }}
                                    >
                                        <h3 className="text-2xl font-bold text-purple-700 mb-6">Impact on Scientific Research:</h3>
                                        <ul className="list-disc list-inside text-lg pl-5 text-gray-700 leading-relaxed mb-4">
                                            <li className="mb-2">
                                                <strong>Enhanced Structural Insights: </strong> The high accuracy of AlphaFold’s predictions provides a deeper understanding of protein functions and interactions.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Drug Discovery:</strong> Potential drug targets can be identified more rapidly, reducing the time and cost associated with traditional methods.
                                            </li>
                                            <li className="mb-2">
                                                <strong>Precision Medicine:</strong> The ability to predict individual protein structures from genomic data enables more tailored treatments for patients.
                                            </li>
                                        </ul>
                                    </motion.div>
                                </div>

                                <motion.div
                                    className="bg-purple-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-800 mb-4">Visualization of Findings</h3>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-bold">Confidence Metrics:</span>
                                            <ul className="list-disc list-inside pl-6 mt-3">
                                                <li>
                                                    <span className="font-semibold">pLDDT Scores  (Predicted Local Distance Difference Test):</span> <span className="text-gray-600">High pLDDT scores (indicated in blue) suggest accurate predictions with high confidence.</span>
                                                </li>
                                                <li>
                                                    <span className="font-semibold">Aligned Error:</span> <span className="text-gray-600">Low predicted aligned error (indicated in white) further confirms the reliability of the predictions.</span>
                                                </li>
                                            </ul>

                                            <motion.div
                                                className="relative w-5/6 mx-auto mb-12 mt-8 overflow-hidden rounded-xl shadow-2xl border-2 border-purple-800 p-4 rounded-lg"
                                                initial={{ scale: 0.8, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{ duration: 0.6 }}
                                                whileHover={{ scale: 1.02 }}
                                            >
                                                <img
                                                    src="https://i.imghippo.com/files/rn7s11725969766.png"
                                                    alt="The Importance of Genomic Data in Precision Medicine"
                                                    className="w-full h-auto object-cover rounded-lg mb-4"
                                                />
                                                <motion.p
                                                    className="text-l font-semibold text-gray-700 leading-relaxed"
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ delay: 0.6 }}
                                                >
                                                    This image displays predicted 3D structures and alignment error plots for four protein models, showing structural confidence and chain alignment. On the left, the proteins are colored by pLDDT scores (predicted Local Distance Difference Test), where blue indicates regions of high structural confidence and red represents lower confidence or disorder. In the middle, the predicted aligned error matrix compares the positional uncertainty between different regions of chains A and B, with blue areas indicating low error and red areas indicating high error. On the right, the models are colored by chains, illustrating how distinct protein chains (shown in cyan and green) interact within the overall structure. Each row represents a different model, giving a comprehensive view of predicted structural quality and alignment.
                                                </motion.p>
                                            </motion.div>

                                        </li>
                                        <li className="mb-4">
                                            <span className="font-bold">Sequence Coverage Visualization:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    This plot displays the sequence coverage and identity of aligned sequences relative to a query.
                                                </li>
                                                <div className="flex flex-wrap justify-center gap-8 mb-12">
                                                    <motion.div
                                                        className="relative w-5/6 mx-auto mb-5 mt-8 overflow-hidden rounded-xl shadow-2xl border-2 border-purple-800 p-4 rounded-lg"
                                                        initial={{ scale: 0.8, opacity: 0 }}
                                                        animate={{ scale: 1, opacity: 1 }}
                                                        transition={{ duration: 0.6 }}
                                                        whileHover={{ scale: 1.02 }}
                                                    >
                                                        <img
                                                            src="https://i.imghippo.com/files/RacoA1725971174.png"
                                                            alt="The Importance of Genomic Data in Precision Medicine"
                                                            className="w-full h-auto object-cover rounded-lg mb-4"
                                                        />
                                                        <motion.p
                                                            className="text-l font-semibold text-gray-700 leading-relaxed"
                                                            initial={{ opacity: 0, y: 20 }}
                                                            animate={{ opacity: 1, y: 0 }}
                                                            transition={{ delay: 0.6 }}
                                                        >
                                                            The plot represents the sequence alignment of multiple sequences compared to a query sequence. The x-axis denotes positions along the query, while the y-axis lists the aligned sequences. A color gradient, ranging from red to blue, reflects sequence identity, with blue and green showing higher identity and red showing lower identity. The black line tracks the cumulative coverage at each query position, highlighting regions of high sequence conservation and gaps in alignment, particularly around position 125, where sequence identity and coverage dip.
                                                        </motion.p>
                                                    </motion.div>
                                                </div>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="font-bold">Predicted IDDT per Position for Protein Models:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    This line plot displays predicted IDDT scores across different positions in the protein sequence for five ranked models.
                                                </li>
                                            </ul>
                                            <motion.div
                                                className="relative w-5/6 mx-auto mb-5 mt-8 overflow-hidden rounded-xl shadow-2xl border-2 border-purple-800 p-4 rounded-lg"
                                                initial={{ scale: 0.8, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{ duration: 0.6 }}
                                                whileHover={{ scale: 1.02 }}
                                            >
                                                <img
                                                    src="https://i.imghippo.com/files/4Ww6U1725971432.png"
                                                    alt="The Importance of Genomic Data in Precision Medicine"
                                                    className="w-full h-auto object-cover rounded-lg mb-4"
                                                />
                                                <motion.p
                                                    className="text-l font-semibold text-gray-700 leading-relaxed"
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ delay: 0.6 }}
                                                >
                                                    The plot shows the predicted IDDT (Ideal Distance Difference Test) values across the positions of a protein sequence, comparing five ranked models (rank_1 to rank_5). The y-axis represents the predicted IDDT score, indicating the confidence in the distance between atoms within the protein structure, with higher scores reflecting higher confidence. The x-axis shows the position along the protein sequence. Consistent trends across all five ranked models suggest regions of high confidence, with distinct dips around position 125 and other areas indicating structural uncertainty. Each rank is represented by a different colored line to illustrate the slight variations in predictions among the models.
                                                </motion.p>
                                            </motion.div>
                                        </li>
                                    </ol>
                                </motion.div>
                                <motion.div
                                    className="bg-white p-6 rounded-lg shadow-md w-full"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <h3 className="text-2xl font-bold text-purple-700 mb-3">Broader Impacts:</h3>
                                    <ul className="list-disc list-inside text-lg pl-5 text-gray-700 leading-relaxed mb-4">
                                        <li className="mb-2">
                                            <strong>Revolutionizing Protein Research: </strong> AlphaFold’s predictions are driving advances in various fields, including drug development and genomics.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Educational Benefits: </strong>Provides researchers and students with a powerful tool to visualize and understand complex protein structures.
                                        </li>
                                        <li className="mb-2">
                                            <strong>Collaboration and Innovation: </strong> Facilitates collaboration across disciplines, fostering new ideas and innovations in biological research.
                                        </li>
                                    </ul>
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'code' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">Sample Code</h2>
                                <motion.p
                                    className="text-xl text-gray-700 leading-relaxed mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    This section provides sample code snippets that demonstrate the key functions and processes used throughout the project, offering a practical guide for implementation.
                                </motion.p>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <CodeBlock code={sampleCode} />
                                </motion.div>
                                <motion.div
                                    className="mt-8 flex flex-wrap gap-4"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <a
                                        href="https://github.com/YasmineElnasharty/Exploring_Protein_Folding_with_AlphaFold.git"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition duration-300"
                                    >
                                        <FaGithub className="mr-2" /> View on GitHub
                                    </a>
                                    {/* <a
                                        href="https://colab.research.google.com/drive/your-notebook-id" // Replace with actual Colab URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-400 transition duration-300"
                                    >
                                        <SiGooglecolab className="mr-2" /> Open in Colab
                                    </a>
                                    <a
                                        href="https://jupyter.org/try" // Or replace with your own Jupyter URL
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-400 transition duration-300"
                                    >
                                        <SiJupyter className="mr-2" /> Try in Jupyter
                                    </a> */}
                                </motion.div>
                                <motion.div
                                    className="mt-8 bg-blue-100 p-6 rounded-lg"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.8 }}
                                >
                                    <h3 className="text-2xl font-bold text-blue-800 mb-4">Code Explanation</h3>
                                    <p className="text-lg text-gray-700 leading-relaxed mb-4">
                                        This Python code analyzes and visualizes the amino acid composition and hydropathy of a protein sequence from a FASTA file.
                                    </p>
                                    <ol className="list-decimal list-inside text-lg text-gray-700 leading-relaxed">
                                        <li className="mb-4">
                                            <span className="font-semibold">Loading the Sequence:</span> The 'load_sequence' function reads a FASTA file and extracts the protein sequence.
                                        </li>
                                        <li className="mb-4">
                                            <span className="font-semibold">Amino Acid Composition:</span> 'calculate_composition' computes the fraction of each amino acid in the sequence and stores it in a dictionary.

                                        </li>
                                        <li>
                                            <span className="font-semibold">Hydropathy Calculation:</span> 'calculate_hydropathy' calculates the hydropathy values using the Kyte-Doolittle scale and a sliding window approach.

                                        </li>
                                        <li>
                                            <span className="font-semibold">Plotting:</span>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    'plot_composition' generates a bar plot showing the amino acid composition.
                                                </li>
                                            </ul>
                                            <ul className="list-disc list-inside pl-6">
                                                <li>
                                                    'plot_hydropathy' creates a line plot displaying the hydropathy values across the protein sequence.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                                        The code utilizes 'matplotlib' for visualization, providing insights into the protein's amino acid distribution and hydrophobicity.
                                    </p>
                                </motion.div>
                            </section>
                        )}

                        {activeSection === 'references' && (
                            <section className="mb-12">
                                <h2 className="text-4xl font-bold text-purple-800 mb-4">References</h2>
                                <ul className="list-decimal list-inside text-xl text-gray-700 leading-relaxed mb-8 space-y-4">
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/34265844/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Jumper, J., Evans, R., Pritzel, A., Green, T., Figurnov, M., Ronneberger, O., … & Hassabis, D. (2021). Highly accurate protein structure prediction with AlphaFold. Nature, 596(7873), 583-589.</a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/34265844/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Callaway, E. (2021). Protein-structure prediction explodes: AlphaFold ushers in a new era. Nature, 596(7873), 543-544.</a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/31942072/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Senior, A. W., Evans, R., Jumper, J., Kirkpatrick, J., Sifre, L., Green, T., … & Hassabis, D. (2020). Improved protein structure prediction using potentials from deep learning. Nature, 577(7792), 706-710. </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/32881101/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Pettersen, E. F., Goddard, T. D., Huang, C. C., Couch, G. S., Greenblatt, D. M., Meng, E. C., & Ferrin, T. E. (2021). UCSF ChimeraX: Structure visualization for researchers, educators, and developers. Protein Science, 30(1), 70-82. </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://pubmed.ncbi.nlm.nih.gov/35637307/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            Mirdita, M., Ovchinnikov, S., & Steinegger, M. (2022). ColabFold: Making protein structure prediction accessible to all. Nature Methods, 19(2), 153-155. </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://alphafold.ebi.ac.uk/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            AlphaFold website    </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.uniprot.org/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-purple-600 hover:underline"
                                        >
                                            UniProt website </a>
                                    </li>
                                </ul>
                            </section>
                        )}

                        <div className="flex justify-between mt-6">
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToPreviousSection}
                            >
                                Previous
                            </motion.button>
                            <motion.button
                                className="bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition duration-300"
                                onClick={goToNextSection}
                            >
                                Next
                            </motion.button>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </main>
            <ScrollToTopButton />
            <Footer />
        </div>
    );
};

export default Project3;
